var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ma-0"},[(_vm.hasAgencySetting('TAB_PROSPECTS') && _vm.applicationStore.user.internal)?_c('div',[_c('gat-grid',{staticClass:"elevation-0",attrs:{"title":"Prospects","items":_vm.prospectItems,"columns":_vm.columns,"reloadBtn":"","fixedheader":true,"height":"calc(100vh - 135px)","hideEmptyColumns":true,"viewGroupName":"prospectsGrid","selectable":"","keyName":"CHO_ID"},on:{"reload-clicked":function($event){return _vm.getProspectLines(_vm.callId)},"row-clicked":_vm.onRowClicked,"selectionChanged":function($event){return _vm.gridSelectionChanged($event)}},scopedSlots:_vm._u([{key:"cell",fn:function(cellProps){return [(
            cellProps.column.field == 'Client' || cellProps.column.field == 'Cargo' || cellProps.column.field == 'Sof'
          )?_c('div',{staticClass:"d-flex justify-start align-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.formatProspectSemicolonString(cellProps.value).value.length >= 50 ? _vm.formatProspectSemicolonString(cellProps.value).value.substring(0, 50) + '...' : _vm.formatProspectSemicolonString(cellProps.value).value)+" ")]),(
              _vm.formatProspectSemicolonString(cellProps.value).values &&
              _vm.formatProspectSemicolonString(cellProps.value).values.length >= 1
            )?_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[(
                _vm.formatProspectSemicolonString(cellProps.value).values &&
                _vm.formatProspectSemicolonString(cellProps.value).values.length >= 1
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"ml-1"},'div',attrs,false),on),[_c('v-chip',{staticClass:"pa-1 white--text",attrs:{"x-small":"","color":"blue","label":""}},[_vm._v(" +"+_vm._s(_vm.formatProspectSemicolonString(cellProps.value).values.length)+" ")])],1)]}}],null,true)},_vm._l((_vm.formatProspectSemicolonString(cellProps.value).values),function(people){return _c('div',{key:people},[_c('span',[_vm._v(_vm._s(people))])])}),0):_vm._e()],1):_vm._e()]):_vm._e()]}}],null,false,731043646)},[_c('template',{slot:"btns"},[_c('wm-button-group',{staticClass:"right ml-auto",attrs:{"buttons":_vm.wmButtonGroup()},on:{"createEmailClick":_vm.createEmailClick}})],1)],2),_c('email-generator',{attrs:{"portcallId":_vm.callId,"location":"portcall","guid":_vm.emailTemplateGuid}})],1):_c('div',[_vm._v("You don't have access to prospects!")])])
}
var staticRenderFns = []

export { render, staticRenderFns }