<template>
  <div v-if="item" class="pb-6 mb-6">
    <gat-form v-model="isValid" ref="form" :readonly="!applicationStore.userRight('P.EDIT')" :isLoading="isLoading">
      <GatGroup title="Select Quay" class="pa-0" size="xxl" :collapsed="isNew ? !expandedIfNew : false">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field :value="item.NAME" size="xs4" label="Quay" />
            <gat-compact-field :value="item.TERMINAL_NAME" size="xs4" label="Terminal" />
            <gat-compact-field :value="item.HARBOUR" size="xs4" label="Harbour" />
          </v-layout>
        </template>
        <quay-select v-model="item.QUAY_ID" size="xs12 sm4" :quayRequired="true"></quay-select>
      </GatGroup>

      <GatGroup title="General" size="xxl" :collapsed="isNew ? !expandedIfNew : false">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field :value="item.ACTION" size="xs6" label="Action" />
            <gat-compact-field :value="item.ACTIVE" size="xs4" label="Current berth" checkbox />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-select
            label="Action"
            :items="quayActions"
            v-model="item.ACTION"
            textFieldName="value"
            codeFieldName="value"
            size="xs12 sm4" />
          <gat-edit label="Connections" v-model="item.HOSE_CONNECTIONS" size="xs12 sm4" :maxChars="250" />
          <gat-edit label="Sort order" v-model="item.SORT_ORDER" size="xs4 sm2" :decimals="0" dataType="number" />
          <gat-edit
            label="Current berth"
            v-model="item.ACTIVE_QUAY"
            size="xs4 sm2"
            checkbox
            :checkedValue="1"
            :uncheckedValue="0" />
        </v-layout>
      </GatGroup>

      <GatGroup title="Arrival / Departure" size="xxl" :collapsed="isNew ? !expandedIfNew : false">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field
              v-if="!item.ATA"
              :value="item.ETB"
              size="xs6 sm4"
              label="Estimated arrival"
              is-date-time />
            <gat-compact-field :value="item.ATA" size="xs6 sm4" label="Actual arrival" is-date-time />
            <gat-compact-field
              v-show="applicationStore.globalSettings.SGL_SHOW_ITC_ETC == 1"
              :value="item.ITC"
              size="xs6 sm4"
              label="Intend to commense"
              is-date-time />
            <gat-compact-field
              v-show="applicationStore.globalSettings.SGL_SHOW_ITC_ETC == 1"
              :value="item.ETC"
              size="xs6 sm4"
              label="Expect to complete"
              is-date-time />
            <gat-compact-field
              v-if="!item.ATD"
              :value="item.ETD"
              size="xs6 sm4"
              label="Estimated departure"
              is-date-time />
            <gat-compact-field :value="item.ATD" size="xs6 sm4" label="Acutal departure" is-date-time />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-edit
            v-if="isCalculated != 1"
            label="ETA"
            v-model="item.ETB"
            hint="Estimated time of arrival"
            dateTimeEdit
            futureDate
            size="xs12 sm4" />
          <gat-edit label="ATA" v-model="item.ATA" hint="Actual time of arrival" dateTimeEdit size="xs12 sm4" />
          <gat-edit
            v-if="isCalculated != 1"
            v-show="applicationStore.globalSettings.SGL_SHOW_ITC_ETC == 1"
            label="ITC"
            v-model="item.ITC"
            hint="Intended to commence"
            dateTimeEdit
            futureDate
            size="xs12 sm4" />
          <gat-edit
            v-if="isCalculated != 1"
            v-show="applicationStore.globalSettings.SGL_SHOW_ITC_ETC == 1"
            label="ETC"
            v-model="item.ETC"
            hint="Expected to complete"
            dateTimeEdit
            futureDate
            size="xs12 sm4" />
          <gat-edit
            v-if="isCalculated != 1"
            label="ETD"
            v-model="item.ETD"
            hint="Estimated time of departure"
            dateTimeEdit
            futureDate
            size="xs12 sm4" />
          <gat-edit label="ATD" v-model="item.ATD" hint="Actual time of departure" dateTimeEdit size="xs12 sm4" />
        </v-layout>
      </GatGroup>

      <GatGroup
        v-if="isCalculated"
        title="Calculation of arrival and departure time"
        size="xxl"
        :collapsed="isNew ? !expandedIfNew : false">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field :value="item.SHIFTING_TIME" size="xs3" label="Shifting" />
            <gat-compact-field :value="item.HOURS_TO_STAY" size="xs3" label="Hrs to stay" />
            <gat-compact-field :value="item.ITC_DELAY" size="xs3" label="ITC delay" />
            <gat-compact-field :value="item.ETC_DELAY" size="xs3" label="ETC delay" />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-edit label="Shifting time" v-model="item.SHIFTING_TIME" size="xs6 sm3 lg2" :timeEdit="true" />
          <gat-edit label="Hrs to stay" v-model="item.HOURS_TO_STAY" size="xs6 sm3 lg2" dataType="number" />
          <gat-edit label="ITC delay" v-model="item.ITC_DELAY" size="xs6 sm3 lg2" :timeEdit="true" />
          <gat-edit label="ETC delay" v-model="item.ETC_DELAY" size="xs6 sm3 lg2" :timeEdit="true" />
        </v-layout>
      </GatGroup>

      <GatGroup title="Vessel position" size="xxl" :collapsed="isNew ? !expandedIfNew : false">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field :value="item.VESSEL_ORIENTATION" size="xs6" label="Orientation" />
            <gat-compact-field :value="item.POSITION_REMARK" size="xs6" label="Remark" />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-select
            label="Orientation"
            :items="vesselOrientations"
            v-model="item.VESSEL_ORIENTATION"
            size="xs12 sm4"
            :required="applicationStore.globalSettings.SGL_USE_PORTBASE == 1" />
          <gat-edit label="Remark" v-model="item.POSITION_REMARK" :maxChars="250" size="xs12 sm4" />
          <gat-edit label="Bollard from" v-model="item.BOLLARD_FROM" size="xs6 sm2" :decimals="0" dataType="number" />
          <gat-edit label="Bollard to" v-model="item.BOLLARD_TO" size="xs6 sm2" :decimals="0" dataType="number" />
        </v-layout>
      </GatGroup>

      <GatGroup title="Draft" size="xxl" :collapsed="isNew ? !expandedIfNew : false">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field :value="item.ARR_DRAFT" size="xs6" label="Arrival draft" />
            <gat-compact-field :value="item.DEP_DRAFT" size="xs6" label="Departure draft" />
          </v-layout>
        </template>
        <v-layout wrap>
          <gat-edit label="Arrival draft" v-model="item.ARR_DRAFT" size="xs6 sm2" dataType="number" />
          <gat-edit label="Departure draft" v-model="item.DEP_DRAFT" size="xs6 sm2" dataType="number" />
        </v-layout>
      </GatGroup>
      <GatGroup
        v-show="applicationStore.globalSettings.SGL_SHOW_TUG_ON_BERTH == 1"
        title="Towage"
        size="xxl"
        :collapsed="isNew ? !expandedIfNew : false">
        <v-layout wrap>
          <GatSubGroup title="Towage arrival" size="xs12 sm6">
            <gat-select
              v-model="item.TUG_COMPANY"
              :items="towageCompanies"
              label="Company"
              textFieldName="text"
              codeFieldName="value"
              size="xs12 sm7"
              clearable>
            </gat-select>
            <gat-edit label="Number of tugs" v-model="item.TUG_QTY" :decimals="0" dataType="number" size="xs4 sm5" />
            <gat-edit label="Remark" v-model="item.TUG_REMARKS" :maxChars="100" size="xs8 sm12" />
          </GatSubGroup>
          <GatSubGroup title="Towage departure" size="xs12 sm6">
            <gat-select
              v-model="item.TUG_DEP_COMPANY"
              :items="towageDepCompanies"
              label="Company"
              textFieldName="text"
              codeFieldName="value"
              size="xs12 sm7"
              clearable>
            </gat-select>
            <gat-edit
              label="Number of tugs"
              v-model="item.TUG_DEP_QTY"
              :decimals="0"
              dataType="number"
              size="xs4 sm5" />
            <gat-edit label="Remark" v-model="item.TUG_DEP_REMARKS" :maxChars="100" size="xs8 sm12" />
          </GatSubGroup>
        </v-layout>
      </GatGroup>
      <GatGroup
        v-show="applicationStore.globalSettings.SGL_SHOW_PILOT_DETAILS_ON_BERTH == 1"
        title="Pilot"
        size="xxl"
        :collapsed="isNew ? !expandedIfNew : false">
        <v-layout wrap>
          <GatSubGroup title="Pilot shifting" size="xs12 sm6">
            <gat-edit
              label="Pilot needed"
              v-model="item.PILOT_IN_NEEDED"
              :disabled="agencyUsesDanPilotIntegration"
              :uncheckedValue="0"
              :checkedValue="1"
              checkbox
              size="xs12" />
            <gat-edit
              label="Pilot boarding time"
              v-model="item.PILOT_IN_PBT"
              :disabled="item.PILOT_IN_NEEDED != 1 || agencyUsesDanPilotIntegration"
              dateTimeEdit
              size="xs12 sm8" />
            <gat-edit
              label="Our ref / PO"
              v-model="item.PILOT_IN_PO"
              :disabled="item.PILOT_IN_NEEDED != 1 || agencyUsesDanPilotIntegration"
              :maxChars="50"
              size="xs4 sm4" />
            <gat-edit
              label="Remark"
              v-model="item.PILOT_IN_REMARKS"
              :disabled="item.PILOT_IN_NEEDED != 1 || agencyUsesDanPilotIntegration"
              :maxChars="0"
              size="xs8 sm12" />
          </GatSubGroup>
          <GatSubGroup title="Pilot departure" size="xs12 sm6">
            <gat-edit
              label="Pilot needed"
              v-model="item.PILOT_OUT_NEEDED"
              :disabled="agencyUsesDanPilotIntegration"
              :uncheckedValue="0"
              :checkedValue="1"
              checkbox
              size="xs12" />
            <gat-edit
              label="Pilot boarding time"
              v-model="item.PILOT_OUT_PBT"
              :disabled="item.PILOT_OUT_NEEDED != 1 || agencyUsesDanPilotIntegration"
              dateTimeEdit
              size="xs12 sm8" />
            <gat-edit
              label="Our ref / PO"
              v-model="item.PILOT_OUT_PO"
              :disabled="item.PILOT_OUT_NEEDED != 1 || agencyUsesDanPilotIntegration"
              :maxChars="50"
              size="xs4 sm4" />
            <gat-edit
              label="Remark"
              v-model="item.PILOT_OUT_REMARKS"
              :disabled="item.PILOT_OUT_NEEDED != 1 || agencyUsesDanPilotIntegration"
              :maxChars="0"
              size="xs8 sm12" />
          </GatSubGroup>
        </v-layout>
      </GatGroup>
    </gat-form>
    <gat-bottom-panel
      :orgValue="orgItem"
      :newValue="item"
      :itemDescription="'quay'"
      :saveBtn="true"
      :deleteBtn="true"
      :buttonsEnabled="!saving || !applicationStore.userRight('P.EDIT')"
      :deleteDisabled="!item.ID || !applicationStore.userRight('P.EDIT')"
      :saveDisabled="readonly || !isValid"
      @saveClicked="saveClicked"
      @deleteClicked="deleteClicked" />
  </div>
  <div v-else>{{ !this.applicationStore.appIsInitialized ? 'Please wait...' : 'No quay found.' }}</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiPut, apiDelete } from '@/store/api';
import QuaySelect from '@/app-components/QuaySelect.vue';
import { useConstantsStore } from '@/store/constantsStore';
import { useToolbarStore } from '@/store/toolbarStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
import { getBerths } from '@/services/api/api-calls/getBerths';
import { getPortcallSyncChanges } from '@/services/api/api-calls/getPortcallSyncChanges';
import { getOwnTowageCompanies } from '@/services/api/api-calls/getOwnTowageCompanies';

export default {
  name: 'QuayDockEdit',
  props: {
    quayId: Number,
    callId: [Number, String],
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  components: { QuaySelect },
  setup() {
    const constantsStore = useConstantsStore();
    const toolbarStore = useToolbarStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      constantsStore,
      toolbarStore,
      applicationStore,
      portcallStore,
    };
  },
  data() {
    return {
      expandedIfNew: false, // is set in activated/deactivated based on isNew prop.
      berthType: 'quay',
      orgItem: undefined,
      item: undefined,
      terminalId: null,
      isLoading: false,
      saving: false,
      isValid: true,
    };
  },

  activated() {
    this.setBackNavigation();
    this.scrollToTop();
    this.expandedIfNew = this.isNew;
  },

  deactivated() {
    this.toolbarStore.setBackNavigation(undefined);
    this.expandedIfNew = !this.isNew;
  },

  created() {
    getOwnTowageCompanies();
  },

  watch: {
    currentBerth: {
      immediate: true,
      handler(newVal) {
        this.isLoading = true;
        if (this.isNew) {
          this.createNewDock();
        } else if (newVal) {
          this.item = { ...newVal };
          this.orgItem = { ...newVal };
        }
        this.isLoading = false;
      },
    },
    callId() {
      if (this.callId != this.item.PORTCALL_ID) {
        this.goToPortcall();
      }
    },
  },

  computed: {
    agencyUsesDanPilotIntegration() {
      let result = false;
      const agency = this.applicationStore.agencySettings.find((item) => item.ID == this.selectedAgency);
      if (agency && agency.DAN_USEPILOTSERVICE == 1) {
        result = true;
      }
      return result;
    },

    berths() {
      return this.portcallStore.berths ? this.portcallStore.berths : [];
    },

    currentBerth() {
      if (this.berths.length > 0) {
        const idx = this.berths.findIndex((item) => item.ID === this.quayId);
        if (idx >= 0) {
          return this.berths[idx];
        }
      }
      return undefined;
    },

    isCalculated() {
      return this.portcallStore.callDetails.BERTH_CALCULATION;
    },

    quayActions() {
      return this.helperStore.cargoActions;
    },

    readonly() {
      return false;
    },

    selectedAgency() {
      return this.applicationStore.agencyIdSelected;
    },

    towageCompanies() {
      const store = this.helperStore.towageCompanies;
      const company = this.item.TUG_COMPANY ? [{ text: this.item.TUG_COMPANY, value: null }] : [];
      return store && store.length > 0 ? store : company;
    },

    towageDepCompanies() {
      const store = this.helperStore.towageCompanies;
      const company = this.item.TUG_DEP_COMPANY ? [{ text: this.item.TUG_DEP_COMPANY, value: null }] : [];
      return store && store.length > 0 ? store : company;
    },

    vesselOrientations() {
      // remove the option to select nothing if portbase is used.
      const orientations = [...this.constantsStore.vesselOrientations];
      if (this.applicationStore.globalSettings.SGL_USE_PORTBASE == 1) {
        const idx = orientations.findIndex((item) => item.value == 0);
        orientations.splice(idx, 1);
      }
      return orientations;
    },
  },

  methods: {
    createNewDock() {
      let sortOrder = 10;
      if (this.berths.length > 0) {
        const highestSortOrder = this.berths.reduce(
          // eslint-disable-next-line no-return-assign, no-param-reassign
          (op, item) => (op = op > item.SORT_ORDER ? op : item.SORT_ORDER),
          0
        );
        sortOrder = highestSortOrder + 10;
      }

      this.item = {
        PORTCALL_ID: this.callId,
        SORT_ORDER: sortOrder,
      };
      this.orgItem = { ...this.item };
    },

    deleteClicked() {
      this.isLoading = true;
      apiDelete(`dock/${this.item.ID}`).then((result) => {
        this.isLoading = false;
        if (result.resultCategory == 1) {
          if (this.isCalculated) {
            getBerths(this.callId);
          } else {
            this.portcallStore.removeBerthItem(this.item.ID);
          }
          if (result.syncChanges) {
            if (result.syncChanges.portcall) {
              getPortcallSyncChanges(this.callId);
            }
            if (result.syncChanges.sof) {
              this.portcallStore.clearSof();
            }
            if (result.syncChanges.portcall || result.syncChanges.sof) {
              this.applicationStore.setWhiteBoardDataIsDirty(true);
            }
          }
          this.$nextTick(() => {
            this.goToPortcall();
          });
        }
      });
    },

    goToPortcall() {
      this.$router.push({ name: 'callDetails', callId: this.callId });
    },

    saveClicked() {
      this.$refs.form.validate();
      if (this.isValid) {
        this.saving = true;
        this.isLoading = true;
        const orgValue = this.isNew ? {} : this.orgItem;
        const newValue = this.item;
        this.SetActionTypeValue(newValue);
        this.SetActionTypeValue(orgValue);
        apiPut('dock', { value: newValue, orgValue }).then((result) => {
          if (result.resultCategory == 1) {
            if (result.syncChanges) {
              if (result.syncChanges.portcall) {
                getPortcallSyncChanges(this.callId);
              }
              if (result.syncChanges.sof) {
                this.portcallStore.clearSof();
              }
              if (result.syncChanges.portcall || result.syncChanges.sof) {
                this.applicationStore.setWhiteBoardDataIsDirty(true);
              }
            }
            this.portcallStore.setBerths(result.payload);
            this.orgItem = { ...newValue };
            this.item = { ...newValue };
            this.saving = false;
            this.isLoading = false;
            this.$nextTick(() => {
              /* if (this.isNew) {
                const currentBerthId = result.payload[result.payload.length - 1].ID;
                this.$router.push({ name: 'quayDetails', params: { quayId: currentBerthId } });
              } */

              this.$router.push({ name: 'callDetails', params: { callId: this.callId } });
            });
          }
        });
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    SetActionTypeValue(berth) {
      const cargoAction = this.helperStore.cargoActions.find((item) => item.value == this.item.ACTION);
      if (cargoAction) {
        // eslint-disable-next-line no-param-reassign
        berth.TYPE = cargoAction.type;
        if (cargoAction.type == 2) {
          // eslint-disable-next-line no-param-reassign
          berth.TYPE_OTHER = cargoAction.value;
        } else {
          // eslint-disable-next-line no-param-reassign
          berth.TYPE_OTHER = null;
        }
      }
    },

    setBackNavigation() {
      this.toolbarStore.setBackNavigation([
        {
          text: 'port call',
          href: `/call/${this.callId}`,
        },
      ]);
    },

    setEmptyStringsToNull(keyValue) {
      if (typeof keyValue == 'string' && keyValue.length == 0) {
        return null;
      }
      return keyValue;
    },
  },
};
</script>

<style scoped></style>
