<template>
  <div>
    <expense-dash-board v-if="showExpenseDashboard" :callId="callId" />
    <gat-grid
      title="Expenses"
      :items="expenses"
      :columns="columns"
      class="elevation-0"
      :fixedheader="true"
      :height="getGridHeight"
      :hideEmptyColumns="true"
      viewGroupName="callExpenseGrid"
      reloadBtn
      dense
      :orgSortBy="orgSorting"
      :rowMenu="rowMenu"
      :loading="loading"
      :selectable="true"
      :selectedKeys="selected"
      keyName="EXP_ID"
      :defaultView="gridView"
      @rowMenuClicked="rowMenuClicked"
      @row-clicked="expenseClicked"
      @reload-clicked="getExpenses"
      @selectionChanged="setSelectedExpenses($event)">
      <template slot="btns">
        <wm-button-group
          class="right ml-auto"
          :buttons="wmButtonGroup"
          @addClick="$router.push({ name: 'newExpense', params: { gridView } })"
          @createEmailClick="(val) => createEmailClick(val)" />
      </template>
      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == 'EXP_BUDGET'" class="d-flex">
          <v-chip v-if="props.value == 1" :class="$vuetify.breakpoint.xsOnly ? '' : 'budget-label'" small label>{{
            $vuetify.breakpoint.xsOnly ? 'P' : 'Proforma'
          }}</v-chip>
          <v-chip
            v-else
            :class="$vuetify.breakpoint.xsOnly ? '' : 'budget-label'"
            small
            label
            text-color="black"
            color="green lighten-4"
            >{{ $vuetify.breakpoint.xsOnly ? 'F' : 'Final' }}</v-chip
          >
        </div>
        <div v-else-if="props.column.field == 'InvoiceNo'">
          <v-chip v-if="props.value" small label text-color="black" class="invoice-label">{{ props.value }}</v-chip>
        </div>
        <ClientCreditFlag
          v-else-if="props.column.field == 'CLIENT_CREDIT_FLAG'"
          :creditBalance="+props.row.CLIENT_CREDIT_BALANCE"
          :flagValue="+props.value" />
        <div v-else-if="props.column.field == 'APPROVED'" class="ma-0 pa-0 d-flex approval-column justify-center">
          <div
            v-if="
              props.value == 0 &&
              !applicationStore.user.internal &&
              props.row.CLIENT_ID === applicationStore.user.externalClientId
            ">
            <v-btn
              v-if="applicationStore.userRight('EX.APPROVE') && !applicationStore.user.internal"
              small
              outlined
              color="green"
              class="pa-0 px-1 ma-0 mx-1"
              @click.stop="showApprovalDialog(props.row.EXP_ID, +1)"
              >Approve</v-btn
            >
            <v-btn
              v-if="applicationStore.userRight('EX.APPROVE') && !applicationStore.user.internal"
              small
              outlined
              color="red"
              class="pa-0 px-1 ma-0 mx-1"
              @click.stop="showApprovalDialog(props.row.EXP_ID, +2)"
              >Reject</v-btn
            >
          </div>
          <v-chip v-else-if="props.value == 1" small label class="approved-label">Approved</v-chip>
          <v-chip v-else-if="props.value == 2" small label class="rejected-label" color="red lighten-4"
            >Rejected</v-chip
          >
        </div>
        <div v-else-if="props.column.field === 'hasAttachment'">
          <v-icon v-if="+props.value === 1">mdi-paperclip</v-icon>
        </div>
      </template>
    </gat-grid>
    <v-dialog v-model="showDialog" persistent max-width="360">
      <v-card>
        <v-card-title class="title">{{ dialog.title }}</v-card-title>
        <v-card-text class="text-center">{{ dialog.text }}</v-card-text>
        <gat-edit
          v-if="dialog.isReject"
          size="xs12"
          label="Reject comment"
          v-model="expenseApproval.APPROVAL_COMMENT"
          :rows="3" />
        <v-card-text v-if="dialog.error" class="text-center" style="color: red">{{ dialog.error }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="updateExpenseApporval">{{
            dialog.isReject ? 'Reject' : 'Approve'
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <email-generator :portcallId="callId" location="expense" :guid="emailTemplateGuid" />
    <!-- <email-status :showForm="showEmailStatusDialog" :guid="emailTemplateGuid" @close="showEmailStatusDialog = false" @documentReady="showEmailStatusDialog=true" @goToEmailClicked="goToEditDraft($event)" /> -->
  </div>
</template>

<script>
import ClientCreditFlag from '@/app-components/client/ClientCreditFlag.vue';
// eslint-disable-next-line import/no-cycle
import { apiPut, apiGet } from '@/store/api';
/*     import EmailStatus from '@/app-components/EmailStatus.vue'; */
import EmailGenerator from '@/app-components/EmailGenerator.vue';
import { defineComponent } from 'vue';
import { useAppStatusStore } from '@/store/appStatusStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useApplicationStore } from '@/store/applicationStore';
import { useHelperStore } from '@/store/helperStore';
import { getExpenses } from '@/services/api/api-calls/getExpenses';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import ExpenseDashBoard from './ExpenseDashboard.vue';

export default defineComponent({
  name: 'Expenses',
  props: ['callId', 'gridView'],
  components: { ExpenseDashBoard, ClientCreditFlag, EmailGenerator },
  setup() {
    const appStatusStore = useAppStatusStore();
    const portcallStore = usePortcallStore();
    const applicationStore = useApplicationStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      applicationStore,
      appStatusStore,
      portcallStore,
    };
  },
  data() {
    return {
      orgSorting: [{ field: 'CLIENTNAME' }, { header: 'Sorting' }, { header: 'Date' }, { header: 'Time' }],
      loading: false,
      selected: [],
      showDialog: false,
      dialog: {
        title: null,
        text: null,
        isReject: false,
        error: null,
      },
      expenseApproval: {
        ID: null,
        APPROVED: null,
        APPROVAL_COMMENT: null,
      },
      emailTemplates: [],
      emailTemplateGuid: undefined,
      showEmailStatusDialog: false,
    };
  },

  created() {},

  computed: {
    showExpenseDashboard() {
      return this.applicationStore.user.internal;
    },
    agencyId() {
      return this.portcallStore.callDetails.SETUP_ID;
    },
    wmButtonGroup() {
      const result = [];
      result.push({ btnType: 'ADD', disabled: !this.userCanAddExpenses(), menuCaption: 'Add expense' });
      if (this.userCanGenerateDoc) {
        result.push({
          btnType: 'CREATE_DOC',
          docType: 11,
          agencyId: this.agencyId,
          applicationStatus: this.applicationStatus,
          location: 'portcall',
          foreignKey: this.callId,
          disabled: this.selected.length <= 0,
        });
        if (
          this.applicationStore.globalSettings.SGL_WM_EMAIL_PROVIDER !== 0 &&
          this.applicationStore.userRight('EM.EDIT')
        ) {
          result.push({
            btnType: 'CUSTOM',
            caption: 'Create email',
            icon: 'mdi-email',
            menuItems: this.emailTemplateItems,
            disabled: this.emailTemplateItems.length === 0,
          });
        }
      }
      return result;
    },
    userCanGenerateDoc() {
      const isInternal = this.applicationStore.user.internal;
      if (isInternal) {
        return true;
      }
      const externalRights = this.applicationStore.userRight('EX.GENERATE_DOCUMENT');
      return externalRights;
    },
    applicationStatus() {
      return this.applicationStore.getAppStatus;
      /* let result = {};
              result.VesselId = this.portcallStore.callDetails.VESSEL_ID;
              result.PortCall = {};
              result.PortCall.Id = this.callId;
              result.PortCall.Expense ={SelectedIds:this.selected};
              if(this.selected && this.selected.length > 0){
                  let id = this.selected[0];
                  let expense = this.portcallStore.expenses.find(item=>item.EXP_ID == id);
                  if (expense){
                      result.PortCall.Expense.SelectedClientId = expense.CLIENT_ID;
                  }

              }
              return result; */
    },
    columns() {
      const result = [];
      /* if (this.loading) {
        return result;
      } */
      result.push({ header: '#', field: 'GRID_LINE_NO', hideOnPhone: true, hide: true });
      if (this.$vuetify.breakpoint.xsOnly) {
        result.push({ header: '', field: 'EXP_BUDGET', checkbox: true, width: '10px' });
      } else {
        result.push({ header: 'Budget', field: 'EXP_BUDGET', checkbox: true });
      }
      result.push({
        header: 'Att.',
        field: 'hasAttachment',
        checkbox: true,
        hideOnPhone: false,
        hide: false,
        maxWidth: '1%',
        width: '1%',
        minWidth: '1%',
      });
      result.push({ header: 'Date', field: 'EXP_DATE', dataType: 'date', hideOnPhone: true, minWidth: '110px' });
      result.push({ header: 'Time', field: 'EXPENSE_TIME', dataType: 'time', hideOnPhone: true, hide: true });
      result.push({ header: 'Group', field: 'EXP_GRP', hideOnPhone: true });
      result.push({ header: 'Template', field: 'ELT_NAME', hideOnPhone: true, hide: true });

      result.push({ header: 'Description', field: 'EXP_TEXT', hideOnPhone: false });
      result.push({
        header: 'Qty',
        field: 'EXP_QUANTITY',
        hideOnPhone: true,
        dataType: 'number',
        maxDecimals: 3,
        hide: true,
      });
      result.push({ header: 'Price', field: 'EXP_PRICE', hideOnPhone: true, dataType: 'currency', hide: true });
      result.push({ header: 'Subtotal', field: 'EXP_AMOUNT', hideOnPhone: true, dataType: 'currency', hide: true });
      result.push({ header: 'Handling', field: 'EXP_HANDLING', hideOnPhone: true, dataType: 'currency', hide: true });
      result.push({ header: 'Hours', field: 'EXP_WORK_HOURS', hideOnPhone: true, dataType: 'currency', hide: true });
      result.push({
        header: 'Work amount',
        field: 'EXP_WORK_AMOUNT',
        hideOnPhone: true,
        dataType: 'currency',
        hide: true,
      });
      result.push({
        header: 'VAT',
        field: 'EXP_VAT',
        hideOnPhone: true,
        dataType: 'currency',
        hide: true,
        footerFunction: { fn: 'sum' },
      });
      result.push({ header: 'VAT', field: 'VAT_PCT', hideOnPhone: true, hide: true });
      result.push({ header: 'Total', field: 'EXP_TOTAL_SUM', hideOnPhone: false, dataType: 'currency', hide: true });
      result.push({ header: 'Cur', field: 'EXP_CURRENCY_NAME', hideOnPhone: true, hide: true });

      result.push({
        header: `Total (${
          this.applicationStore.getAgencySettings(this.portcallStore.callDetails.SETUP_ID).LocalCurrency
        })`,
        field: 'EXP_LOCAL_SUM',
        hideOnPhone: true,
        dataType: 'currency',
        footerFunction: { fn: 'sum' },
      });
      if (this.applicationStore.user.internal) {
        result.push({ header: 'Revenue', field: 'Revenue', hideOnPhone: true, dataType: 'currency', hide: true });
      }
      /* result.push( {"header":"Client credit balance","field":"CLIENT_CREDIT_BALANCE",hideOnPhone:true} ); */ // Client credit balance
      result.push({ header: '', field: 'CLIENT_CREDIT_FLAG', hideOnPhone: true, width: '1%' }); // Client credit flag
      result.push({ header: 'Client', field: 'CLIENTNAME', hideOnPhone: true });
      result.push({ header: 'Supplier', field: 'SUPPNAME', hideOnPhone: true, hide: true });
      result.push({ header: 'Voucher', field: 'VOUCHER_REF', hideOnPhone: true, hide: true });
      result.push({ header: 'User', field: 'INITIALS', hideOnPhone: true, hide: true });
      result.push({ header: 'Grp sort', field: 'EG_SORT_ORDER', hideOnPhone: true, dataType: 'number', hide: true });
      result.push({ header: 'Ref', field: 'REF_NUMBER', hideOnPhone: true, hide: true });
      result.push({ header: 'PO #', field: 'PO_NO', hideOnPhone: true, hide: true });
      result.push({ header: 'Invoice #', field: 'InvoiceNo', hideOnPhone: true });
      result.push({
        header: 'In. inv #',
        field: 'IIN_INTERNAL_NUMBER',
        hideOnPhone: true,
        hide: true,
        calculatedClass: (item) => this.isDarkModeEnabled(item),
      });
      result.push({ header: 'Status', field: 'EXPENSE_STATUS_TEXT', hideOnPhone: true, hide: true });
      result.push({
        header: 'Org budget amount',
        field: 'EXP_ORIG_BUDGET',
        hideOnPhone: true,
        dataType: 'currency',
        hide: true,
      });
      result.push({
        header: 'Org actual amount',
        field: 'ORIGINAL_ACTUAL_VALUE',
        hideOnPhone: true,
        dataType: 'currency',
        hide: true,
      });
      result.push({ header: 'Cost code', field: 'EXP_ACCOUNT_CODE', hideOnPhone: true, hide: true });
      result.push({ header: 'Misc', field: 'EXP_MISC', hideOnPhone: true, hide: true });
      result.push({
        header: 'Voucher printed',
        field: 'VOUCHER_PRINTED',
        hideOnPhone: true,
        dataType: 'datetime',
        hide: true,
      });
      result.push({ header: 'Printed', field: 'PRINTED_DATE', hideOnPhone: true, dataType: 'datetime', hide: true });
      result.push({ header: 'Exported', field: 'BATCH_NO', hideOnPhone: true, hide: true });
      result.push({ header: 'CODE1', field: 'CODE1', hideOnPhone: true, hide: true });
      result.push({ header: 'CODE2', field: 'CODE2', hideOnPhone: true, hide: true });
      /* result.push( {"header":"Approved","field":"APPROVED",hideOnPhone:true,checkbox:true,hide:true} );
              result.push( {"header":"Approval comment","field":"APPROVAL_COMMENT",hideOnPhone:true,hide:true} ); */
      result.push({ header: 'Inv cur', field: 'INVOICE_CURRENCY', hideOnPhone: true, hide: true });
      result.push({
        header: 'Inv ROE',
        field: 'INV_EXCHANGE_RATE',
        hideOnPhone: true,
        dataType: 'number',
        maxDecimals: 5,
        hide: true,
      });
      result.push({ header: 'Inv amount', field: 'InvAmount', hideOnPhone: true, dataType: 'currency', hide: true });
      result.push({ header: 'Sorting', field: 'SORTING', hideOnPhone: true, dataType: 'number', hide: false });

      result.push({ header: 'Cargo description', field: 'CARGO_DESCRIPTION', hideOnPhone: true, hide: true });
      result.push({
        header: 'Inv line amount',
        field: 'INVL_AMOUNT',
        hideOnPhone: true,
        dataType: 'currency',
        hide: true,
      });
      result.push({
        header: 'Approval',
        field: 'APPROVED',
        width: '190px',
        class: 'ma-0 pa-0',
        hide: this.hasNoApprovals,
        neverHide: true,
      });
      result.push({
        header: 'Reject comment',
        field: 'APPROVAL_COMMENT',
        class: 'ma-0 pa-0',
        hide: this.hasNoApprovalComments,
        // eslint-disable-next-line consistent-return
        calculated: (item) => {
          if (item.APPROVED == 0) return item.APPROVAL_COMMENT;
        },
      });

      // dynafields columns
      for (let index = 0; index < this.helperStore.dynafields.length; index++) {
        const dynafield = this.helperStore.dynafields[index];
        if (dynafield.USE_IN_GRID && dynafield.COMPONENT_NAME == 'pDynaFields.ExpenseDyna') {
          const col = { header: dynafield.CAPTION, field: `dyn_${dynafield.ID}`, hideOnPhone: true };

          result.push(col);
        }
      }

      return result;
    },

    expenses() {
      return this.portcallStore.expenses ? this.portcallStore.expenses : [];
    },

    getGridHeight() {
      if (!this.showExpenseDashboard) {
        return 'calc(100vh - 135px)';
      }
      return 'calc(100vh - 240px)';
    },

    hasNoApprovals() {
      if (this.applicationStore.userRight('EX.APPROVE') && !this.applicationStore.user.internal) {
        return false;
      }
      for (let i = 0; this.expenses.length > i; i++) {
        if (this.expenses[i].APPROVED != null) {
          return false;
        }
      }
      return true;
    },

    hasNoApprovalComments() {
      /* if(this.applicationStore.userRight('EX.APPROVE')){} */

      for (let i = 0; this.expenses.length > i; i++) {
        if (
          this.expenses[i].APPROVED == 2 &&
          this.expenses[i].APPROVAL_COMMENT &&
          this.expenses[i].APPROVAL_COMMENT.length > 0
        ) {
          return false;
        }
      }
      return true;
    },

    rowMenu() {
      const result = [
        {
          name: 'duplicate',
          caption: 'duplicate expense...',
          icon: 'mdi-content-duplicate',
          enabled: this.userCanAddExpenses(),
        },
        {
          name: 'setfinal',
          caption: 'set as final!',
          icon: 'mdi-check',
          enabled: (rowItem) => this.userCanAddExpenses() && rowItem.EXP_BUDGET == 1,
        },
      ];
      return result;
    },
    emailTemplateItems() {
      const templates = [...this.emailTemplates];
      let result = [];
      if (Array.isArray(templates) && templates.length > 0) {
        result = templates.map((item) => ({
          caption: item.TITLE,
          eventName: 'createEmailClick',
          icon: 'mdi-file-outline',
          ID: item.ID,
        }));
      }
      return result;
    },
  },

  watch: {
    callId: {
      async handler(id) {
        this.getExpenses(id);
      },
      immediate: true,
    },
    showDialog(newValue) {
      if (!newValue) {
        this.expenseApproval.APPROVAL_COMMENT = null;
        this.dialog.error = null;
      }
    },
  },

  methods: {
    setSelectedExpenses(selectedExpenseIds) {
      this.selected = selectedExpenseIds;
      this.appStatusStore.setAppStatusExpenseSelectedIds(selectedExpenseIds);
      if (Array.isArray(selectedExpenseIds) && selectedExpenseIds.length > 0) {
        const id = selectedExpenseIds[0];
        const expense = this.portcallStore.expenses.find((item) => item.EXP_ID == id);
        if (expense) {
          this.appStatusStore.setAppStatusExpenseClientId(expense.CLIENT_ID);
        }
      }
    },
    updateExpenseApporval() {
      apiPut('expense/approve', this.expenseApproval).then((result) => {
        if (result) {
          const { expenses } = this.portcallStore;
          const idx = expenses.findIndex((item) => item.EXP_ID == this.expenseApproval.ID);
          let expense;
          if (idx >= 0) {
            expense = { ...expenses[idx] };
          }
          expense.APPROVED = this.expenseApproval.APPROVED;
          if (this.expenseApproval.APPROVED == 2) {
            expense.APPROVAL_COMMENT = this.expenseApproval.APPROVAL_COMMENT;
          }
          this.portcallStore.replaceExpenseItem(expense);

          this.showDialog = false;
        } else {
          this.dialog.error = 'Unable to save: Insufficient user rights.';
        }
      });
    },

    showApprovalDialog(expenseId, inputValue) {
      const isReject = inputValue == 2;
      if (expenseId) {
        this.expenseApproval.ID = expenseId;
        this.dialog.isReject = isReject;
        if (isReject) {
          this.expenseApproval.APPROVED = 2;
          this.dialog.title = 'Reject expense';
          this.dialog.text = 'Are you sure you want to reject this expense?';
        } else {
          this.expenseApproval.APPROVED = 1;
          this.dialog.title = 'Approve expense';
          this.dialog.text = null;
        }

        if (!this.showDialog) {
          this.showDialog = true;
        }
      }
    },
    // eslint-disable-next-line consistent-return
    isDarkModeEnabled(item) {
      if (item.InvoiceNo || item.IIN_INTERNAL_NUMBER) {
        const darkModeValueFromLocalStorage = localStorage.getItem('dark_theme');
        const darkModeValueFromStore = this.applicationStore.settings.dark;

        // Make color bright(text visible) if dark mode is enabled
        if (darkModeValueFromLocalStorage == 'true' || darkModeValueFromStore) {
          return 'green';
        }
        return 'green lighten-4';
      }
    },

    expenseClicked(rowItem) {
      const params = {
        expenseId: rowItem.EXP_ID,
        gridView: this.gridView,
        duplicate: false,
      };
      this.$router.push({ name: 'callExpense', params });
    },

    getExpenses(id = null) {
      if (!id) {
        // eslint-disable-next-line no-param-reassign
        id = this.callId;
      }
      this.loading = true;
      getExpenses(id).then(() => {
        this.loading = false;
        apiGet(`mail/templates/expense/${this.agencyId}`).then((result) => {
          if (result) {
            this.emailTemplates = result;
          }
        });
      });
    },

    createEmailClick(item) {
      if (this.callId) {
        const guid = GlobalHelperFunctions.GUID();
        const templateId = item.ID;
        const location = 'portcall';
        const foreignKey = this.callId;
        const appStatus = JSON.stringify(this.applicationStatus);
        const param = { guid, templateId, location, foreignKey, appStatus, isEmail: 1 };
        apiPut('document/generate/', param).then((result) => {
          if (result.resultCategory === 0 && result.resultType === 0) {
            this.emailTemplateGuid = guid;
            this.showEmailStatusDialog = true;
          }
        });
      }
    },

    rowMenuClicked(event) {
      /* console.log(event); */
      if (event.menuItem.name == 'duplicate') {
        const params = {
          expenseId: event.rowItem.EXP_ID,
          gridView: this.gridView,
          duplicate: true,
        };
        this.$router.push({ name: 'callExpense', params });
      } else if (event.menuItem.name == 'setfinal') {
        apiPut('expense', {
          value: { ID: event.rowItem.EXP_ID, PORTCALL_ID: this.callId, BUDGET: 0 },
          orgValue: { ID: event.rowItem.EXP_ID, PORTCALL_ID: this.callId, BUDGET: 1 },
        }).then(() => {
          // eslint-disable-next-line no-param-reassign
          event.rowItem.EXP_BUDGET = 0;
        });
      }
    },

    userCanAddExpenses() {
      if (this.portcallStore.expenseLocked) {
        return false;
      }
      return this.applicationStore.userRight('EX.NEW');
    },
  },
});
</script>

<style lang="scss" scoped>
.budget-label {
  min-width: 75px;
}
.invoice-label,
.approved-label {
  background: #c8e6c9 !important;
}
.rejected-label {
  background: #ffcdd2 !important;
}
.theme--dark {
  .budget-label.v-chip:not(.v-chip--active),
  .invoice-label,
  .approved-label {
    background: green !important;
    color: black;
  }
  .rejected-label {
    background: rgb(255, 18, 18) !important;
    color: black;
  }
}

.rightBtn {
  display: flex;
  justify-content: flex-end;
}

.approval-column {
  max-width: 150px;
}
</style>
