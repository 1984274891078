<template>
  <div>
    <gat-form v-model="isValid" class="mb-12" :isLoading="isLoading" ref="form">
      <v-container class="ma-0 pa-0" style="max-width: 100% !important">
        <v-layout class="d-flex justify-space-between">
          <div class="d-flex justify-start align-center" style="flex-grow: 1">
            <div class="d-flex justify-start align-baseline pr-4" style="flex-grow: 1">
              <gat-select
                v-model="CurrentSOFId"
                :items="sofTitles"
                label="Select SOF"
                textFieldName="TITLE"
                codeFieldName="ID"
                size="xs12 sm6"
                :disabled="!globalObjectsAreAlike(sof, orgSof)" />
              <!--  -->
              <gat-reload-btn
                class="mt-5"
                hint="Reload the selected SOF"
                :orgValue="orgSof"
                :newValue="sof"
                @click="getSofs()" />
              <wm-button-group
                class="ml-auto"
                :rightAligned="true"
                :buttons="gridButtons"
                @addClick="showDialog = true"
                @createEmailClick="(val) => createEmailClick(val)" />
            </div>
          </div>
        </v-layout>
      </v-container>
      <sof-detail
        v-if="CurrentSOFId > 0"
        :berths="berths"
        :anchorage="anchorage"
        v-model="sof"
        :readOnly="!userCanEdit" />
      <sof-line-edit-grid
        v-if="CurrentSOFId > 0"
        v-model="sof.statementLines"
        caption="Statements"
        :sofId="CurrentSOFId"
        lineType="statements"
        :readOnly="!userCanEdit"
        @lines-deleted="sof.statementLines = $event"
        :dataLoaded="sofStatementLoaded"
        @loadingFinished="sofStatementLoaded = false"
        @saveDisabled="setSaveDisabled($event)" />
      <sof-line-edit-grid
        v-if="CurrentSOFId > 0"
        v-model="sof.workLines"
        :sortAutomatically.sync="sortAutomatically"
        caption="Workline items"
        :sofId="CurrentSOFId"
        lineType="workLines"
        :berths="berths"
        :readOnly="!userCanEdit"
        @lines-deleted="sof.workLines = $event"
        :dataLoaded="sofWorklineLoaded"
        @loadingFinished="sofWorklineLoaded = false"
        @saveDisabled="setSaveDisabled($event)"
        :cargoes="cargoes" />

      <gat-bottom-panel
        :orgValue="orgSof"
        :newValue="sof"
        itemDescription="statement of facts"
        :saveBtn="true"
        :deleteBtn="true"
        :buttonsEnabled="!saving"
        :deleteDisabled="!sof || parseInt(CurrentSOFId) <= 0 || !userCanDelete"
        :saveDisabled="saveDisabled"
        @saveClicked="saveClicked"
        @deleteClicked="deleteClicked"
        @leftWithoutSaving="leftWithoutSaving">
      </gat-bottom-panel>
      <SofAddTemplate
        :showDialog="showDialog"
        @close="showDialog = false"
        @sofAdded="onSofAdded($event)"
        @SOF-templates="setSofTemplates($event)"
        :callId="callId"
        :isLoading="isLoading"
        @loading="loading($event)"
        :sofTemplates="sofTemplates" />
    </gat-form>
    <email-generator :portcallId="callId" location="sof" :guid="emailTemplateGuid" />
  </div>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete, apiPost } from '@/store/api';
import EmailGenerator from '@/app-components/EmailGenerator.vue';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useAppStatusStore } from '@/store/appStatusStore';
import { useHelperStore } from '@/store/helperStore';
import { getSofTitles } from '@/services/api/api-calls/getSofTitles';
import { getWorklineCargoes } from '@/services/api/api-calls/getWorklineCargoes';
import { getPortcallSyncChanges } from '@/services/api/api-calls/getPortcallSyncChanges';
import { getBerths } from '@/services/api/api-calls/getBerths';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import SofAddTemplate from './SofAddTemplate.vue';
import SofDetail from './SofDetail.vue';
import SofLineEditGrid from './SofLineEditGrid.vue';

export default {
  name: 'SofPage',
  props: ['callId', 'accessGroup', 'urlSofId'],
  components: { SofLineEditGrid, SofDetail, SofAddTemplate, EmailGenerator },
  setup() {
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const appStatusStore = useAppStatusStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      applicationStore,
      portcallStore,
      appStatusStore,
    };
  },
  data() {
    return {
      CurrentSOFId: -1,
      orgSof: {},
      sof: {},
      saving: null,
      showDialog: false,
      isLoading: false,
      isValid: null,
      sofTemplates: [],
      sofStatementLoaded: false,
      sofWorklineLoaded: false,
      saveDisabled: false,
      sortAutomatically: 'Unchecked',
      orgSortAutomatically: 'Unchecked',
      emailTemplates: [],
      emailTemplateGuid: undefined,
    };
  },

  activated() {
    if (this.portcallStore.sofs.length <= 0) {
      this.getSofs();
    }
  },

  created() {
    // Handling of sof from url
    if (this.urlSofId) {
      this.CurrentSOFId = parseInt(this.urlSofId, 10);

      // Set route to without sof id at the end (to prevent problems with url in the address bar and selecting other items)
      this.$router.push({ name: 'callSof' });
    }
  },

  computed: {
    agencyId() {
      return this.portcallStore.callDetails.SETUP_ID;
    },
    gridButtons() {
      const result = [];
      result.push({
        btnType: 'ADD',
        menuCaption: 'Add SOF',
        disabled: !this.userCanAdd || !GlobalHelperFunctions.globalObjectsAreAlike(this.sof, this.orgSof),
      });
      if ((!this.applicationStore.user.internal && this.userCanGenerateDoc) || this.applicationStore.user.internal) {
        result.push({
          btnType: 'CREATE_DOC',
          docType: 4,
          agencyId: this.agencyId,
          applicationStatus: this.getApplicationStatus,
          location: 'portcall',
          foreignKey: this.callId,
          disabled:
            this.CurrentSOFId <= 0
              ? true
              : !this.userCanGenerateDoc || !GlobalHelperFunctions.globalObjectsAreAlike(this.sof, this.orgSof),
        });
        if (
          this.applicationStore.globalSettings.SGL_WM_EMAIL_PROVIDER !== 0 &&
          this.applicationStore.userRight('EM.EDIT')
        ) {
          result.push({
            btnType: 'CUSTOM',
            caption: 'Create email',
            icon: 'mdi-email',
            menuItems: this.emailTemplateItems,
            disabled: this.emailTemplateItems.length === 0,
          });
        }
      }

      return result;
    },
    portCallId() {
      return this.portcallStore.callDetails.ID;
    },
    unsavedPages() {
      return this.applicationStore.unsavedPages;
    },
    berths() {
      const result = [];
      if (this.portcallStore.berths) {
        // eslint-disable-next-line array-callback-return
        this.portcallStore.berths.map((item) => {
          if (item.BA_TYPE == 'Quay') {
            const quay = {};
            quay.value = item.QUAY_ID;
            quay.text = item.NAME;
            quay.DOCK_ID = item.ID;
            result.push(quay);
          }
        });
      }
      return result;
    },
    cargoes() {
      const result = [];
      if (this.helperStore.worklineCargoes) {
        // eslint-disable-next-line array-callback-return
        this.helperStore.worklineCargoes.map((item) => {
          const cargo = {};
          cargo.ID = item.ID;
          cargo.TEXT = item.REF_NO ? `${item.REF_NO}. ${item.BL_TEXT}` : item.BL_TEXT;
          result.push(cargo);
        });
      }
      return result;
    },

    anchorage() {
      const result = [];
      if (this.portcallStore.berths) {
        // eslint-disable-next-line array-callback-return
        this.portcallStore.berths.map((item) => {
          if (item.BA_TYPE == 'Anchorage') {
            const anc = {};
            anc.value = item.ANC_ID;
            anc.text = item.NAME;
            result.push(anc);
          }
        });
      }
      return result;
    },

    getApplicationStatus() {
      return this.appStatusStore.getAppStatus;
      /* let result = {};
                    result.VesselId = this.portcallStore.callDetails.VESSEL_ID;
                    result.PortCall = {};
                    result.PortCall.Id = this.callId;
                    result.PortCall.SofId = this.CurrentSOFId;

                    return result; */
    },

    sofTitles() {
      const { sofTitles } = this.portcallStore;
      if (sofTitles.length === 1) {
        this.setCurrentSOF(this.portcallStore.sofTitles[0].ID);
      }
      return sofTitles;
    },
    userIsInternal() {
      return this.applicationStore.user.internal;
    },
    userCanAccess() {
      let canAccess = false;
      if (!this.userIsInternal) {
        canAccess = this.getUserRight('SOF.ACCESS');
      } else {
        // no user rights for internal to access.
        canAccess = true;
      }
      return canAccess;
    },
    userCanAdd() {
      let canAdd = false;
      if (!this.userIsInternal) {
        canAdd = this.getUserRight('SOF.NEW_AND_EDIT');
      } else {
        canAdd = this.getUserRight('SOF.NEW_AND_EDIT');
      }
      if (this.sofTemplates.length == 0) {
        canAdd = false;
      }
      return canAdd;
    },
    userCanEdit() {
      let canEdit = false;
      if (!this.userIsInternal) {
        canEdit = this.getUserRight('SOF.EDIT');
      } else {
        canEdit = this.getUserRight('SOF.NEW_AND_EDIT');
      }
      return canEdit;
    },
    userCanDelete() {
      return this.getUserRight('SOF.DELETE');
    },
    userCanGenerateDoc() {
      const isInternal = this.applicationStore.user.internal;
      if (isInternal) {
        return true;
      }
      const externalRights = this.applicationStore.userRight('SOF.GENERATE_DOCUMENT');
      return externalRights;
    },
    emailTemplateItems() {
      const templates = [...this.emailTemplates];
      let result = [];
      if (Array.isArray(templates) && templates.length > 0) {
        result = templates.map((item) => ({
          caption: item.TITLE,
          eventName: 'createEmailClick',
          icon: 'mdi-file-outline',
          ID: item.ID,
        }));
      }
      return result;
    },
  },

  watch: {
    sofTitles: {
      immediate: true,
      handler() {
        if (this.sofTitles && this.sofTitles.length == 1) {
          this.CurrentSOFId = this.sofTitles[0].ID || -1;
        } /*  else {
                            this.CurrentSOFId = -1;
                        } */
      },
    },
    portCallId: {
      handler() {
        if (this.sofTitles.length != 1) {
          this.CurrentSOFId = 0;
        }
      },
    },

    CurrentSOFId: {
      handler(sofId) {
        if (parseInt(sofId, 10) > 0) {
          this.loadSof(sofId, true);
        } else {
          this.sof = {};
          this.sof.workLines = [];
          this.sof.statementLines = [];
          this.orgSof = {};
          this.orgSof.workLines = [];
          this.orgSof.statementLines = [];
        }
      },
      immediate: true,
    },
  },

  methods: {
    globalObjectsAreAlike(objA, objB) {
      return GlobalHelperFunctions.globalObjectsAreAlike(objA, objB);
    },
    createEmailClick(item) {
      if (this.callId) {
        const guid = GlobalHelperFunctions.GUID();
        const templateId = item.ID;
        const location = 'portcall';
        const foreignKey = this.callId;
        const appStatus = JSON.stringify(this.getApplicationStatus);
        const param = { guid, templateId, location, foreignKey, appStatus, isEmail: 1 };
        apiPut('document/generate/', param).then((res) => {
          if (res.resultCategory === 0 || res.resultCategory === 1) this.emailTemplateGuid = guid;
        });
      }
    },
    setSaveDisabled(value) {
      this.saveDisabled = value;
    },
    setCurrentSOF(id) {
      this.CurrentSOFId = id;
    },
    setSofTemplates(templates) {
      this.sofTemplates = templates;
    },
    getUserRight(userRight) {
      return this.applicationStore.userRight(userRight);
    },
    loading(value) {
      this.isLoading = value;
    },
    getSofs() {
      getSofTitles(this.callId);
      if (this.sofTitles && this.sofTitles.length == 1) {
        this.CurrentSOFId = this.sofTitles[0].ID;
      }
      const sofId = this.CurrentSOFId;
      if (parseInt(sofId, 10) > 0) {
        this.loadSof(sofId, true);
      } else {
        this.CurrentSOFId = -1;
      }
    },

    onSofAdded(data) {
      this.CurrentSOFId = data;
    },

    deleteClicked() {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`/sof/${this.CurrentSOFId}`).then((result) => {
        // remove progressbar
        this.isLoading = false;
        if (result.resultCategory == 1) {
          getSofTitles(this.callId);
          this.CurrentSOFId = -1;
        }
      });
    },

    loadSof(sofId, force) {
      if (sofId) {
        let sof = {};

        if (!force && this.portcallStore.sofs && this.portcallStore.sofs.length > 0) {
          sof = this.portcallStore.sofs.find((item) => item.ID == sofId);
        }

        if (!force && sof) {
          this.sof = GatUtils.makeCopyViaJson(sof);
          this.orgSof = GatUtils.makeCopyViaJson(sof);
        } else {
          this.isLoading = true;
          apiGet(`sof/${sofId}`).then((result) => {
            this.sortAutomatically = result.sortOrderSetting.VALUE_TEXT;
            this.orgSortAutomatically = result.sortOrderSetting.VALUE_TEXT;
            this.sof = result;
            // eslint-disable-next-line array-callback-return
            this.sof.workLines.map((item) => {
              if (item.FROM_TIME && item.FROM_TIME.length > 5) {
                // eslint-disable-next-line no-param-reassign
                item.FROM_TIME = item.FROM_TIME.substring(0, 5);
              }
              if (item.TO_TIME && item.TO_TIME.length > 5) {
                // eslint-disable-next-line no-param-reassign
                item.TO_TIME = item.TO_TIME.substring(0, 5);
              }
            });
            // eslint-disable-next-line array-callback-return
            this.sof.statementLines.map((item) => {
              if (item.FROM_TIME && item.FROM_TIME.length > 5) {
                // eslint-disable-next-line no-param-reassign
                item.FROM_TIME = item.FROM_TIME.substring(0, 5);
              }
              if (item.TO_TIME && item.TO_TIME.length > 5) {
                // eslint-disable-next-line no-param-reassign
                item.TO_TIME = item.TO_TIME.substring(0, 5);
              }
            });
            this.orgSof = GatUtils.makeCopyViaJson(result);
            this.portcallStore.setSof(this.sof);
            if (this.sof.ID) {
              this.appStatusStore.setAppStatusSelectedSofId(this.sof.ID);
            }
            apiGet(`mail/templates/sof/${this.agencyId}`).then((emailTemplateResult) => {
              if (emailTemplateResult) {
                this.emailTemplates = emailTemplateResult;
              }
            });
            this.$nextTick(() => {
              this.sofStatementLoaded = true;
              this.sofWorklineLoaded = true;
              this.isLoading = false;
            });
          });
        }
        getWorklineCargoes({ callid: this.callId, sofid: this.CurrentSOFId });
      } else {
        this.emailTemplates = [];
      }
    },

    saveClicked() {
      // remove temp ID
      this.$refs.form.validate();
      if (this.isValid) {
        // eslint-disable-next-line array-callback-return
        this.sof.statementLines.map((item) => {
          if (item.ID < 0) {
            // eslint-disable-next-line no-param-reassign
            delete item.ID;
          }
        });
        // eslint-disable-next-line array-callback-return
        this.sof.workLines.map((item) => {
          if (item.ID < 0) {
            // eslint-disable-next-line no-param-reassign
            delete item.ID;
          }
        });
        this.isLoading = true;
        const saveValues = {
          value: this.sof,
          orgValue: this.orgSof,
        };
        apiPut('sof', saveValues)
          .then((result) => {
            if (result.resultCategory == 1) {
              if (this.sortAutomatically !== this.orgSortAutomatically) {
                apiPost(`sof/sort-order-setting/${this.sortAutomatically}`);
              }
              this.sof = result.payload;
              this.orgSof = GatUtils.makeCopyViaJson(result.payload);
              this.portcallStore.setSof(this.sof);
              // update sof-list with new title if the title has changed
              if (saveValues.orgValue.TITLE != saveValues.value.TITLE) {
                this.portcallStore.updateSofTitle({ id: saveValues.value.ID, title: saveValues.value.TITLE });
              }

              if (result.syncChanges) {
                if (result.syncChanges.portcall) {
                  getPortcallSyncChanges(this.callId);
                }
                if (result.syncChanges.berth) {
                  getBerths(this.callId);
                }
                if (result.syncChanges.portcall || result.syncChanges.berth) {
                  this.applicationStore.setWhiteBoardDataIsDirty(true);
                }
              }
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    leftWithoutSaving() {
      this.sof = GatUtils.makeCopyViaJson(this.orgSof);
    },
  },
};
</script>

<style lang="scss" scoped>
.rightBtn {
  position: absolute;
  top: 0px;
  right: 75px;
  margin-top: 0px;
}

.addBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 0px;
}
.test {
  padding-top: 60px !important;
  margin-top: 50px !important;
  .rightBtn,
  .addBtn {
    top: 50px;
  }
}
</style>
