<template>
  <gat-form class="d-flex flex-column flex-grow-1" style="height: calc(100vh - 95px)" :isLoading="isLoading">
    <div v-if="email" class="d-flex flex-column">
      <div>
        <div class="d-flex">
          <div class="d-flex flex-grow-1 caption ma-0 pa-0">
            <div>
              <span class="mr-1">sent:</span
              ><span>{{ globalFormatDateTime(email.SENT, 'DD. MMMM YYYY HH:mm:ss') }}</span>
              <p
                v-if="email.SUBJECT"
                class="text-h6 grey--text text--darken-3 ma-0 mt-1 pa-0 d-flex flex-inline align-center">
                <strong class="mr-1">{{ email.SUBJECT }}</strong>
                <grid-label v-if="email.LABEL_ID" :labelId="email.LABEL_ID" class="ml-2" style="width: fit-content" />
              </p>
            </div>
          </div>
          <div class="ml-auto pa-2">
            <wm-button-group :buttons="wmBtnGrpButtons" @click="buttonGroupClicked" />
          </div>
        </div>

        <div v-if="email.FROM" class="d-flex align-center ma-0 my-1 pa-0 small-font">
          <div style="width: 50px; text-align: right">
            <strong class="mr-1 grey--text text--darken-1" style="font-weight: 500">From:</strong>
          </div>
          <a :href="`mailto:${email.FROM.email}`">
            <v-chip outlined label small link class="px-2 gridLink">{{ email.FROM.asString }}</v-chip>
          </a>
        </div>
        <div v-else class="d-flex align-center ma-0 my-1 pa-0 small-font">
          <div style="width: 50px; text-align: right">
            <strong class="mr-1 grey--text text--darken-1" style="font-weight: 500">From:</strong>
          </div>
          {{ email.FROM_ }}
        </div>
        <div v-if="email.TO && email.TO.length > 0" class="d-flex align-center ma-0 my-1 pa-0 small-font">
          <div style="width: 50px; text-align: right">
            <span class="mr-1 grey--text text--darken-1" style="font-weight: 500">To:</span>
          </div>
          <div v-for="(to, index) in email.TO" :key="'s' + index">
            <a :href="`mailto:${to.email}`" class="mr-2">
              <v-chip outlined label small link class="px-2 gridLink">{{ to.asString }}</v-chip>
            </a>
          </div>
        </div>
        <div v-else-if="email.TO_" class="d-flex align-center ma-0 my-1 pa-0 small-font">
          <div style="width: 50px; text-align: right">
            <strong class="mr-1 grey--text text--darken-1">To:</strong>
          </div>
          {{ email.TO_ }}
        </div>
        <div v-if="email.CC && email.CC.length > 0" class="d-flex align-center ma-0 my-1 pa-0 small-font">
          <div style="width: 50px; text-align: right">
            <span class="mr-1 grey--text text--darken-1" style="font-weight: 500">Cc:</span>
          </div>
          <div v-for="(cc, index) in email.CC" :key="'s' + index">
            <a :href="`mailto:${cc.email}`" class="mr-2">
              <v-chip outlined label small link class="px-2 gridLink">{{ cc.asString }}</v-chip>
            </a>
          </div>
        </div>
        <div v-else-if="email.CC_" class="d-flex align-center ma-0 my-1 pa-0 small-font">
          <div style="width: 50px; text-align: right">
            <strong class="mr-1 grey--text text--darken-1">Cc:</strong>
          </div>
          {{ email.CC_ }}
        </div>
        <div v-if="email.BCC && email.BCC.length > 0" class="d-flex align-center ma-0 my-1 pa-0 small-font">
          <div style="width: 50px; text-align: right">
            <span class="mr-1 grey--text text--darken-1" style="font-weight: 500">Bcc:</span>
          </div>
          <div v-for="(bcc, index) in email.BCC" :key="'s' + index">
            <a :href="`mailto:${bcc.email}`" class="mr-2">
              <v-chip outlined label small link class="px-2 gridLink">{{ bcc.asString }}</v-chip>
            </a>
          </div>
        </div>
        <div v-else-if="email.BCC_" class="d-flex align-center ma-0 my-1 pa-0 small-font">
          <div style="width: 50px; text-align: right">
            <strong class="mr-1 grey--text text--darken-1">Bcc:</strong>
          </div>
          {{ email.BCC_ }}
        </div>
      </div>
      <div v-if="email.MAIL_ATTACHMENTS" class="d-flex flex-inline justify-start align-center my-1">
        <!-- <strong>Attachments:</strong> -->
        <div v-for="(attachment, index) in email.MAIL_ATTACHMENTS" :key="'email_attachment' + index">
          <v-chip @click="downloadEmailAttachment(attachment.FILE_NAME)" class="mx-1" link pill>
            <v-icon :color="getMdiFileIcon(attachment.FILE_NAME).color" size="20px">{{
              getMdiFileIcon(attachment.FILE_NAME).icon
            }}</v-icon>
            <span class="ml-2">
              {{ attachment.FILE_NAME }}
            </span>
          </v-chip>
        </div>
      </div>
    </div>
    <v-divider />
    <iframe
      v-if="email && email.MAIL_HTML"
      :srcdoc="email.MAIL_HTML"
      class="d-flex flex-grow-1"
      style="display: flex !important"
      frameborder="0" />
    <v-dialog v-model="showDeleteDialog" persistent max-width="340">
      <v-card>
        <v-card-title class="title">Delete</v-card-title>
        <v-card-text class="text-center">Are you sure you want to delete this e-mail?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDeleteDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="deleteEmail()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </gat-form>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiDelete, apiGetFile } from '@/store/api';
import DocumentHelper from '@/common/DocumentHelper';
import GridLabel from '@/app-components/GridLabel.vue';
import { isValidEmail } from '@/modules/email/emailFunctions';
import { useToolbarStore } from '@/store/toolbarStore';
import { useApplicationStore } from '@/store/applicationStore';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'EmailItem',
  props: {
    emailId: {
      type: Number,
      required: true,
    },
    portcallId: Number,
    vesselId: Number,
    clientId: Number,
    location: String,
  },
  components: { GridLabel },
  setup() {
    const toolbarStore = useToolbarStore();
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
      toolbarStore,
    };
  },
  data() {
    return {
      email: null,
      isLoading: false,
      replyContent: '',
      replySubject: '',
      replyTo: '',
      showDeleteDialog: false,
    };
  },

  activated() {
    this.setBackNavigation();
  },

  deactivated() {
    this.toolbarStore.setBackNavigation(null);
  },

  watch: {
    emailId: {
      immediate: true,
      handler(val) {
        if (val) this.getEmail();
      },
    },
  },

  computed: {
    /** If the user can delete emails. */
    canDelete() {
      return this.applicationStore.userRight('EM.DELETE');
    },
    /** If the user can create or update emails. */
    canEdit() {
      return this.applicationStore.userRight('EM.EDIT');
    },
    wmBtnGrpButtons() {
      const result = [];
      // if (this.applicationStore.user.internal) {
      if (this.applicationStore.globalSettings.SGL_WM_EMAIL_PROVIDER !== 0 && this.canEdit) {
        result.push({ btnType: 'CUSTOM', caption: 'Forward', icon: 'mdi-share', eventName: 'onForwardClick' });
        result.push({ btnType: 'CUSTOM', caption: 'Reply all', icon: 'mdi-reply-all', eventName: 'onReplyAllClick' });
        result.push({ btnType: 'CUSTOM', caption: 'Reply', icon: 'mdi-reply', eventName: 'onReplyClick' });
      }
      if (this.canDelete) {
        result.push({ btnType: 'DELETE', eventName: 'deleteClick' });
      }
      // }
      return result;
    },
  },

  methods: {
    globalFormatDateTime(date, format) {
      return GlobalHelperFunctions.globalFormatDateTime(date, format);
    },
    getMdiFileIcon(fileName) {
      return DocumentHelper.getMdiFileIcon(fileName);
    },
    buttonGroupClicked(btn) {
      const event = btn.button.eventName;
      if (event === 'onReplyClick') {
        this.onReplyClick();
      }
      if (event === 'onReplyAllClick') {
        this.onReplyClick('all');
      }
      if (event === 'onForwardClick') {
        this.onReplyClick('forward');
      }
      if (event === 'deleteClick') {
        this.showDeleteDialog = true;
      }
    },
    getEmail() {
      this.email = null;
      this.isLoading = true;
      apiGet(`mail/${this.emailId}`)
        .then((result) => {
          this.email = result;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    downloadEmailAttachment(fileName) {
      if (fileName) {
        apiGetFile(`mail/${this.emailId}/download/${fileName}`, fileName).then(() => {});
      }
    },

    async deleteEmail() {
      const id = this.email.ID;
      this.isLoading = true;
      this.showDeleteDialog = false;
      const result = await apiDelete(`mail/${id}`);
      if (result.resultCategory === 1) {
        this.goToEmailList(true);
      }
    },

    goToEmailList(refreshPage) {
      let dirty = 0;
      if (refreshPage) {
        dirty = Math.random();
      }
      this.$nextTick(() => {
        this.isLoading = false;
        if (this.portcallId) {
          this.$router.push({ name: 'portcallEmailList', params: { dirty } });
        }
        if (this.vesselId) {
          this.$router.push({ name: 'vesselEmailList', params: { dirty } });
        }
        if (this.clientId) {
          this.$router.push({ name: 'clientEmailList', params: { dirty } });
        }
      });
    },

    setBackNavigation() {
      const result = [];
      if (this.portcallId) {
        result.push({ text: 'port call', href: `/call/${this.portcallId}` });
        result.push({ text: 'e-mail list', href: `/call/${this.portcallId}/e-mail` });
      }
      if (this.vesselId) {
        result.push({ text: 'vessel', href: `/vessel/${this.vesselId}` });
        result.push({ text: 'e-mail list', href: `/vessel/${this.vesselId}/e-mail` });
      }
      if (this.clientId) {
        result.push({ text: 'client', href: `/client/${this.clientId}` });
        result.push({ text: 'e-mail list', href: `/client/${this.clientId}/e-mail` });
      }
      this.toolbarStore.setBackNavigation(result);
    },
    async onReplyClick(replyType = null) {
      // Returns error & result properties.
      const replyTo = await this.createRecipientsArray(replyType, this.email.TO, true);
      const to = replyTo.result;
      let { error } = replyTo;
      let cc = [];
      if (Array.isArray(this.email.CC) && this.email.CC.length > 0) {
        const replyCc = await this.createRecipientsArray(replyType, this.email.CC);
        cc = replyCc.result;
        error = [...error, ...replyCc.error];
      }
      const bcc = [];
      // Bcc skal ikke være med på reply all.
      /* if(Array.isArray(this.email.BCC) && this.email.BCC.length > 0){
                      const replyBcc = await this.createRecipientsArray(replyType, this.email.BCC)
                      bcc = replyBcc.result;
                      error = [...error, ...replyBcc.error]
                  } */
      let emailId = 0;
      const result = await apiGet('mail/new-email');
      if (result && result.payload) {
        emailId = result.payload.ID;
      }

      const content = this.createReplyBody();
      const mailStyle = this.getReplyContentStyle();
      const subject = `${replyType === 'forward' ? 'FWD:' : 'RE:'} ${this.email.SUBJECT}`;
      const attachments = [];
      // Må sende med attachments på forward...
      if (
        replyType === 'forward' &&
        this.email &&
        this.email.MAIL_ATTACHMENTS &&
        Array.isArray(this.email.MAIL_ATTACHMENTS)
      ) {
        this.email.MAIL_ATTACHMENTS.forEach((attachment) => {
          attachments.push({ ...attachment, emailId: this.emailId });
        });
      }
      if (this.location === 'client' && this.clientId) {
        this.$router.push({
          name: 'clientEmailReply',
          params: {
            clientId: this.clientId,
            emailId,
            to,
            cc,
            bcc,
            content,
            mailStyle,
            subject,
            attachments,
            location: this.location,
            onLoadError: error,
          },
        });
      }
      if (this.location === 'portcall' && this.portcallId) {
        this.$router.push({
          name: 'prortcallEmailReply',
          params: {
            portcallId: this.portcallId,
            emailId,
            to,
            cc,
            bcc,
            content,
            mailStyle,
            subject,
            attachments,
            location: this.location,
            onLoadError: error,
          },
        });
      }
      if (this.location === 'vessel' && this.vesselId) {
        this.$router.push({
          name: 'vesselEmailReply',
          params: {
            portcallId: this.vesselId,
            emailId,
            to,
            cc,
            bcc,
            content,
            mailStyle,
            subject,
            attachments,
            location: this.location,
            onLoadError: error,
          },
        });
      }
    },
    getReplyContentStyle() {
      const myRe = /(<style.*>(.*)<\/style>)(?=.*<\/head>)/migs;
      const str = this.email.MAIL_HTML;
      const result = str.match(myRe);
      console.log("getReplyContentStyle", result.length, result);
      if (result && result.length === 1){
        return result[0];
      }
      return "";
    },
    createReplyBody() {
      let result = '<br/>';
      // eslint-disable-next-line no-underscore-dangle
      if (this.email.FROM_) {
        // A html-frmatted string with the from-email.
        // eslint-disable-next-line no-underscore-dangle
        const fromText = `<b>From: </b> <a href="mailto:${this.email.FROM_}">${this.email.FROM_}</a>`;
        result = `${fromText} <br/>`;
      }
      let toText = '';
      // An array of strings (emails).
      const toEmails = this.email.TO.map((item) => item.email);
      if (Array.isArray(toEmails) && toEmails.length > 0) {
        // A html-frmatted string with the to-email(s).
        toText = `<b>To: </b> `;
        // Create the HTML-string from toEmails and assign it to the toText variable.
        toEmails.forEach((email) => {
          toText += `<a href="mailto:${email}">${email}</a>`;
        });
      }
      if (toText) {
        result += `${toText} <br/>`;
      }
      let ccText = '';
      const ccEmails = this.email.CC.map((item) => item.email);
      if (Array.isArray(ccEmails) && ccEmails.length > 0) {
        // A html-frmatted string with the to-email(s).
        ccText = `<b>Cc: </b> `;
        // Create the HTML-string from toEmails and assign it to the toText variable.
        ccEmails.forEach((email) => {
          ccText += `<a href="mailto:${email}">${email}</a>`;
        });
      }
      if (ccText) {
        result += `${ccText} <br/>`;
      }
      const sent = `<b>Sent:</b> ${this.globalFormatDateTime(this.email.SENT, 'dddd DD MMM YYYY HH:mm')}`;
      result += `${sent} <br/>`;
      const subject = `<b>Subject:</b> ${this.email.SUBJECT}`;
      result += `${subject} <br/>`;
      result += `${this.email.MAIL_HTML}`;
      return `&nbsp;
                  <hr/>
                  <div style="padding-left: 16px; color: #1f497d;">${result}</div>`;
    },
    async createRecipientsArray(replyType, arrayValue, addFromRecipientToArray = false) {
      const error = [];
      const result = [];
      if (replyType === 'forward') {
        return { result, error };
      }
      const promises = [];
      if (addFromRecipientToArray && isValidEmail(this.email.FROM.email)) {
        promises.push(
          apiGet(`portcall/emailRecipients/addManual/${this.email.FROM.email}`).then((res) => {
            if (Array.isArray(res) && res.length > 0) {
              // If a client was found, use that client.
              result.push(res[0]);
            } else {
              // If no client was found, just use the email.
              result.push(this.email.FROM.email);
            }
          })
        );
      } else if (addFromRecipientToArray) {
        error.push(`The e-mail this was sent from is not a valid e-mail address: ${this.email.FROM.email}`);
      }
      if (replyType === 'all') {
        if (Array.isArray(this.email.TO)) {
          const toArray = [...arrayValue];
          toArray.forEach((item) => {
            if (isValidEmail(item.email)) {
              promises.push(
                apiGet(`portcall/emailRecipients/addManual/${item.email}`).then((res) => {
                  if (Array.isArray(res) && res.length > 0) {
                    result.push(res[0]);
                  } else {
                    result.push(item.email);
                  }
                })
              );
            } else {
              error.push(`email ${item.email} is not a valid e-mail address`);
            }
          });
        }
      }
      await Promise.allSettled(promises);
      return { error, result };
    },
  },
};
</script>

<style lang="scss" scoped>
.small-font {
  font-size: 0.9rem;
}
a {
  text-decoration: none;
}
</style>
