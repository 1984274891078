import moment from 'moment';
import numeral from 'numeral';
import GatCommon from '@/plugins/gat-components/common/GatCommon';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export const GatGridMixin = {
  components: {},

  methods: {
    cellData(column, rowItem) {
      let value = +rowItem[column.field];
      if (Object.prototype.hasOwnProperty.call(column, 'calculated')) {
        value = column.calculated(rowItem);
      }
      if (column.linkUrl) {
        const url = column.linkUrl.replace(' ', '');
        return { caption: this.replaceFields(column.linkText, rowItem), href: this.replaceFields(url, rowItem, true) };
        /* `<a class="gridLink" href="${this.replaceFields(url, rowItem, true)}">${this.replaceFields(
          column.linkText,
          rowItem
        )}</a>` */
      }

      if (column.icon) {
        return value;
      }

      if (column.checkbox) {
        if (value == '1') {
          return 'mdi-check';
        }
        // return "check_box_outline_blank";
      }

      if (column.field == 'GRID_LINE_NO') {
        const idx = this.visibleItems.findIndex((item) => item == rowItem);
        return idx + 1;
      }
      if (Object.prototype.hasOwnProperty.call(column, 'calculated')) {
        return value;
      }
      /* let wrapStyle = 'style="white-space: pre-wrap;word-wrap: break-word;"';
      if (this.noWrapCol(column)) {
        wrapStyle = ''; // nowrap class is set in colClass
      }
      return `<span ${wrapStyle} >${this.formatValue(rowItem[column.field], column.dataType, false, column)}</span>`; */
      return this.formatValue(rowItem[column.field], column.dataType, false, column);
    },

    colClass(col, rowItem, colsClass, index, stickyFirstColumn, noPadding) {
      let result = '';

      if (col && col.calculatedClass) {
        result = col.calculatedClass(rowItem);
      }

      if (col && col.class) {
        result = `${result} ${col.class}`;
      }

      if (colsClass) {
        result = `${result} ${colsClass}`;
      }

      // no wrap settings
      if (this.noWrapCol(col)) {
        result += ' nowrap';
      }

      if (!noPadding) {
        result += ' px-4';
      }

      if (this.$vuetify.breakpoint.xsOnly) {
        if (!noPadding) {
          result += ' pr-2 pl-1';
        }
      } else if (this.$vuetify.breakpoint.smOnly) {
        if (!noPadding) {
          result += ' pr-3 pl-2';
        }
      }

      if (col && col.checkbox) {
        result += ' text-xs-center';
      }

      if (stickyFirstColumn && index == 0) {
        result += ' sticky-column';
      }

      return result;
    },

    colStyle(column, tight) {
      let result = '';
      if (column) {
        if (column.dataType == 'currency' || column.dataType == 'number') {
          result += ' text-align:right; ';
        }
        if (column.width) {
          result = `${result}width:${column.width}; max-width:${column.width}; min-width:${column.width};`;
        }
        if (column.minWidth) {
          result = `${result} min-width:${column.minWidth};`;
        }
        if (tight) {
          result += 'padding-top:7px; padding-bottom:0px; margin-bottom:-10px; padding-left:3px; padding-right:3px;';
        }
        if (column.columnStyle) {
          result = `${result} ${column.columnStyle}`;
        }
      }
      return result;
    },

    formatValue(value, dataType, isUrl, column) {
      let result = `${value}`;
      if (result == 'null' || result == 'undefined') {
        result = '';
      }
      if (isUrl) {
        result = result.replace(/ /g, '');
      }

      if (dataType == 'currency') {
        if (value == null) {
          result = '';
        } else {
          result = numeral(value).format('0,0.00');
        }
      }

      if (dataType == 'duration') {
        // value = duration in milliseconds
        if (value == null) {
          result = '';
        } else {
          // NB!  the column does currently not have a durationFormat property, as we only have one valid format that is default.
          result = GlobalHelperFunctions.formatDuration(value, column.durationFormat);
        }
      }

      if (dataType == 'number') {
        if (value == null) {
          result = '';
        } else if (column && column.numberFormat) {
          result = numeral(value).format(column.numberFormat);
        } else {
          result = numeral(value).format('0,0.[000000]');
        }
      }

      if (column && Object.prototype.hasOwnProperty.call(column, 'maxDecimals')) {
        if (value == null) {
          result = '';
        } else {
          result = numeral(value).format(`0.[${'00000000000000000'.substr(1, column.maxDecimals)}]`);
        }
      }

      if (column && Object.prototype.hasOwnProperty.call(column, 'decimals')) {
        if (value == null) {
          result = '';
        } else {
          result = numeral(value).format(`0,0.${'00000000000000000'.substr(1, column.decimals)}`);
        }
      }

      if (dataType == 'date') {
        if (value == null) {
          result = '';
        } else if (column && column.datetimeFormat) {
          result = moment(value).format(column.datetimeFormat);
        } else {
          result = moment(value).format(GatCommon.datetimeDisplaySettings.dateFormat);
        }
      }

      if (dataType == 'time') {
        if (value == null) {
          result = '';
        } else {
          const t = moment(value, 'HH:mm:ss');
          if (column && column.datetimeFormat) {
            result = t.format(column.datetimeFormat);
          } else {
            result = t.format(GatCommon.datetimeDisplaySettings.timeFormat);
          }
        }
      }

      if (dataType == 'datetime') {
        if (value == null) {
          result = '';
        } else {
          let formatString = GatCommon.datetimeDisplaySettings.datetimeFormat;
          if (column && column.datetimeFormat) {
            formatString = column.datetimeFormat;
          }

          // if time is NULL, we should format whithout time
          if (this.isDateWithoutTime(value) && formatString.includes(' ')) {
            // eslint-disable-next-line prefer-destructuring
            formatString = formatString.split('H')[0];
          }

          result = moment(value).format(formatString);
        }
      }

      return result;
    },

    isDateWithoutTime(value) {
      if (typeof value == 'string') {
        if (value.endsWith('00:00:01')) {
          return true;
        }
        return !value.includes('T');
      }
      return false;
    },

    noWrapCol(col) {
      let result = false;

      if (col.nowrap) {
        return true;
      }

      if (
        col.dataType == 'number' ||
        col.dataType == 'currency' ||
        col.dataType == 'datetime' ||
        col.dataType == 'date'
      ) {
        result = true;
      }

      return result;
    },

    replaceFields(text, item, isUrl) {
      let result = text;
      if (text) {
        const fieldSections = text.split('field[');
        for (let index = 1; index < fieldSections.length; index++) {
          // kun felt fra andre, pga split: 'Navn field[LASTNAME], field[FIRST].' => ['Navn ' , 'LASTNAME], ' , 'FIRST].']
          const str = fieldSections[index];
          if (str.includes(']')) {
            const field = str.substring(0, str.indexOf(']'));
            result = result.replace(`field[${field}]`, this.formatValue(item[field], 'string', isUrl));
          }
        }
      }

      return result;
    },
  },
};
