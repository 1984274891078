export const GatInputMixin = {
  components: {},

  computed: {
    getLabel() {
      let result = this.label;
      if (this.getRequiredOrOptionalHint != '') {
        if (!result.endsWith('*')) {
          result += '*';
        }
      }
      return result;
    },
    getMessages() {
      const result = this.getRequiredOrOptionalHint;
      if (result != '') {
        return result;
      }
      return undefined;
    },
    getRequiredOrOptionalHint() {
      let result = '';
      // onlys how for empty fields
      if (!(this.textValue == '' || this.textValue == null)) {
        return '';
      }
      // normally hint is shown on required field only
      if (this.required) {
        result = '*required';
      }

      // if most fields are required, we can insted mark the optional fields
      if (this.markOptionalInputs) {
        if (this.required) {
          result = '';
        } else {
          result = '*optional';
        }
      }
      return result;
    },
  },

  methods: {
    // returns true if errors, false if no erros found.  Willby default emit "OnErrorState" if error is found
    checkForErrors(inputValue, altRules) {
      let { rules } = this;
      if (altRules) {
        rules = altRules;
      }
      if (!rules) {
        return false;
      }
      for (let index = 0; index < rules.length; index++) {
        const rule = rules[index];
        const ruleDataType = typeof rule;
        let ruleValue;
        if (ruleDataType == 'function') {
          ruleValue = rules[index].call(this, inputValue);
        } else {
          ruleValue = rule;
        }
        if (ruleValue !== true) {
          this.$emit('OnErrorState', inputValue);
          return true;
        }
      }
      return false;
    },

    expandParentGatGroup() {
      try {
        const grp = this.getParentGatGroup(this);
        if (grp) {
          grp.isExpanded = true;
        }
      } catch (error) {
        console.log(`expandParentGatGroup (${this.label}): `, error);
      }
    },

    getParentGatGroup(component) {
      const parent = component.$parent;
      if (!parent) {
        return null;
      }

      if (parent.compName != 'GatGroup') {
        return this.getParentGatGroup(parent);
      }
      return parent;
    },
  },
};
