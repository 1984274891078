// Pinia Store
import GatUtils from '@/app-components/GatUtils';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useHelperStore } from './helperStore';

export const usePortcallStore = defineStore('portcallStore', () => {
  const callDetails = ref<any>({ empty: true, extra: {}, tabsHasData: {} });

  const callDetailsLoading = ref(true);
  const cargo = ref<any[]>([]);
  const checklist = ref<any[]>([]);
  const documents = ref<any[]>([]);
  const expenses = ref<any[]>([]);
  const hotlist = ref<any[]>([]);
  const services = ref<any[]>([]);
  const sofTitles = ref<any[]>([]);
  const sofs = ref<any[]>([]);
  const berths = ref<any[]>([]);
  const lastCallsUsed = ref<any[]>([]);
  const voyage = ref<any[]>([]);
  const visitors = ref<any[]>([]);
  /** used in the visitors list for external users. (has additional data) */
  const visitorsExtended = ref<any[]>([]);
  const crewChange = ref({
    people: {} as any,
    tasks: {} as any,
    filter: [] as any[],
  });
  const expenseLocked = ref(false);
  const sendEmailProspects = ref<any>(undefined);

  function setExpenseLocked(value: boolean) {
    expenseLocked.value = value;
  }

  function setBerths(data: any[]) {
    berths.value = data;
  }

  function removeBerthItem(id: string | number) {
    const result = [...berths.value];
    const idx = result.findIndex((item) => item.ID === id);
    if (idx >= 0) {
      result.splice(idx, 1);
    }
    berths.value = result;
  }

  // call details

  function setCallDetailsLoading(value: boolean) {
    callDetailsLoading.value = value;
  }

  function setCallDetails(data: any) {
    callDetails.value = data;
    berths.value = data.berths;
    setCallDetailsLoading(false);
  }

  function setSendEmailProspects(payload: any) {
    sendEmailProspects.value = payload;
  }

  function setCallSyncChanges(data: any) {
    const result = { ...callDetails.value };
    result.ETA = data.ETA;
    result.ETA_DATE = data.ETA_DATE;
    result.ETA_TIME = data.ETA_TIME;

    result.ETD = data.ETD;
    result.ETD_DATE = data.ETD_DATE;
    result.ETD_TIME = data.ETD_TIME;

    result.ATA = data.ATA;
    result.ATA_DATE = data.ATA_DATE;
    result.ATA_TIME = data.ATA_TIME;

    result.ATD = data.ATD;
    result.ATD_DATE = data.ATD_DATE;
    result.ATD_TIME = data.ATD_TIME;

    result.STATUS = data.STATUS;
    callDetails.value = result;
  }

  // last calls used
  function setLastPortCalls(data: any[]) {
    lastCallsUsed.value = data;
  }

  // clear call
  function clearCall() {
    sofs.value = [];
    services.value = [];
    sofTitles.value = [];
    // state.callDetails = [];
    berths.value = [];
    checklist.value = [];
    voyage.value = [];
    visitors.value = [];
    crewChange.value.people = [];
    crewChange.value.tasks = [];
    crewChange.value.filter = [];
  }

  // tabHasData
  function setTabHasData({ tabName, value }: { tabName: any; value: any }) {
    if (value != callDetails.value.tabsHasData[tabName]) {
      callDetails.value.tabsHasData[tabName] = value;
    }
  }

  // cargo
  function setCargo(data: any[]) {
    cargo.value = data;
    callDetails.value.tabsHasData.GOODS = data.length;
  }

  function addCargoItem(cargoItem: any) {
    const result = [...cargo.value];
    const idx = result.findIndex((item) => item.ID === cargoItem.ID);
    if (idx >= 0) {
      result.splice(idx, 1, cargoItem);
    } else {
      result.push(cargoItem);
    }
    cargo.value = result;
    callDetails.value.tabsHasData.GOODS = result.length;
  }

  function removeCargoItem(id: string | number) {
    const result = [...cargo.value];
    const idx = result.findIndex((item) => item.ID === id);
    if (idx >= 0) {
      result.splice(idx, 1);
    }
    cargo.value = result;
    callDetails.value.tabsHasData.GOODS = result.length;
  }

  // checklist
  function setChecklist(data: any[]) {
    checklist.value = data;
    callDetails.value.tabsHasData.CHECKLIST = data.length;
  }

  function addChecklistItem(completeChecklistItem: any) {
    const result = [...checklist.value];
    const newItem = {
      ID: completeChecklistItem.ID,
      CHECKED: completeChecklistItem.CHECKED,
      ITEM: completeChecklistItem.ITEM,
      GROUPING: completeChecklistItem.GROUPING,
      STATUS: completeChecklistItem.STATUS,
      CHECKED_DATE: completeChecklistItem.CHECKED_DATE,
      SORT_ORDER: completeChecklistItem.CHECKLIST_NUMBER,
      REMINDER: completeChecklistItem.REMINDER,
      REM_TYPE: completeChecklistItem.REM_TYPE,
      REM_DATETIME: completeChecklistItem.REM_DATETIME,
      REM_REL_TYPE: completeChecklistItem.REM_REL_TYPE,
      REM_RELATIVE: completeChecklistItem.REM_RELATIVE,
      IGNORE_ITEM: completeChecklistItem.IGNORE_ITEM,
      COMMENTS: completeChecklistItem.COMMENTS,
    };
    result.push(newItem);
    checklist.value = result;
    callDetails.value.tabsHasData.CHECKLIST = result.length; // Set the tabsHasData value to the number of checklist records.
  }

  function setChecklistItemChecked(obj: any) {
    const date = obj.CHECKED_DATE;
    const username = obj.CHECKED_BY;
    const status = obj.STATUS;

    const idx = checklist.value.findIndex((item) => item.ID == obj.ID);
    const item = checklist.value[idx].CHECKED;
    if (item) {
      checklist.value[idx].CHECKED = 0;
    } else {
      checklist.value[idx].CHECKED = 1;
    }
    checklist.value[idx].STATUS = status;
    checklist.value[idx].CHECKED_BY = username;
    checklist.value[idx].CHECKED_DATE = date;
  }

  function setChecklistItemIgnored(obj: any) {
    const idx = checklist.value.findIndex((item) => item.ID == obj.ID);
    const item = checklist.value[idx].IGNORE_ITEM;
    if (item) {
      checklist.value[idx].IGNORE_ITEM = 0;
    } else {
      checklist.value[idx].IGNORE_ITEM = 1;
    }
  }

  function replaceChecklistItem(completeChecklistItem: any) {
    const idx = checklist.value.findIndex((item) => item.ID == completeChecklistItem.ID);
    if (idx >= 0) {
      // replace item
      const updatedItem = checklist.value[idx];
      updatedItem.CHECKED = completeChecklistItem.CHECKED;
      updatedItem.ITEM = completeChecklistItem.ITEM;
      updatedItem.GROUPING = completeChecklistItem.GROUPING;
      updatedItem.STATUS = completeChecklistItem.STATUS;
      updatedItem.CHECKED_DATE = completeChecklistItem.CHECKED_DATE;
      updatedItem.CHECKED_BY = completeChecklistItem.CHECKED_BY;
      updatedItem.SORT_ORDER = completeChecklistItem.CHECKLIST_NUMBER;
      updatedItem.REMINDER = completeChecklistItem.REMINDER;
      updatedItem.REM_TYPE = completeChecklistItem.REM_TYPE;
      updatedItem.REM_REL_TYPE = completeChecklistItem.REM_REL_TYPE;
      updatedItem.REM_RELATIVE = completeChecklistItem.REM_RELATIVE;
      updatedItem.REM_DATETIME = completeChecklistItem.REM_DATETIME;
      updatedItem.IGNORE_ITEM = completeChecklistItem.IGNORE_ITEM;
      updatedItem.COMMENTS = completeChecklistItem.COMMENTS;

      checklist.value.splice(idx, 1, updatedItem);
    }
  }

  function removeChecklistItem(id: string | number) {
    const idx = checklist.value.findIndex((item) => item.ID == id);
    if (idx >= 0) {
      // replace item
      checklist.value.splice(idx, 1);
    }
    if (callDetails.value.tabsHasData.CHECKLIST) {
      callDetails.value.tabsHasData.CHECKLIST = checklist.value.length;
    }
  }

  // hotlist / clients
  function setHotlist(data: any[]) {
    hotlist.value = data;
    callDetails.value.tabsHasData.HOTLIST = data.length;
  }

  function replaceHotlistItem(hotlistItem: any) {
    let idx = -1;
    if (hotlistItem.H_ID > 0) {
      idx = hotlist.value.findIndex((item) => item.H_ID == hotlistItem.H_ID);
    } else if (hotlistItem.H_ID == -1) {
      idx = hotlist.value.findIndex((item) => item.CLIENT_ID == hotlistItem.CLIENT_ID);
    }
    if (idx >= 0) {
      hotlist.value.splice(idx, 1, hotlistItem);
    }
  }

  function removeHotlistItem(id: string | number) {
    const result = [...hotlist.value];
    const idx = result.findIndex((item) => item.H_ID == id);
    if (idx >= 0) {
      result.splice(idx, 1);
    }
    hotlist.value = result;
    callDetails.value.tabsHasData.HOTLIST = result.length;
  }

  function removeHotlistTempContact(id: string | number) {
    const result = [...hotlist.value];
    const idx = result.findIndex((item) => item.H_ID == -1 && item.CLIENT_ID == id);
    if (idx >= 0) {
      result.splice(idx, 1);
    }
    hotlist.value = result;
    callDetails.value.tabsHasData.HOTLIST = result.length;
  }

  // crewchange
  function setCrewChangeFilter(data: any[]) {
    crewChange.value.filter = data;
  }

  function setCrewChangePeople(data: any[]) {
    crewChange.value.people = data;
  }

  function setCrewChangeTasks(data: any[]) {
    crewChange.value.tasks = data;
  }

  // expenses
  function setExpenses(data: any[]) {
    expenses.value = data;
    callDetails.value.tabsHasData.EXPENSE = data.length;
  }

  function addExpenseItem(data: any) {
    // ikke i bruk.
    const result = [...expenses.value];
    const idx = result.find((item) => item.EXP_ID == data.EXP_ID);
    if (idx >= 0) {
      result.splice(idx, 1, data);
    } else {
      result.push(data);
    }
    expenses.value = result;
    callDetails.value.tabsHasData.EXPENSE = result.length;
  }

  function replaceExpenseItem(expenseObj: any) {
    const result = [...expenses.value];
    const idx = result.findIndex((item) => item.EXP_ID == expenseObj.EXP_ID);
    if (idx >= 0) {
      result.splice(idx, 1, expenseObj);
    }
    expenses.value = result;
    callDetails.value.tabsHasData.EXPENSE = result.length;
  }

  function removeExpenseItem(id: string | number) {
    const result = [...expenses.value];
    const idx = result.findIndex((item) => item.EXP_ID == id);
    if (idx >= 0) {
      result.splice(idx, 1);
    }
    expenses.value = result;
    callDetails.value.tabsHasData.EXPENSE = result.length;
  }

  // services
  function setServices(data: any[]) {
    services.value = data;
    callDetails.value.tabsHasData.SERVICES = data.length;
  }

  function addServiceItem(data: any) {
    const result = [...services.value];
    const idx = result.findIndex((item) => item.SER_ID == data.SER_ID);
    if (idx >= 0) {
      result.splice(idx, 1, data);
    } else {
      result.push(data);
    }
    services.value = result;
    callDetails.value.tabsHasData.SERVICES = result.length;
  }

  function removeServiceItem(id: string | number) {
    const result = [...services.value];
    const idx = result.findIndex((item) => item.SER_ID == id);
    if (idx >= 0) {
      result.splice(idx, 1);
    }
    services.value = result;
    callDetails.value.tabsHasData.SERVICES = result.length;
  }

  // sof
  function clearSof() {
    sofs.value = [];
    sofTitles.value = [];
  }

  function setSof(data: any) {
    const sof = GatUtils.makeCopyViaJson(data);

    const idx = sofs.value.findIndex((item) => item.ID == data.ID);
    if (idx >= 0) {
      sofs.value[idx] = sof;
    } else {
      sofs.value.push(sof);
    }
  }

  function setSofTitles(data: any[]) {
    sofTitles.value = GatUtils.makeCopyViaJson(data) as any;
    callDetails.value.tabsHasData.SOF = data.length;
  }

  function updateSofTitle({ id, title }: { id: any; title: any }) {
    const titles = [...sofTitles.value].map((item) => {
      const result = item;
      if (result.ID === id) {
        result.TITLE = title;
      }
      return result;
    });
    sofTitles.value = titles;
  }

  function addSofTitleAndId(data: any) {
    // set the newly added sof title and id
    const result = [...sofTitles.value];
    result.push(data);
    sofTitles.value = result;
    callDetails.value.tabsHasData.SOF = result.length;
  }

  // visitors
  function setVisitors(data: any[]) {
    visitors.value = data;
    callDetails.value.tabsHasData.VISITOR = data.length;
  }

  function setVisitorsExtended(data: any[]) {
    visitorsExtended.value = data;
  }

  function addVisitorItem(visitorItem: any) {
    const result = [...visitors.value];
    result.push(visitorItem);
    visitors.value = result;
    callDetails.value.tabsHasData.VISITOR = result.length;
    if (visitorsExtended.value.length > 0) {
      visitorsExtended.value = [];
    }
  }

  function replaceVisitorItem(visitorItem: any) {
    const idx = visitors.value.findIndex((item) => item.VIS_ID === visitorItem.VIS_ID);
    visitors.value.splice(idx, 1, visitorItem);
    if (visitorsExtended.value.length > 0) {
      const idx2 = visitorsExtended.value.findIndex((item) => item.VIS_ID === visitorItem.VIS_ID);
      if (idx2 >= 0 && idx >= 0) {
        const orgItem = {
          ...visitorsExtended.value[idx2],
          ...visitors.value[idx],
        };
        visitorsExtended.value.splice(idx, 1, orgItem);
      }
    }
  }

  function removeVisitorItem(visitorId: string | number) {
    let result = [...visitors.value];
    let idx = result.findIndex((item) => item.VIS_ID == visitorId);
    if (idx >= 0) {
      result.splice(idx, 1);
    }
    visitors.value = result;
    callDetails.value.tabsHasData.VISITOR = result.length;
    if (visitorsExtended.value.length > 0) {
      idx = -1;
      result = [...visitorsExtended.value];
      idx = result.findIndex((item) => item.VIS_ID == visitorId);
      if (idx >= 0) {
        result.splice(idx, 1);
        visitorsExtended.value = result;
      }
    }
  }

  // voyage
  function setVoyage(data: any[]) {
    voyage.value = data;
    callDetails.value.tabsHasData.ROUTE = data.length;
  }

  function addVoyageItem(voyageItem: any) {
    const result = [...voyage.value];
    result.push(voyageItem);
    voyage.value = result;
    callDetails.value.tabsHasData.ROUTE = result.length;
  }

  function replaceVoyageItem(voyageItem: any) {
    const idx = voyage.value.findIndex((item) => item.ID === voyageItem.ID);
    voyage.value.splice(idx, 1, voyageItem);
  }

  function removeVoyageItem(voyageId: string | number) {
    const result = [...voyage.value];
    const idx = result.findIndex((item) => item.ID == voyageId);
    if (idx >= 0) {
      result.splice(idx, 1);
    }
    voyage.value = result;
    callDetails.value.tabsHasData.ROUTE = result.length;
  }

  const currentWebAccessGroup = computed(() => {
    let result = useHelperStore().webAccessGroups.find((item) => item.WAG_NAME == callDetails.value.webAccessGroup);
    if (!result) {
      result = {};
    }
    return result;
  });
  const getFirstSofTitleId = computed(() => {
    const sofTitlesArray = sofTitles.value;
    if (Array.isArray(sofTitlesArray) && sofTitlesArray.length > 0 && sofTitlesArray[0].ID) {
      return sofTitlesArray[0].ID;
    }
    return undefined;
  });

  return {
    callDetails,
    callDetailsLoading,
    cargo,
    checklist,
    documents,
    expenses,
    hotlist,
    services,
    sofTitles,
    sofs,
    berths,
    lastCallsUsed,
    voyage,
    visitors,
    visitorsExtended,
    crewChange,
    expenseLocked,
    sendEmailProspects,
    setExpenseLocked,
    setBerths,
    removeBerthItem,
    setCallDetailsLoading,
    setCallDetails,
    setSendEmailProspects,
    setCallSyncChanges,
    setLastPortCalls,
    clearCall,
    setTabHasData,
    setCargo,
    addCargoItem,
    removeCargoItem,
    setChecklist,
    addChecklistItem,
    setChecklistItemChecked,
    setChecklistItemIgnored,
    replaceChecklistItem,
    removeChecklistItem,
    setHotlist,
    replaceHotlistItem,
    removeHotlistItem,
    removeHotlistTempContact,
    setCrewChangeFilter,
    setCrewChangePeople,
    setCrewChangeTasks,
    setExpenses,
    addExpenseItem,
    replaceExpenseItem,
    removeExpenseItem,
    setServices,
    addServiceItem,
    removeServiceItem,
    clearSof,
    setSof,
    setSofTitles,
    updateSofTitle,
    addSofTitleAndId,
    setVisitors,
    setVisitorsExtended,
    addVisitorItem,
    replaceVisitorItem,
    removeVisitorItem,
    setVoyage,
    addVoyageItem,
    replaceVoyageItem,
    removeVoyageItem,
    currentWebAccessGroup,
    getFirstSofTitleId,
  };
});
