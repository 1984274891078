// Pinia Store
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useHelperStore = defineStore('helperStore', () => {
  const callTypes = ref<any[]>([]);
  const cargoActions = ref<any[]>([]);
  const cargoTypes = ref<any[]>([]);
  const clientCategories = ref<any[]>([]);
  const clientTypes = ref<any[]>([]);
  const countries = ref<any[]>([]);
  const consentTypes = ref({ purposes: [] as any[], legalBases: [] as any[] });
  const currencies = ref<any[]>([]);
  const documentLabels = ref<any[]>([]);
  const documentTemplates = ref<any[]>([]);
  const dynafields = ref<any[]>([]);
  const dynaWebTabs = ref<any[]>([]);
  const hotlistTemporaryTypes = ref<any[]>([]);
  const ownHarbours = ref<any[]>([]);
  const ownTerminals = ref<any[]>([]);
  const serviceTypes = ref<any[]>([]);
  const teams = ref<any[]>([]);
  const vesselTypes = ref<any[]>([]);
  const webAccessGroups = ref<any[]>([]);
  const worklineStatements = ref<any[]>([]);
  const expenseTemplates = ref<any[]>([]);
  const packageTypes = ref<any[]>([]);
  const unitTypes = ref<any[]>([]);
  const users = ref<any[]>([]);
  const vatCodes = ref<any[]>([]);
  const tabSequence = ref<any[]>([]);
  const tarifs = ref<any[]>([]);
  const towageCompanies = ref<any[]>([]);
  const tabHasSqlScripts = ref<any>({});
  const worklineCargoes = ref<any[]>([]);
  const crewChangeTypes = ref<any[]>([]);
  const crewChangeRanks = ref<any[]>([]);
  const pendingActivities = ref(0);
  // variable for the timeout interval.
  const activitiesTimeout = ref<any>(undefined);

  function initFixedValues() {
    clientCategories.value = [
      { value: 0, text: 'Customer / debitor' },
      { value: 1, text: 'Supplier / creditor' },
      { value: 2, text: 'Other' },
    ];
  }

  function setCallTypes(data: any[]) {
    callTypes.value = data;
  }

  function setCargoTypes(data: any[]) {
    cargoTypes.value = data;
  }

  function setClientTypes(data: any[]) {
    clientTypes.value = data;
  }

  function setConsentTypes(data: any[]) {
    const purposes: any[] = [];
    const legalBases: any[] = [];
    data.forEach((item) => {
      if (item.COT_TYPE == 0) {
        purposes.push(item);
      } else if (item.COT_TYPE == 1) {
        legalBases.push(item);
      }
    });
    consentTypes.value.purposes = purposes;
    consentTypes.value.legalBases = legalBases;
  }

  function setCountries(data: any[]) {
    countries.value = data;
  }

  function setCurrencies(data: any) {
    currencies.value = data;
  }

  function setDocumentLabels(data: any[]) {
    documentLabels.value = data;
  }

  function setDocumentTemplates(data: any[]) {
    documentTemplates.value = data;
  }

  function setDynaFields(data: any[]) {
    dynafields.value = data;
  }

  function setDynaWebTabs(data: any[]) {
    dynaWebTabs.value = data;
  }

  function setExpenseTemplates(data: any) {
    const idx = expenseTemplates.value.findIndex((item) => item.agencyId == data.agencyId);
    if (idx >= 0) {
      expenseTemplates.value.splice(idx, 1, data);
    } else {
      expenseTemplates.value.push(data);
    }
  }

  function setHotlistTemporaryTypes(data: any[]) {
    hotlistTemporaryTypes.value = data;
  }

  function setOwnHarbours(data: any[]) {
    ownHarbours.value = data;
  }

  function setOwnTerminals(data: any[]) {
    ownTerminals.value = data;
  }

  function setPackageTypes(data: any[]) {
    packageTypes.value = data;
  }

  function setServiceTypes(data: any[]) {
    serviceTypes.value = data;
  }

  function setSqlScripts(data: any[]) {
    const result: any = {};
    for (let i = 0; data.length > i; i++) {
      const key = data[i].DASHBOARD.toUpperCase().trim();
      const value = data[i].SQL_SCRIPTS;
      // console.log(key, value);
      result[key] = +value;
    }
    tabHasSqlScripts.value = result;
  }

  function setTabSequence(data: any[]) {
    tabSequence.value = data;
  }

  function setTarifs(data: any[]) {
    tarifs.value = data;
  }

  function setTeams(data: any[]) {
    teams.value = data;
  }

  function setUnitTypes(data: any[]) {
    unitTypes.value = data;
  }

  function setUsers(data: any[]) {
    users.value = data;
  }

  function setVatCodes(data: any) {
    const idx = vatCodes.value.findIndex((item) => item.agencyId == data.agencyId);
    if (idx >= 0) {
      vatCodes.value.splice(idx, 1, data);
    } else {
      vatCodes.value.push(data);
    }
  }

  function setVesselTypes(data: any[]) {
    vesselTypes.value = data;
  }

  function setWebAccessGroups(data: any[]) {
    webAccessGroups.value = data;
  }

  function setWorklineStatements(data: any[]) {
    worklineStatements.value = data;
  }

  function setCargoActions(data: any[]) {
    cargoActions.value = data;
  }

  function setTowageCompanies(data: any[]) {
    towageCompanies.value = data;
  }

  function setDynaFieldSQLCombo(params: any) {
    const idx = dynafields.value.findIndex((item) => item.ID == params.ID);
    dynafields.value[idx].COMBO_ITEMS = params.COMBOITEMS;
  }

  function setWorklineCargoes(data: any[]) {
    worklineCargoes.value = data;
  }

  function setCrewChangeTypes(data: any[]) {
    crewChangeTypes.value = data;
  }

  function setPendingActivities(data: number) {
    pendingActivities.value = data;
  }

  function setcrewChangeRanks(data: any[]) {
    crewChangeRanks.value = data;
  }

  // Getters

  function getCountryValueFromCountryName(name: string) {
    if (name) {
      const countriesCopy = [...countries.value];
      const found = countriesCopy.find((item) => item.text.toUpperCase() === name.toUpperCase());
      if (found && found.value) {
        return found.value;
      }
    }
    return undefined;
  }
  const serviceStatuses = computed(() => [
    {
      ID: 10,
      NAME: 'Not handled',
      icon: 'mdi-alert-circle',
      iconClass: 'red--text',
      class: 'red--text',
    },
    {
      ID: 20,
      NAME: 'Pending',
      icon: 'hourglass_empty',
      iconClass: 'blue--text',
    },
    {
      ID: 40,
      NAME: 'Closed',
      icon: 'check_circle',
      iconClass: 'green--text',
    },
  ]);

  /* function getExpenseTemplates(agencyId: number) {
    let result = [];
    const idx = expenseTemplates.value.findIndex((item) => item.agencyId == agencyId);
    if (idx >= 0) {
      result = expenseTemplates.value[idx].data;
    }
    return result;
  } */

  function getDocumentTemplates(docType: string | number, agencyId: number) {
    const result: any[] = [];
    const templates = documentTemplates.value.filter((item) => item.TYPE == docType);
    templates.forEach((item) => {
      if (!item.filter) {
        result.push(item);
      } else if (item.filter.includes(agencyId)) {
        result.push(item);
      }
    });
    return result;
  }

  function getTeams(agencyId: number) {
    // eslint-disable-next-line consistent-return
    const result = teams.value.filter((item) => {
      if (item.AF_AGENCY_ID === agencyId) return item;
      if (item.AF_AGENCY_ID === null) {
        return item;
      }
      return false;
    });
    return result;
  }

  const allTeams = computed(() => teams.value);

  return {
    callTypes,
    cargoActions,
    cargoTypes,
    clientCategories,
    clientTypes,
    countries,
    consentTypes,
    currencies,
    documentLabels,
    documentTemplates: getDocumentTemplates,
    dynafields,
    dynaWebTabs,
    hotlistTemporaryTypes,
    ownHarbours,
    ownTerminals,
    serviceTypes,
    teams: getTeams,
    allTeams,
    vesselTypes,
    webAccessGroups,
    worklineStatements,
    expenseTemplates,
    packageTypes,
    unitTypes,
    users,
    vatCodes,
    tabSequence,
    tarifs,
    towageCompanies,
    tabHasSqlScripts,
    worklineCargoes,
    crewChangeTypes,
    crewChangeRanks,
    pendingActivities,
    // variable for the timeout interval.
    activitiesTimeout,
    initFixedValues,
    setCallTypes,
    setCargoTypes,
    setClientTypes,
    setConsentTypes,
    setCountries,
    setCurrencies,
    setDocumentLabels,
    setDocumentTemplates,
    setDynaFields,
    setDynaWebTabs,
    setExpenseTemplates,
    setHotlistTemporaryTypes,
    setOwnHarbours,
    setOwnTerminals,
    setPackageTypes,
    setServiceTypes,
    setSqlScripts,
    setTabSequence,
    setTarifs,
    setTeams,
    setUnitTypes,
    setUsers,
    setVatCodes,
    setVesselTypes,
    setWebAccessGroups,
    setWorklineStatements,
    setCargoActions,
    setTowageCompanies,
    setDynaFieldSQLCombo,
    setWorklineCargoes,
    setCrewChangeTypes,
    setPendingActivities,
    setcrewChangeRanks,
    getCountryValueFromCountryName,
    serviceStatuses,
  };
});
