<template>
  <v-dialog v-model="showDialog" persistent max-width="600">
    <gat-form v-model="isValid" ref="form">
      <v-card class="px-3">
        <v-card-subtitle class="py-0 px-0 my-0 text-overline">Port call {{ cardSubtitle }}</v-card-subtitle>
        <v-card-title class="py-0 pt-0 my-0"
          ><span style="text-transform: uppercase; margin-right: 4px">{{ cardTitleType }}</span>
          {{ cardTitle }}</v-card-title
        >
        <v-layout wrap>
          <gat-edit
            size="xs12"
            label="Name"
            v-model="hotlistItem.C_NAME"
            :autofocus="true"
            :maxChars="applicationStore.getColumnLength('Hotlist_temporary.HTE_NAME')" />
          <gat-edit
            size="xs6"
            label="Email"
            v-model="hotlistItem.C_EMAIL"
            :maxChars="applicationStore.getColumnLength('Hotlist_temporary.HTE_EMAIL')" />
          <gat-edit
            size="xs6"
            label="Phone"
            v-model="hotlistItem.C_PHONE"
            :maxChars="applicationStore.getColumnLength('Hotlist_temporary.HTE_PHONE')" />
          <gat-select
            clearable
            label="Role"
            :items="roles"
            v-model="hotlistItem.CT_NAME"
            textFieldName="NAME"
            codeFieldName="NAME"
            size="xs6" />
          <!-- <gat-select size="xs6" label="Role" :items="roles" clearable v-model="hotlistItem.CT_NAME"
                          textFieldName="NAME" codeFieldName="NAME"/> -->
        </v-layout>

        <!-- <record-presenter :value="hotlistItem" /> -->

        <v-card-actions>
          <v-btn text @click="deleteDialog = true">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">Cancel</v-btn>
          <v-btn color="primary" @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog v-model="deleteDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title">Remove from the list?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="deleteDialog = false">Cancel</v-btn>
            <v-btn text color="primary" @click="deleteItem">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </gat-form>
  </v-dialog>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import { apiPut, apiDelete } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'HotlistTempForm',
  props: ['value', 'visible'],
  components: {},
  setup() {
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
      helperStore,
      portcallStore,
    };
  },
  data() {
    return {
      hotlistItem: {},
      deleteDialog: false,
      isValid: null,
      showDialog: false,
    };
  },

  computed: {
    cardTitleType() {
      return this.value.H_ID ? 'Edit ' : 'Add ';
    },
    cardTitle() {
      return ' Provisional contact';
    },
    cardSubtitle() {
      return this.portcallStore.callDetails.PORTCALL_NUMBER;
    },
    roles() {
      const result = [];
      if (this.helperStore.hotlistTemporaryTypes) {
        // eslint-disable-next-line array-callback-return
        this.helperStore.hotlistTemporaryTypes.map((item) => {
          result.push(item.NAME);
        });
      }
      return result;
    },
  },

  created() {},

  watch: {
    value(val) {
      this.hotlistItem = GatUtils.makeCopyViaJson(val);
    },
    visible(val) {
      this.showDialog = val;
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
    deleteItem() {
      const id = this.hotlistItem.CLIENT_ID;
      apiDelete(`hotlist/temmp/${id}`).then(() => {
        this.portcallStore.removeHotlistTempContact(id);
      });

      this.$emit('close');
    },
    save() {
      this.$refs.form.validate();
      if (this.isValid) {
        const orgItem = {
          HTE_ID: this.value.CLIENT_ID,
          HTE_NAME: this.value.C_NAME,
          HTE_PHONE: this.value.C_PHONE,
          HTE_EMAIL: this.value.C_EMAIL,
          HTE_PORTCALL_ID: this.value.PORTCALL_ID,
          HTE_ROLE: this.value.CT_NAME,
        };
        const newItem = {
          HTE_ID: this.hotlistItem.CLIENT_ID,
          HTE_NAME: this.hotlistItem.C_NAME,
          HTE_PHONE: this.hotlistItem.C_PHONE,
          HTE_EMAIL: this.hotlistItem.C_EMAIL,
          HTE_PORTCALL_ID: this.hotlistItem.PORTCALL_ID,
          HTE_ROLE: this.hotlistItem.CT_NAME,
        };
        apiPut('hotlist', { value: newItem, orgValue: orgItem }).then((result) => {
          if (result.payload) {
            this.portcallStore.setHotlist(result.payload);
          }
        });

        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped>
/* .text-overline{
   font-size: .75rem!important;
  font-family: Roboto,sans-serif!important;
} */
.noUpper {
  text-transform: none !important;
}
</style>
