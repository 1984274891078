<template>
  <div>
    <v-snackbar v-model="snackbar" bottom :timeout="timeout" :color="color">
      <div>
        <v-icon v-if="icon" medium class="mr-1">{{ icon }}</v-icon>
        <span :class="textColor">{{ text }}</span>
      </div>
    </v-snackbar>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h6">
          <v-icon v-if="icon" color="error" medium class="mr-2">error</v-icon>
          {{ text }}
        </v-card-title>
        <v-card-text v-if="Array.isArray(subText) && subText.length > 0">
          <p v-for="(textLines, index) in subText" :key="index">
            {{ textLines }}
          </p>
          <v-list-item-subtitle v-if="traceId" style="color: #a8a8a8">
            <strong> TraceID: </strong> {{ traceId }}
          </v-list-item-subtitle>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'MessageHandler',
  props: [],
  components: {},
  data() {
    return {
      snackbar: false,
      dialog: false,
      timeout: 5000,
      text: '',
      textColor: null,
      subText: [],
      traceId: '',
      color: null,
      icon: 'warning',
    };
  },

  created() {
    window.messageBus.$on('showMessage', (params) => {
      //  this.$root.$on('showMessage',(params)=>{
      this.text = this.getText(params.text, params.predefined);
      if (params.traceId) {
        this.traceId = params.traceId;
      }
      this.color = params.type;
      this.textColor = null;
      this.icon = null;

      if (params.type == 'warning') {
        this.icon = 'warning';
        this.color = 'yellow darken-1';
        this.textColor = 'black--text';
      }
      if (params.type == 'error') {
        if (!params.subText) {
          this.subText = this.createSubtextArray(params.text);
        } else {
          this.subText = this.createSubtextArray(params.subText);
        }
        this.dialog = true;
      } else {
        this.snackbar = true;
      }
    });
  },

  methods: {
    createSubtextArray(subTextValue) {
      if (Array.isArray(subTextValue) && subTextValue.includes('\r')) {
        return subTextValue.split('\r');
      }
      return [subTextValue];
    },
    getText(text, predefined) {
      if (predefined) {
        // eslint-disable-next-line default-case
        switch (predefined) {
          case 1:
            return 'No changes was detected - nothing was updated';
          case 2:
            return 'Data saved';
          case 3:
            return 'Saving data failed';
          case 4:
            return 'Data deleted';
          case 5:
            return 'Deleting data failed';
          case 6:
            return 'Could not delete';
          case 7:
            return 'You do not have the necessary user rights. Access denied';
        }
      }
      return text;
    },
  },
};
</script>

<style scoped></style>
