<template>
  <div>
    <gat-form ref="form" :isLoading="isLoading" :readonly="!canAddVisitor">
      <gat-group title="Visitor" :collapsable="false">
        <v-layout wrap>
          <gat-edit label="Date" dateEdit v-model="visitor.VIS_DATE" size="sm" />
          <gat-edit label="Time" timeEdit v-model="visitor.VIS_TIME" />
          <gat-edit
            label="Company"
            :maxChars="applicationStore.getColumnLength('Visitor.VIS_COMPANY')"
            v-model="visitor.VIS_COMPANY"
            @input="doSearch" />
          <gat-edit
            label="Name"
            :maxChars="applicationStore.getColumnLength('Visitor.VIS_NAME')"
            v-model="visitor.VIS_NAME"
            required
            @input="doSearch" />
          <gat-edit
            label="Phone"
            :maxChars="applicationStore.getColumnLength('Visitor.VIS_PHONE')"
            v-model="visitor.VIS_PHONE" />
          <gat-edit label="Other" :maxChars="-1" v-model="visitor.VIS_OTHER" />
          <gat-edit
            :label="agencySettings.VIS_USER1"
            :maxChars="applicationStore.getColumnLength('Visitor.VIS_USER1')"
            v-model="visitor.VIS_USER1"
            v-if="agencySettings.VIS_USER1" />
          <gat-edit
            :label="agencySettings.VIS_USER2"
            :maxChars="applicationStore.getColumnLength('Visitor.VIS_USER2')"
            v-model="visitor.VIS_USER2"
            v-if="agencySettings.VIS_USER2" />
          <gat-edit
            :label="agencySettings.VIS_USER3"
            :maxChars="applicationStore.getColumnLength('Visitor.VIS_USER3')"
            v-model="visitor.VIS_USER3"
            v-if="agencySettings.VIS_USER3" />
          <gat-edit
            :label="agencySettings.VIS_USER4"
            :maxChars="applicationStore.getColumnLength('Visitor.VIS_USER4')"
            v-model="visitor.VIS_USER4"
            v-if="agencySettings.VIS_USER4" />
          <gat-edit
            :label="agencySettings.VIS_USER5"
            :maxChars="applicationStore.getColumnLength('Visitor.VIS_USER5')"
            v-model="visitor.VIS_USER5"
            v-if="agencySettings.VIS_USER5" />
          <gat-edit
            :label="agencySettings.VIS_USER6"
            :maxChars="applicationStore.getColumnLength('Visitor.VIS_USER6')"
            v-model="visitor.VIS_USER6"
            v-if="agencySettings.VIS_USER6" />
        </v-layout>
      </gat-group>
      <VisitorSearch
        v-if="!visitorId && !imported"
        v-show="showSearchBox"
        :visitorName="visitor.VIS_NAME"
        :visitorCompany="visitor.VIS_COMPANY"
        emp="true"
        @visitor-import="visitorImport"></VisitorSearch>
      <br />
      <gat-bottom-panel
        :orgValue="orgVisitor"
        :newValue="visitor"
        :itemDescription="'Visitor item '"
        :saveBtn="true"
        :deleteBtn="true"
        :buttonsEnabled="!saving"
        :deleteDisabled="!visitor.VIS_ID || !canDeleteVisitor"
        :saveDisabled="!canAddVisitor"
        @saveClicked="saveItem"
        @deleteClicked="deleteItem">
      </gat-bottom-panel>
    </gat-form>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPost, apiDelete, apiPut } from '@/store/api';
import GatUtils from '@/app-components/GatUtils';
import { useToolbarStore } from '@/store/toolbarStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useApplicationStore } from '@/store/applicationStore';
import { getVisitors } from '@/services/api/api-calls/getVisitors';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import VisitorSearch from './VisitorSearch.vue';

export default {
  name: 'VisitorItem',
  props: {
    callId: [Number, String],
    visitorId: [Number, String],
    isNew: Boolean,
  },
  components: {
    VisitorSearch,
  },
  setup() {
    const toolbarStore = useToolbarStore();
    const portcallStore = usePortcallStore();
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
      toolbarStore,
      portcallStore,
    };
  },
  data() {
    return {
      visitor: {},
      orgVisitor: {},
      saving: false,
      isLoading: false,
      imported: false,
      showSearchBox: false,
    };
  },

  created() {},

  activated() {
    if (this.portcallStore.visitors && this.portcallStore.visitors.length === 0) {
      getVisitors({ callId: this.callId });
    }

    this.toolbarStore.setBackNavigation([
      { text: 'port call', href: `/call/${this.callId}` },
      { text: 'visitor list', href: `/call/${this.callId}/visitors` },
    ]);
    if (this.isNew) {
      this.visitor = { VIS_PORTCALL_ID: this.callId };
      this.orgVisitor = GlobalHelperFunctions.duplicateViaJson(this.visitor);
    } else {
      this.retrieveVisitorItem(this.visitorId);
    }
  },

  deactivated() {
    this.toolbarStore.setBackNavigation([]);
  },

  watch: {},

  computed: {
    canAddVisitor() {
      if (this.applicationStore.user.internal) {
        return this.applicationStore.userRight('P.NEW_AND_EDIT');
      }
      return this.applicationStore.userRight('VI.NEW_AND_EDIT');
    },

    canDeleteVisitor() {
      if (this.applicationStore.user.internal) {
        return this.applicationStore.userRight('P.DELETE');
      }
      return this.applicationStore.userRight('VI.DELETE');
    },

    agencySettings() {
      return this.applicationStore.getAgencySettings(this.portcallStore.callDetails.SETUP_ID);
    },
  },

  methods: {
    doSearch(value) {
      if (value.length >= 3) {
        this.showSearchBox = true;
      } else {
        this.showSearchBox = false;
      }
    },
    retrieveVisitorItem(visitorId) {
      this.isLoading = true;
      apiGet(`/visitor/${visitorId}`)
        .then((result) => {
          this.visitor = result;
          this.orgVisitor = GlobalHelperFunctions.duplicateViaJson(result);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    saveItem() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.isLoading = true;
        if (this.isNew) {
          apiPost('/visitor/', this.visitor)
            .then((result) => {
              if (result.resultType === 2) {
                // 2=UPDATE_OK
                const callId = this.visitor.VIS_PORTCALL_ID;
                this.visitor = {};
                this.orgVisitor = {};
                this.portcallStore.addVisitorItem(result.payload);
                this.$nextTick().then(() => {
                  this.$router.push(`/call/${callId}/visitors/`);
                });
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          apiPut(`/visitor/${this.visitorId}`, { Value: this.visitor, OrgValue: this.orgVisitor })
            .then((result) => {
              if (result.resultType === 2) {
                // 2=UPDATE_OK
                const callId = this.visitor.VIS_PORTCALL_ID;
                const newVisitorItem = {
                  VIS_ID: this.visitor.VIS_ID,
                  VIS_PORTCALL_ID: this.visitor.VIS_PORTCALL_ID,
                  VIS_DATE: this.visitor.VIS_DATE,
                  VIS_TIME: this.visitor.VIS_TIME,
                  VIS_COMPANY: this.visitor.VIS_COMPANY,
                  VIS_NAME: this.visitor.VIS_NAME,
                  VIS_PHONE: this.visitor.VIS_PHONE,
                  VIS_OTHER: this.visitor.VIS_OTHER,
                  VIS_USER1: this.visitor.VIS_USER1,
                  VIS_USER2: this.visitor.VIS_USER2,
                  VIS_USER3: this.visitor.VIS_USER3,
                  VIS_USER4: this.visitor.VIS_USER4,
                  VIS_USER5: this.visitor.VIS_USER5,
                  VIS_USER6: this.visitor.VIS_USER6,
                };
                this.portcallStore.replaceVisitorItem(newVisitorItem);
                this.visitor = {};
                this.orgVisitor = {};
                this.$nextTick().then(() => {
                  this.$router.push(`/call/${callId}/visitors/`);
                });
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
    },
    deleteItem() {
      this.isLoading = true;
      apiDelete(`/visitor/${this.visitorId}`)
        .then((result) => {
          if (result.resultType === 4) {
            // 4==DeleteOK
            const callId = this.visitor.VIS_PORTCALL_ID;
            this.visitor = {};
            this.orgVisitor = {};
            this.portcallStore.removeVisitorItem(this.visitorId);
            this.$router.push(`/call/${callId}/visitors/`);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    visitorImport(item) {
      const importedVisitor = GatUtils.makeCopyViaJson(item);
      this.$set(importedVisitor, 'VIS_PORTCALL_ID', this.callId);
      this.$set(importedVisitor, 'VIS_DATE', this.visitor.VIS_DATE);
      this.$set(importedVisitor, 'VIS_TIME', this.visitor.VIS_TIME);
      this.visitor = GatUtils.makeCopyViaJson(importedVisitor);
      this.imported = true;
    },
  },
};
</script>

<style scoped></style>
