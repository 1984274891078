import { render, staticRenderFns } from "./GatEdit.vue?vue&type=template&id=68b0f837&scoped=true&"
import script from "./GatEdit.vue?vue&type=script&lang=js&"
export * from "./GatEdit.vue?vue&type=script&lang=js&"
import style0 from "./GatEdit.vue?vue&type=style&index=0&id=68b0f837&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68b0f837",
  null
  
)

export default component.exports