import { apiGet } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';
import { useAppStatusStore } from '@/store/appStatusStore';
import { addToLastCallUsed } from './addToLastCallUsed';
import { isExpenseOnPortcallLocked } from './isExpenseOnPortcallLocked';

function combineCallDateAndTime(date: any, time: any) {
  if (date == null || date == '') {
    return null;
  }
  if (time == null) {
    return date;
  }
  return `${date}T${time}`;
}

export function combineCallDateAndTimes(call: any) {
  const result = call;
  result.ETD = combineCallDateAndTime(call.ETD_DATE, call.ETD_TIME);
  result.ATA = combineCallDateAndTime(call.ATA_DATE, call.ATA_TIME);
  result.ATD = combineCallDateAndTime(call.ATD_DATE, call.ATD_TIME);
  result.ETA = combineCallDateAndTime(call.ETA_DATE, call.ETA_TIME);
  result.extra.ETPS = combineCallDateAndTime(call.extra.PCE_ETPS_DATE, call.extra.PCE_ETPS_TIME);
  return result;
}

export async function getPortcallDetails(callId: string | number) {
  const portcallStore = usePortcallStore();
  portcallStore.setCallDetailsLoading(true);
  const data = await apiGet(`portcall/${callId}`);
  const portcall = combineCallDateAndTimes(data);
  if (portcall.vessel && !portcall.vessel.VESSEL_PREFIX) {
    portcall.vessel.VESSEL_PREFIX = '';
  }
  portcallStore.setCallDetails(portcall);
  addToLastCallUsed(portcall);
  useAppStatusStore().createApplicationStatusWhenPortcallIsLoaded(portcall);
  isExpenseOnPortcallLocked(callId);
  return data;
}
