<template>
  <div>
    <!-- Grid View is removed from the whiteboard until we are confident about the stability of this funcionallity -->
    <!-- <gat-grid :items="rowData" :columns="columns"  :favorites="favourites" :title="whiteBoard.name" :viewGroupName="'wb_'+whiteBoard.name" reloadBtn -->
    <gat-grid
      class="table-spacing elevation-0"
      :items="rowData"
      :columns="columns"
      :favorites="favourites"
      :rowMenu="rowMenu"
      gridName="Whiteboard"
      :height="getHeight"
      :fixedheader="true"
      reloadBtn
      :hideEmptyColumns="true"
      :loading="loading || duplicating"
      :rowBar="true"
      :rowBarClass="getRowBarClass"
      :rowclass="getRowClass"
      :noCellWrap="noCellWrap"
      @favClick="favClicked"
      @row-clicked="gotoCall"
      @reload-clicked="$emit('reload')"
      @rowMenuClicked="rowMenuClicked"
      :stickyFirstColumn="false">
      <template v-if="spesialColumnRendering(props)" slot="cell" slot-scope="props">
        <span v-if="props.column.field == 'Status'" :class="whiteBoard.name == 'Search Board' ? 'pl-4' : ''">{{
          props.value
        }}</span>
        <div v-if="props.column.field == 'Comments'" class="nowrap">{{ props.value }}</div>
        <!-- CRM style="width:300px !important;"-->
        <a
          v-if="applicationStore.user.internal && props.column.GTI_NAME == 'dbo.gsf_VesselName(P'"
          :href="'/vessel/' + props.row['VESSEL_ID']"
          class="gridLink"
          >{{ props.value }}</a
        >
        <a
          v-if="applicationStore.user.internal && props.row.PAYING_PARTY_COLUMN == props.column.header"
          :href="'/client/' + props.row.PAYING_PARTY_CLIENT_ID"
          class="gridLink"
          >{{ props.row.PAYING_PARTY_CLIENT_NAME }}</a
        >
        <a
          v-if="
            (applicationStore.user.internal || applicationStore.userRight('CA.ACCESS')) &&
            props.column.GTI_NAME == 'dbo.gsf_GetCargoInfo'
          "
          :href="'/call/' + props.row.ID + '/cargo-list'"
          class="gridLink"
          >{{ props.value }}</a
        >
        <span
          v-if="props.column.GTI_NAME == 'PortCall.PORTCALL_NU' || props.column.field == 'Call no'"
          class="gridMainLink"
          >{{ props.value }}</span
        >
        <div
          v-if="props.column.GTI_NAME == 'case when Vessel.SHO' || props.column.GTI_NAME == '(select TOP 1 177 as'"
          class="d-flex flex-column nowrap justify-center">
          <v-icon color="orange">{{ props.value == 177 ? 'mdi-alert-outline' : '' }}</v-icon>
        </div>
        <v-icon v-if="props.column.checklistIcon" :color="setChecklistIconColor(props.value)">{{
          setChecklistIcons(props.value)
        }}</v-icon>
        <v-icon v-if="props.column.GTI_NAME == 'Setup.ICON_INDEX'" size="28" :color="getAgencyIconColor(props.value)">{{
          getAgencyIconName(props.value)
        }}</v-icon>
        <div
          class="d-flex flex-column nowrap justify-center"
          style="height: 100%"
          @click.stop
          v-if="
            props.value &&
            (props.column.header.toLowerCase().includes('email') ||
              props.column.header.toLowerCase().includes('e-mail'))
          ">
          <div v-for="(emailItem, index) in createEmailLinksArrayFromString(props.value)" :key="emailItem + index">
            <span>{{ emailItem.caption }}</span>
            <a
              class="gridLink align-center"
              style="padding: 0.5rem; padding-left: 4px"
              :href="`mailto:${emailItem.email}`"
              >{{ emailItem.email }}</a
            >
          </div>
        </div>
        <div
          class="d-flex flex-column nowrap justify-center"
          style="height: 100%"
          @click.stop
          v-if="
            props.value &&
            props.column.header.toLowerCase().includes('phone') &&
            !props.column.header.toLowerCase().includes('e-mail') &&
            !props.column.header.toLowerCase().includes('email')
          ">
          <template v-if="createPhoneLinksFromString(props.value).length > 0">
            <div v-for="(phoneLinks, index) in createPhoneLinksFromString(props.value)" :key="phoneLinks + index">
              <span>{{ phoneLinks.caption }}</span>
              <a
                class="gridLink align-center"
                style="padding: 0.3rem; padding-left: 4px; width: 100%; height: 100%"
                :href="`tel:${phoneLinks.phoneNo}`"
                >{{ phoneLinks.phoneNo }}</a
              >
            </div>
          </template>

          <div v-else>{{ props.value }}</div>
        </div>
        <span v-if="props.column.field.toUpperCase() === 'ETA'" :class="getExpiredDateClass(props.row)">{{
          props.value
        }}</span>
        <span v-if="props.column.field.toUpperCase() === 'ETB'" :class="getExpiredDateClass(props.row, 'ETB')">{{
          props.value
        }}</span>
        <span v-if="props.column.field.toUpperCase() === 'ETD'" :class="getExpiredDateClass(props.row, 'ETD')">{{
          props.value
        }}</span>
        <span v-if="props.column.GTI_NAME === 'Departure'" :class="getExpiredDateClass(props.row, 'Departure')">{{
          formatArrivalDeparture(props)
        }}</span>
        <span v-if="props.column.GTI_NAME === 'Arrival'" :class="getExpiredDateClass(props.row, 'Arrival')">{{
          formatArrivalDeparture(props)
        }}</span>
      </template>
      <!-- <template slot="btns">
        <wm-button-group
          v-if="this.shouldShowNewPortcallBtn && menuItems"
          class="right mr-1"
          :buttons="menuItems"
          @click="buttonGroupClicked"
          @menuItemClick="buttonGroupClicked"
          @subMenuItemClick="buttonGroupClicked"
          :showMenu="this.$vuetify.breakpoint.xsOnly" />
      </template> -->
    </gat-grid>
    <duplicate-port-call
      v-if="dialogVisible && duplicateParams"
      :showForm="dialogVisible"
      :fromTemplate="fromTemplate"
      :duplicateParams="duplicateParams"
      :portcall="duplicatePortcall"
      @close="closeDuplicateDialog" />
    <!-- <record-presenter :value="columns" /> -->
  </div>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import { apiGet } from '@/store/api';
import { useConstantsStore } from '@/store/constantsStore';
import { useApplicationStore } from '@/store/applicationStore';
import { toggleFavorite } from '@/services/api/api-calls/toggleFavorite';
import GatButtonMenu from '@/plugins/gat-components/gatButtons/GatButtonMenu.vue';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import DuplicatePortCall from '../views/portcall/DuplicatePortCall.vue';

export default {
  name: 'WhiteBoard',
  props: ['whiteBoard', 'rowData', 'favCalls', 'loading', 'maxRows'],
  components: {
    DuplicatePortCall,
    GatButtonMenu,
  },
  setup() {
    const constantsStore = useConstantsStore();
    const applicationStore = useApplicationStore();
    return {
      constantsStore,
      applicationStore,
    };
  },
  data() {
    return {
      search: '',
      menuItems: null,
      noCellWrap: false,
      duplicating: false,
      dialogVisible: false,
      duplicatePortcall: {},
      duplicateParams: null,
    };
  },

  mounted() {
    this.dialogVisible = false;
  },

  created() {},

  watch: {},

  computed: {
    getHeight() {
      if (this.whiteBoard.id === 100) {
        // search whiteboard
        return 'calc(100vh - 201px)';
      }
      if (this.hasMultipleWhiteboards) {
        return 'calc(100vh - 120px)';
      }
      return 'calc(100vh - 110px)';
    },
    getTitle() {
      if (this.whiteBoard.id === 100) {
        // search whiteboard
        return undefined;
      }
      return this.whiteBoard.name;
    },

    columns() {
      const result = [];
      if (this.whiteBoard && this.whiteBoard.columns) {
        for (let index = 0; index < this.whiteBoard.columns.length; index++) {
          const col = this.whiteBoard.columns[index];

          // if columns should not be visible in the web module, we skip it
          if (this.columnsShouldNotBeVisible(col)) {
            // eslint-disable-next-line no-continue
            continue;
          }

          let header = {
            header: col.caption,
            field: col.caption,
            // align: 'left' | 'center' | 'right';
            sortable: true,
          };
          if (col.maxWidth) {
            header.width = col.maxWidth;
          }
          switch (
            col.dataType // GridTemplateColumnDataType = (gtdtText=0,gtdtDate=1, gtdtDateTime=2, gtdtTime=3, gtdtInteger=4, gtdtDecimal=5, gtdtBoolean=6);
          ) {
            case 1:
              header.dataType = 'date';
              if (col.format) {
                header.datetimeFormat = col.format;
              } else {
                // if no format, numbers are shown wihtout decimals as in  gs-classic
                header.datetimeFormat = 'DD MMM';
              }
              break;
            case 2:
              header.dataType = 'datetime';
              if (col.format) {
                header.datetimeFormat = col.format;
              } else {
                // if no format, numbers are shown wihtout decimals as in  gs-classic
                header.datetimeFormat = 'DD MMM HH:mm';
              }
              break;
            case 3:
              header.dataType = 'time';
              if (col.format) {
                header.datetimeFormat = col.format;
              }
              break;
            case 4:
              header.dataType = 'number';
              if (col.format) {
                header.numberFormat = col.format;
              } else {
                // if no format, numbers are shown wihtout decimals as in  gs-classic
                header.numberFormat = '0';
              }
              break;
            case 5:
              header.dataType = 'number';
              if (col.format) {
                header.numberFormat = col.format;
              } else {
                // if no format, numbers are shown with existing decimals as in  gs-classic
                header.numberFormat = '0.[00000]';
              }
              break;
            case 6:
              header.checkbox = true;
              break;
            default:
              break;
          }
          if (col.ColumnDisplayInfo) {
            header = {
              header: col.caption,
              field: col.caption,
              checklistIcon: true,
              sortable: true,
            };
          }

          header.GTI_NAME = col.GTI_NAME;

          if (header.header == 'Call no') {
            header.header = 'PortCall';
            header.field = 'PortCall';
          }
          if (col.WRAP != 1) {
            // GTI_WEB_WRAP in db
            // set nowrap to true if the column doesn't have the wrap property = 1.
            header.nowrap = true;
          }
          if (col.caption == 'PortCall') {
            header.nowrap = true;
          }

          result.push(header);
        }
      }
      return result;
    },
    rowMenu() {
      const result = [
        {
          name: 'duplicate',
          caption: 'Duplicate port call...',
          icon: 'mdi-content-duplicate',
          enabled: this.canCreatePortCall(),
        },
      ];
      return result;
    },
    favourites() {
      if (this.favCalls === null) {
        return null;
      }
      const result = [];
      // eslint-disable-next-line array-callback-return
      this.favCalls.map((item) => {
        result.push({ FAV_TYPE: 'CALL', FAV_KEY: item });
      });
      return result;
    },

    hasMultipleWhiteboards() {
      return this.whiteBoards.length > 1;
    },

    isCRM() {
      return this.applicationStore.isCRM;
    },

    whiteBoards() {
      return this.applicationStore.whiteboard.whiteBoards;
    },

    showInvalidDatesAsRed() {
      return this.applicationStore.globalSettings.SGL_BOARDS_DATE_WARNING;
    },
  },

  methods: {
    canCreatePortCall() {
      return this.applicationStore.userRight('P.NEW_AND_EDIT') && this.applicationStore.user.internal;
    },

    async showDuplicateForm(callId, fromTemplate) {
      this.fromTemplate = fromTemplate;
      this.duplicating = true;
      this.duplicateParams = fromTemplate ? {} : await apiGet('portcall/duplicate-portcall-parameters');
      const portcall = await apiGet(`portcall/${callId}`);
      this.duplicatePortcall = GatUtils.makeCopyViaJson(portcall);
      this.duplicating = false;
      this.dialogVisible = true;
    },
    closeDuplicateDialog() {
      this.duplicateParams = null;
      this.fromTemplate = null;
      this.dialogVisible = false;
    },
    buttonGroupClicked(btn) {
      const event = btn.button.eventName;
      if (event === 'newOrFromTemplateClicked') {
        if (btn.menuItem.eventName === 'addNewportCallClick') {
          this.addNewportCallClick(btn.menuItem);
        } else {
          this.addPortcallFromTemplateClicked(btn.menuItem);
        }
      } else if (event === 'addNewportCallClick') {
        this.addNewportCallClick();
      } else if (event === 'addPortcallFromTemplateClicked') {
        this.addPortcallFromTemplateClicked(btn.menuItem);
      }
    },
    addNewportCallClick() {
      this.$router.push({ name: 'NewPortCall' });
    },
    addPortcallFromTemplateClicked(menuItem) {
      if (menuItem?.template?.PTE_PORTCALL_ID) {
        this.showDuplicateForm(menuItem.template.PTE_PORTCALL_ID, true);
      }
    },
    rowMenuClicked(event) {
      if (event.menuItem.name == 'duplicate') {
        this.showDuplicateForm(event.rowItem.ID, false);
      }
    },
    formatArrivalDeparture(props) {
      // console.log('formatArrivalDeparture', props, props.value, props.row['Departure'], props.row['Arrival'])
      if (props.value && (props.column.GTI_NAME === 'Arrival' || props.column.GTI_NAME == 'Departure')) {
        if (
          props.column.GTI_NAME === 'Departure' &&
          props.row.Departure &&
          typeof props.row.Departure === 'string' &&
          props.row.Departure.includes('1899')
        ) {
          return '';
        }
        if (
          props.column.GTI_NAME === 'Arrival' &&
          props.row.Arrival &&
          typeof props.row.Arrival === 'string' &&
          props.row.Arrival.includes('1899')
        ) {
          return '';
        }

        const status = props.row['PortCall STATUS'];
        if (props.column.GTI_NAME === 'Arrival' && (status === 0 || status === 1 || status === 4)) {
          return `${props.value} est.`;
        }
        if (props.column.GTI_NAME === 'Departure' && (status === 0 || status === 1 || status === 2 || status === 4)) {
          return `${props.value} est.`;
        }
      }
      return props.value;
    },

    getExpiredDateClass(rowData, column = 'ETA') {
      if (!this.showInvalidDatesAsRed) {
        return '';
      }
      if (rowData.ATD) {
        return '';
      }
      // field is ETA or ETB
      if (column === 'ETA' || column === 'ETB') {
        if (rowData.ATA || rowData.Status === 'Berthed') {
          return '';
        }
        if (column === 'ETA' && rowData.Status === 'Anchored') {
          return '';
        }
      }
      // field is ETD
      if (column === 'ETD') {
        if (rowData.ATD) {
          return '';
        }
      }

      const status = rowData['PortCall STATUS'];
      if (column === 'Arrival' && (status === 2 || status === 3 || status === 5)) {
        return '';
      }
      if (column === 'Departure' && (status === 3 || status === 5)) {
        return '';
      }

      const dateValue = rowData[column];
      if (dateValue) {
        const dateExpired = new Date(dateValue) < new Date();
        if (dateExpired) {
          return 'red--text';
        }
      }
      return '';
    },

    columnsShouldNotBeVisible(col) {
      let result = false;
      if (col.GTI_NAME == 'PortCall.REMARKS' || col.GTI_NAME == 'PCE_REMARKS2' || col.GTI_NAME == 'PCE_REMARKS3') {
        result = true;
      }
      return result;
    },
    createEmailLink(email) {
      return `<a class="gridLink align-center" style="padding: .5rem; padding-left: 4px;" href="mailto:${email}">${email}</a>`;
    },
    createEmailLinksArrayFromString(stringValue) {
      const result = [];
      const strValue = stringValue.trim();
      if (strValue) {
        if (strValue.includes(' ')) {
          const arr = strValue.split(' ');
          let email;
          let caption;
          arr.forEach((strLine) => {
            if (strLine.includes('@')) {
              email = strLine.trim();
            } else {
              caption = caption ? `${caption} ${strLine.trim()}` : strLine.trim();
            }
            if (email && caption) {
              result.push({ email, caption });
              email = undefined;
              caption = undefined;
            }
          });
        } else {
          result.push({ email: null, caption: strValue });
        }
      }
      return result;
    },
    createPhoneLinksFromString(stringValue) {
      const result = [];
      const re = /(?:[-+() ]*\d){10,13}/gm;
      if (stringValue && stringValue.match(re)) {
        // Create an array with the valid phone numbers from regex.
        const phoneNumbers = stringValue.match(re).map((s) => s.trim());
        // Assign the string, which will be transformed lated
        let clippedString = stringValue;
        if (phoneNumbers.length > 0) {
          // If the array of valid phone numbers has values
          phoneNumbers.forEach((phoneNo) => {
            // Extract caption from the clippedString leading up to the phoneNo.
            const caption = clippedString.split(phoneNo)[0];
            // Re-assign the clipped string with replaced values.
            clippedString = clippedString.replace(caption, '').replace(phoneNo, '');
            // Add caption / phoneNo to the result;
            result.push({ caption, phoneNo });
          });
        } else {
          // If there is no string or it doesnt match, just add the whole string to a caption.
          result.push({ caption: stringValue });
        }
      }
      return result;
    },
    getAgencyIconName(classicIconNumber) {
      let result = '';
      let letter = '';
      const iconNo = parseInt(classicIconNumber, 10);
      // first 136 icons are letters from a-z in different colors
      if (iconNo <= 136) {
        if (classicIconNumber < 29) {
          letter = String.fromCharCode(iconNo + 97);
        } else if (classicIconNumber < 55) {
          letter = String.fromCharCode(iconNo + 97 - 29);
        } else if (classicIconNumber < 85) {
          letter = String.fromCharCode(iconNo + 97 - 55);
        } else if (classicIconNumber < 111) {
          letter = String.fromCharCode(iconNo + 97 - 85);
        } else if (classicIconNumber >= 111) {
          letter = String.fromCharCode(iconNo + 97 - 111);
        }

        result = `mdi-alpha-${letter}-box`;
      }

      return result;
    },

    getAgencyIconColor(classicIconNumber) {
      let result = 'black';
      if (classicIconNumber < 29) {
        result = 'orange';
      } else if (classicIconNumber <= 54) {
        result = 'blue';
      } else if (classicIconNumber <= 84) {
        result = 'red';
      } else if (classicIconNumber <= 110) {
        result = 'green';
      } else if (classicIconNumber > 110) {
        result = 'grey';
      }
      return result;
    },

    getRowClass(rowData) {
      let result = '';
      if (rowData.Status == 'Cancelled') {
        result += 'ignored';
      }
      return result;
    },

    getRowBarClass(rowData) {
      let result = '';
      if (rowData) {
        const statusName = GlobalHelperFunctions.lookupCode(
          rowData['PortCall STATUS'],
          this.constantsStore.portcallStatus
        );
        if (statusName) {
          result += ` pc-status-${statusName.toLowerCase()}-bg`;
        }
      }
      return result;
    },

    setChecklistIcons(val) {
      /* mdi-alert-circle (rød)
              mdi-checkbox-marked-circle (grønn)
              mdi-checkbox-blank-circle-outline
              mdi-circle-off-outline (grå) */
      // eslint-disable-next-line default-case
      switch (+val) {
        case 174:
          return 'mdi-checkbox-blank-circle-outline';
        case 175:
          return 'mdi-checkbox-marked-circle';
        case 176:
          return 'mdi-alert-circle';
        case 177:
          return 'mdi-circle-off-outline';
      }
      return val;
    },
    setChecklistIconColor(val) {
      // let colorGrade = " lighten-2"
      // eslint-disable-next-line default-case
      switch (+val) {
        case 174 || 177:
          return 'gray';
        case 175:
          return 'green'; // +colorGrade
        case 176:
          return 'red'; // +colorGrade
      }
      return '';
    },

    spesialColumnRendering(props) {
      const { column } = props;
      let result = false;
      if (
        column.header.toLowerCase().includes('email') ||
        column.header.toLowerCase().includes('e-mail') ||
        column.header.toLowerCase().includes('phone')
      ) {
        return true;
      }
      if (this.isCRM && column.header == 'Comments') {
        return true;
      }
      if (column.field == 'Status') {
        result = true;
      }
      if (column.checklistIcon) {
        result = true;
      }
      if (
        column.GTI_NAME == 'Setup.ICON_INDEX' ||
        column.GTI_NAME == 'case when Vessel.SHO' ||
        column.GTI_NAME == '(select TOP 1 177 as' ||
        column.GTI_NAME == 'PortCall.PORTCALL_NU' ||
        (column.GTI_NAME == 'dbo.gsf_GetCargoInfo' &&
          (this.applicationStore.userRight('CA.ACCESS') || this.applicationStore.user.internal))
      ) {
        return true;
      }
      if (this.applicationStore.user.internal) {
        if (column.GTI_NAME == 'dbo.gsf_VesselName(P') {
          // Vessel link should only be available to internal users.
          return true;
        }
        if (props.row.PAYING_PARTY_COLUMN == column.header) {
          // only display link if column header matches paying party column.
          result = true;
        }
      }
      if (column.field == 'Call no') {
        result = true;
      }
      if (
        column.field.toUpperCase() === 'ETA' ||
        column.field.toUpperCase() === 'ETB' ||
        column.field.toUpperCase() === 'ETD'
      ) {
        result = true;
      }

      if (column.GTI_NAME === 'Departure' || column.GTI_NAME === 'Arrival') {
        result = true;
      }

      return result;
    },

    gotoCall(call) {
      if (!this.duplicating) {
        this.$router.push(`/call/${call.ID}`);
      }
    },
    isFavCall(row) {
      const callNo = row.item.ID;
      return this.favCalls.indexOf(callNo) >= 0;
    },
    favClicked(row) {
      toggleFavorite({ FAV_TYPE: 'CALL', FAV_KEY: row.ID });
    },
    isOdd(row) {
      const rowNo = row.item.LINE_NO;
      return rowNo == 4;
    },

    isOddRow() {
      this.oddRowValue = !this.oddRowValue;
      if (this.oddRowValue) {
        return 'oddRow';
      }
      return '';
    },
    getStatusIcon(statusName) {
      const result = '';
      // eslint-disable-next-line default-case
      switch (statusName) {
        case 'Expected':
          return 'move_to_inbox';
        case 'Berthed':
          return 'inbox';
        case 'Anchored':
          return 'fa-anchor';
        case 'Sailed':
          return 'flight_takeoff';
      }
      return result;
    },

    getStatusColorClass(statusName) {
      // eslint-disable-next-line default-case
      switch (statusName) {
        case 'Expected':
          return 'pc-status-expected-bg white--text';
        case 'Berthed':
          return 'pc-status-berthed-bg white--text';
        case 'Anchored':
          return 'pc-status-anchored-bg white--text';
        case 'Sailed':
          return 'pc-status-sailed-bg white--text';
        case 'Archived':
          return 'pc-status-archived-bg white--text';
        case 'Cancelled':
          return 'pc-status-cancelled-bg white--text';
      }
      return '';
    },

    getStatusTextColor(statusName) {
      let result = 'white';
      if (statusName == 'Expected') {
        result = 'grey darken-1';
      }
      return result;
    },
    getStatusColorStyle(statusName) {
      const result = {
        backgroundColor: 'gray',
      };
      // eslint-disable-next-line default-case
      switch (statusName) {
        case 'Expected':
          result.backgroundColor = '#FFECB3';
          break;
        case 'Berthed':
          result.backgroundColor = '#C8E6C9';
          break;
        case 'Anchored':
          result.backgroundColor = '#BBDEFB';
          break;
        case 'Sailed':
          result.backgroundColor = '#F8BBD0';
          break;
      }

      return result;
    },

    getStatusColor(statusName) {
      const result = ' lighten-4';
      switch (statusName) {
        case 'Expected':
          return `amber${result}`;
        case 'Berthed':
          return `green${result}`;
        case 'Anchored':
          return `blue${result}`;
        case 'Sailed':
          return `red${result}`;
        default:
          return `gray${result}`;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>

<style lang="scss">
.table-spacing {
  min-height: 200px !important;

  table {
    border-collapse: separate !important;
    border-spacing: 0 4px !important;
    background: inherit;

    th,
    tr {
      border-spacing: 0 !important;
      background: inherit;
    }

    tr {
      /* border: 10px solid blue !important; */
      border-right: 0;
      border-left: 0;
    }
  }
}

.vertical-bar {
  /* min-height: 47px; */
  height: 96%;
  width: 10px;
}
</style>
