<template>
  <div>
    <v-layout wrap>
      <gat-edit
        dateTimeEdit
        label="From"
        v-model="fromDateTime"
        :size="$vuetify.breakpoint.xs ? 'xs12' : 'xs6'"></gat-edit>
      <gat-edit dateTimeEdit label="To" v-model="toDateTime" :size="$vuetify.breakpoint.xs ? 'xs12' : 'xs6'"></gat-edit>
      <gat-multi-select
        v-model="selectedAgencies"
        :items="agencies"
        label="Agencies"
        textFieldName="GROUP_NAME"
        codeFieldName="ID"
        :disabled="agencies.length === 1"
        :size="$vuetify.breakpoint.xs ? 'xs12' : 'xs6'">
      </gat-multi-select>
      <gat-edit
        v-model="showAllActivities"
        label="All activities"
        checkbox
        :size="$vuetify.breakpoint.xs ? 'xs6' : 'xs3'">
      </gat-edit>
      <gat-edit
        v-model="showActiveWhiteboardOnly"
        label="Whiteboard"
        checkbox
        :size="$vuetify.breakpoint.xs ? 'xs6' : 'xs3'">
      </gat-edit>
    </v-layout>
    <v-btn class="searchBtn primary mr-3 mb-5 adjusright" @click="doSearch()" :disabled="!selectedAgencies.length"
      >Search</v-btn
    >
    <gat-grid
      :columns="columns"
      :items="activities"
      dense
      selectable
      :loading="isLoading"
      :rowBar="true"
      :rowBarClass="getRowBarClass"
      :options="{
        checkboxIsHidden,
      }"
      viewGroupName="activityView"
      title="Activities"
      keyName="ID"
      @selectionChanged="selectionChanged($event)"
      noSelectAll>
      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == 'iconIndx'">
          <v-icon>{{ getIconName(props.value) }}</v-icon>
          <span> {{ props.value }}</span>
        </div>
        <div v-else-if="props.column.field == 'TaskDescripion'">
          <a v-if="getActivityUrl(props)" :href="getActivityUrl(props)" class="gridMainLink">{{ props.value }}</a>
          <span v-else>{{ props.value }}</span>
        </div>
        <div v-else-if="props.column.header == 'Port call'">
          <a v-if="props.row.PortcallNumber" :href="'call/' + props.row.CallID" class="gridLink"
            >{{ props.row.VesselName }} ({{ props.row.PortcallNumber }})</a
          >
        </div>
      </template>
      <template slot="btns">
        <wm-button-group class="right mr-2" :buttons="getButtons" @markDoneClick="markActivityAsDone()" />
      </template>
    </gat-grid>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut } from '@/store/api';
import GatUtils from '@/app-components/GatUtils';
import { useConstantsStore } from '@/store/constantsStore';
import { useApplicationStore } from '@/store/applicationStore';
import { getWhiteboardData } from '@/services/api/api-calls/getWhiteboardData';
import { getPendingActivities } from '@/services/api/api-calls/getPendingActivities';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'ActivityList',
  props: [],
  components: {},
  setup() {
    const constantsStore = useConstantsStore();
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
      constantsStore,
    };
  },
  data() {
    return {
      showAllActivities: false,
      showActiveWhiteboardOnly: false,
      fromDateTime: null,
      toDateTime: null,
      selectedAgencies: [],
      allActivities: [],
      isLoading: false,
      selectedActivities: [],
    };
  },

  watch: {
    agencies() {
      if (this.selectedAgencies.length === 0) {
        this.setDefaultFilterValues();
        this.doSearch();
      }
    },
    showActiveWhiteboardOnly() {
      if (!this.applicationStore.whiteboard.whiteBoardData.length) {
        const whiteboardId = this.getLastWhiteboardId();
        getWhiteboardData({
          whiteboardId,
          agencyId: this.applicationStore.agencyIdSelected,
          maxRows: 500,
        });
      }
    },
  },

  created() {
    this.setDefaultFilterValues();
    this.doSearch();
  },

  computed: {
    columns() {
      const result = [];
      result.push({ field: 'iconIndx', nowrap: true });
      result.push({ header: 'Time', field: 'TaskTime', dataType: 'datetime' });
      result.push({ header: 'Type', field: 'TaskGroup' });
      // result.push({"header":"Port call status","field":"STATUS"});
      result.push({ header: 'Port call', nowrap: true });
      result.push({ header: 'Task / Activity', field: 'TaskDescripion', nowrap: true });
      // result.push({"header":"Harbour","field":"Harbour","nowrap":true});
      result.push({ header: 'Responsible', field: 'Responsible', nowrap: true });
      result.push({ header: 'Agency', field: 'Agency', nowrap: true });
      return result;
    },
    agencies() {
      return this.applicationStore.agencySettings;
    },
    activities() {
      if (!this.showActiveWhiteboardOnly) {
        return this.allActivities;
      }

      const activities = this.allActivities.filter((activity) =>
        this.applicationStore.whiteboard.whiteBoardData.some((wbAct) => wbAct.ID === activity.CallID)
      );
      return activities;
    },
    getButtons() {
      return [
        {
          btnType: 'CUSTOM',
          icon: 'mdi-check',
          caption: 'Mark as done',
          mainAction: true,
          eventName: 'markDoneClick',
          disabled: !this.canMarkAsDone,
        },
      ];
    },
    canMarkAsDone() {
      if (!this.selectedActivities.length) {
        return false;
      }

      const baseTask = this.selectedActivities[0].iconIndx;

      if (!this.userHasRightsToEdit(baseTask)) {
        return false;
      }

      // All selected activities are of same type (transport, service, etc)
      if (this.selectedActivities.length > 1) {
        if (!this.selectedActivities.every((act) => act.iconIndx === baseTask)) {
          return false;
        }
      }

      return true;
    },
  },

  methods: {
    checkboxIsHidden(item) {
      if (item.ID) {
        return false;
      }
      return true;
    },
    setDefaultFilterValues() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(23, 59, 59, 59);
      this.fromDateTime = GlobalHelperFunctions.globalFormatDate(today, 'YYYY-MM-DDTHH:mm:ss');
      this.toDateTime = GlobalHelperFunctions.globalFormatDate(tomorrow, 'YYYY-MM-DDTHH:mm:ss');

      // Select all agencies for this user
      for (let i = 0; i < this.agencies.length; i++) {
        this.selectedAgencies.push(this.agencies[i].ID);
      }
    },
    // eslint-disable-next-line consistent-return
    async doSearch() {
      if (this.selectedAgencies.length) {
        this.isLoading = true;

        let queryString = `?FromDate=${this.fromDateTime}&ToDate=${this.toDateTime}&ShowAllActivities=${this.showAllActivities}`;
        for (let i = 0; i < this.selectedAgencies.length; i++) {
          queryString += `&Agency=${this.selectedAgencies[i]}`;
        }

        return apiGet(`activity/search${queryString}`)
          .then((response) => {
            this.allActivities = GatUtils.makeCopyViaJson(response);
          })
          .finally(() => {
            getPendingActivities();
            this.isLoading = false;
          });
      }
    },
    getRowBarClass(rowData) {
      let result = '';
      if (rowData) {
        const statusName = GlobalHelperFunctions.lookupCode(rowData.STATUS, this.constantsStore.portcallStatus);
        if (statusName) {
          result += ` pc-status-${statusName.toLowerCase()}-bg`;
        }
      }
      return result;
    },
    getStatusName(statusCode) {
      return GlobalHelperFunctions.lookupCode(statusCode, this.constantsStore.portcallStatus);
    },
    getIconName(icnValue) {
      switch (icnValue) {
        case 'Transportation':
          return 'mdi-bus';
        case 'Transport':
          return 'mdi-bus';
        case 'Service':
          return 'mdi-handshake';
        case 'Vessel':
          return 'mdi-ferry';
        case 'Checklist':
          return 'mdi-beaker-check';
        case 'Accommodation':
          return 'mdi-bed-empty';
        case 'Client':
          return 'mdi-account-multiple';
        case 'Inquiry':
          return 'mdi-human-greeting';
        default:
          return icnValue;
      }
    },
    getLastWhiteboardId() {
      let whiteboardId = 0;
      apiGet('savedinfo/web_last_whiteboard').then((data) => {
        if (data.length > 0) {
          whiteboardId = data[0].MEMOVALUE1;
        }
      });
      return whiteboardId;
    },
    markActivityAsDone() {
      const taskType = this.selectedActivities[0].iconIndx;
      const ids = [];
      this.selectedActivities.forEach((activity) => {
        ids.push(activity.ID.replace(/[^0-9]/g, ''));
      });

      let queryString = `?id=${ids[0]}`;
      for (let i = 1; i < ids.length; i++) {
        queryString += `&id=${ids[i]}`;
      }

      apiPut(`/activity/${taskType}${queryString}`).finally(() => {
        this.doSearch();
      });
    },
    selectionChanged(event) {
      this.selectedActivities = GatUtils.makeCopyViaJson(this.activities);
      this.selectedActivities = this.selectedActivities.filter((act) => event.includes(act.ID));
    },
    userHasRightsToEdit(taskType) {
      switch (taskType) {
        case 'Transportation':
          return false;
        case 'Transport':
          return false;
        case 'Service':
          return this.applicationStore.userRight('SER.EDIT');
        case 'Vessel':
          return false;
        case 'Checklist':
          return this.applicationStore.userRight('CH.NEW_AND_EDIT');
        case 'Accommodation':
          return false;
        case 'Client':
          return this.applicationStore.userRight('C.EDIT');
        case 'Inquiry':
          return this.applicationStore.userRight('C.EDIT');
        default:
          return false;
      }
    },
    getActivityUrl(props) {
      switch (props.row.iconIndx) {
        case 'Transportation':
          return this.applicationStore.userRight('CRCH.ACCESS') ? `call/${props.row.CallID}/crewchange` : false;
        case 'Transport':
          return false;
        case 'Service':
          return `/call/${props.row.CallID}/service/${props.row.ItemId}`;
        case 'Vessel':
          return false;
        case 'Checklist':
          return `/call/${props.row.CallID}/checklist/${props.row.ItemId}`;
        case 'Accommodation':
          return this.applicationStore.userRight('CRCH.ACCESS') ? `call/${props.row.CallID}/crewchange` : false;
        case 'Client':
          return `/client/${props.row.ItemId}`;
        case 'Inquiry':
          return false;
        default:
          return false;
      }
    },
  },
};
</script>

<style scoped></style>
