
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut } from '@/store/api';
import GatUtils from '@/app-components/GatUtils';
import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import GatButtonMenu from '@/plugins/gat-components/gatButtons/GatButtonMenu.vue';
import moment from 'moment';
import { useToolbarStore } from '@/store/toolbarStore';
import { useAppStatusStore } from '@/store/appStatusStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useApplicationStore } from '@/store/applicationStore';

export default Vue.extend({
  name: 'ProspectDetails',
  props: {
    callId: [String, Number],
    prospectId: [String, Number],
  },
  components: {
    GatButtonMenu,
  },
  setup() {
    const toolbarStore = useToolbarStore();
    const appStatusStore = useAppStatusStore();
    const portcallStore = usePortcallStore();
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
      toolbarStore,
      appStatusStore,
      portcallStore,
    };
  },
  data() {
    return {
      hover: false,
      isValid: null,
      isLoading: true,
      prospectItem: {
        CHO_ID: undefined,
        CHO_NAME: '',
        CHO_SUBJECT: '',
        CHO_INSTRUCTIONS: '',
        CHO_PORTCALL_ID: undefined,
        CHO_HISTORY: '',
        CHO_LAST_UPDATED: '',
        CHO_LAST_UPDATED_BY: undefined as string | undefined,
        Updated: undefined,
      },
      orgProspectItem: {},
      prospectTemplates: [],
      saving: false,
      prospectHintAndInstructions: '',
      cursorPos: -1,
      localDateTime: '',
      emailTemplates: [],
      emailTemplateGuid: undefined,
      showEmailStatusDialog: false,
    };
  },

  computed: {
    callDetails() {
      return this.portcallStore.callDetails;
    },
    applicationStatus() {
      return this.appStatusStore.getAppStatus;
    },
    emailTemplateItems() {
      const templates = [...this.emailTemplates];
      let result: any = [];
      if (Array.isArray(templates) && templates.length > 0) {
        result = templates.map((item: any) => ({
          caption: item.TITLE,
          icon: 'mdi-file-outline',
          ID: item.ID,
        }));
      }
      return result;
    },
  },

  created() {},

  activated() {
    this.setBackNavigation();
    this.prospectItem = {
      CHO_ID: undefined,
      CHO_NAME: '',
      CHO_SUBJECT: '',
      CHO_INSTRUCTIONS: '',
      CHO_PORTCALL_ID: undefined,
      CHO_HISTORY: '',
      CHO_LAST_UPDATED: '',
      CHO_LAST_UPDATED_BY: undefined,
      Updated: undefined,
    };
    this.orgProspectItem = {};
    this.saving = false;
    if (this.callId) {
      this.getProspectHintAndInstructions(this.callId);
    }
    if (this.prospectId) {
      this.getProspectDetails(this.prospectId as number);
      this.appStatusStore.setAppStatusSelectedProspectId(this.prospectId);
    }
    if (this.callDetails && this.callDetails.SETUP_ID) {
      this.getProspectTemplates(this.callDetails.SETUP_ID);
    }
  },

  deactivated() {
    this.prospectItem = {
      CHO_ID: undefined,
      CHO_NAME: '',
      CHO_SUBJECT: '',
      CHO_INSTRUCTIONS: '',
      CHO_PORTCALL_ID: undefined,
      CHO_HISTORY: '',
      CHO_LAST_UPDATED: '',
      CHO_LAST_UPDATED_BY: undefined,
      Updated: undefined,
    };
    this.orgProspectItem = {
      CHO_ID: undefined,
      CHO_NAME: '',
      CHO_SUBJECT: '',
      CHO_INSTRUCTIONS: '',
      CHO_PORTCALL_ID: undefined,
      CHO_HISTORY: '',
      CHO_LAST_UPDATED: '',
      CHO_LAST_UPDATED_BY: undefined,
      Updated: undefined,
    };
    this.prospectHintAndInstructions = '';
    this.prospectTemplates = [];
    this.saving = false;
    this.emailTemplates = [];
    this.emailTemplateGuid = undefined;
    this.showEmailStatusDialog = false;
    this.toolbarStore.setBackNavigation(undefined);
    this.appStatusStore.setAppStatusSelectedProspectId(null);
  },

  watch: {
    callId: {
      handler(val) {
        this.getProspectHintAndInstructions(val);
      },
      immediate: true,
    },
    prospectId: {
      handler(val) {
        this.getProspectDetails(val);
        this.appStatusStore.setAppStatusSelectedProspectId(val);
      },
      immediate: true,
    },
  },

  methods: {
    hasAgencySetting(value: string) {
      value.trim();
      let result = 0;
      const portcallAgency = this.callDetails.SETUP_ID; // Agency to which the current portcall belongs.
      if (portcallAgency) {
        const idx = this.applicationStore.agencySettings.findIndex((item: any) => item.ID == portcallAgency); // Find agencySettings for portcallAgency
        if (idx >= 0) {
          const agency = this.applicationStore.agencySettings[idx]; // Store all the agency settings.
          result = agency[value]; // Check the agency setting for the given argument.
        }
      }
      return result == 1;
    },
    getProspectDetails(prospectId: Number) {
      apiGet(`prospects/details/${prospectId}`).then((response: any) => {
        this.prospectItem = response;
        this.orgProspectItem = GatUtils.makeCopyViaJson(this.prospectItem);
        this.isLoading = false;
      });

      apiGet(`mail/templates/prospects/${this.callDetails.SETUP_ID}`).then((result: any) => {
        if (result) {
          this.emailTemplates = result;
        }
      });
    },
    onProspectEmailClick(item: any) {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
      if (this.isValid && this.hasAgencySetting('TAB_PROSPECTS') && this.applicationStore.user.internal) {
        this.saving = true;
        this.isLoading = true;
        const guid = uuidv4();
        this.prospectItem.CHO_LAST_UPDATED = moment().format('yyyy-MM-DD HH:mm:ss');
        this.prospectItem.CHO_LAST_UPDATED_BY = this.applicationStore.user.userNameLong?.toUpperCase();

        apiPut('/prospects/details', {
          value: this.prospectItem,
          orgValue: this.orgProspectItem,
        }).then((result) => {
          this.prospectItem = result.payload;
          this.orgProspectItem = GatUtils.makeCopyViaJson(this.prospectItem);
          this.isLoading = false;

          if (this.callId) {
            const templateId = item.ID;
            const location = 'portcall';
            const foreignKey = this.callId;
            const appStatus = JSON.stringify(this.applicationStatus);
            const param = { guid, templateId, location, foreignKey, appStatus, isEmail: 1 };
            this.portcallStore.setSendEmailProspects(param);
          }

          // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
          this.$nextTick(() => {
            this.$router.push({ name: 'prospectsList', params: { callId: this.callId as string } });
          });
          this.saving = false;
        });
      } else {
        this.saving = false;
      }
    },
    saveProspectDetails() {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
      if (this.isValid && this.hasAgencySetting('TAB_PROSPECTS') && this.applicationStore.user.internal) {
        this.saving = true;
        this.isLoading = true;
        this.prospectItem.CHO_LAST_UPDATED = moment().format('yyyy-MM-DD HH:mm:ss');
        this.prospectItem.CHO_LAST_UPDATED_BY = this.applicationStore.user.userNameLong?.toUpperCase();

        apiPut('/prospects/details', {
          value: this.prospectItem,
          orgValue: this.orgProspectItem,
        }).then((result) => {
          this.prospectItem = result.payload;
          this.orgProspectItem = GatUtils.makeCopyViaJson(this.prospectItem);
          this.isLoading = false;

          // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
          this.$nextTick(() => {
            this.$router.push({ name: 'prospectsList', params: { callId: this.callId as string } });
            this.saving = false;
          });
          this.saving = false;
        });
      } else {
        this.saving = false;
      }
    },
    setBackNavigation() {
      const result = [];
      if (this.callId) {
        result.push({ text: 'port call', href: `/call/${this.callId}` });
        result.push({ text: 'prospects', href: `/call/${this.callId}/prospects-list` });
      }
      this.toolbarStore.setBackNavigation(result);
    },
    getProspectHintAndInstructions(portCallId: String | Number) {
      apiGet(`prospects/instructions/${portCallId}`).then((response: any) => {
        this.prospectHintAndInstructions = response.payload;
      });
    },
    duplicateViaJson(obj: any) {
      let result = {};
      if (obj) {
        const jsonStr = JSON.stringify(obj);
        result = JSON.parse(jsonStr);
      }
      return result;
    },
    getProspectTemplates(agencyId: Number) {
      this.prospectTemplates = [];
      apiGet(`prospects/details/templates/${agencyId}`).then((response: any) => {
        this.prospectTemplates = response.payload;
      });
    },
    onProspectTemplateClick(e: any) {
      if (!e) return;
      let insertText = {};
      if (e.item && e.item.data) insertText = e.item.data;
      else if (e.data) insertText = e.data;
      else return;

      const textarea = (this.$refs.instructions as Vue & { $el: () => any }).$el.querySelector('textarea');
      if (!textarea) {
        this.cursorPos = 0;
        return;
      }

      if (textarea.selectionStart) this.cursorPos = textarea.selectionStart;
      else this.cursorPos = 0;

      const pos = this.cursorPos;

      const before = this.prospectItem.CHO_INSTRUCTIONS.substr(0, pos);
      const after = this.prospectItem.CHO_INSTRUCTIONS.substr(pos, this.prospectItem.CHO_INSTRUCTIONS.length);
      this.prospectItem.CHO_INSTRUCTIONS = before + insertText + after;
    },
  },
});
