<template>
  <div>
    <div v-if="menuItem.subHeader || menuItem.subHeaderDivider">
      <v-divider v-if="menuItem.subHeaderDivider" />
      <v-subheader v-if="menuItem.subHeader" class="nowrap mr-1">{{ menuItem.subHeader.toUpperCase() }}</v-subheader>
    </div>
    <div v-else>
      <v-divider v-if="menuItem.useDividerBefore" />
      <v-list-item v-if="!hasSubMenu" :disabled="menuItem.disabled" @click="$emit('click', menuItem)">
        <v-list-item-icon v-if="menuItem.icon">
          <v-icon small v-text="menuItem.icon" :disabled="menuItem.disabled"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ menuItem.menuCaption ? menuItem.menuCaption : menuItem.caption }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-menu offset-x v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-list-item :disabled="menuItem.disabled" v-bind="attrs" v-on="on">
            <v-list-item-icon>
              <v-icon small v-text="menuItem.icon" :disabled="menuItem.disabled"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                menuItem.menuCaption ? menuItem.menuCaption : menuItem.caption
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon small :disabled="menuItem.disabled">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-list dense>
          <GatButtonMenuItem
            v-for="(subMenuItem, index) in menuItem.menuItems"
            :menuItem="subMenuItem"
            :key="index"
            @click="menuItemClicked"
            @subMenuItemClick="subMenuItemClicked(menuItem, $event)"
            />
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GatButtonMenuItem',
  props: {
    menuItem: Object,
  },
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  computed: {
    hasSubMenu() {
      return this.menuItem && this.menuItem.menuItems && this.menuItem.menuItems.length > 0;
    },
  },

  methods: {
    menuItemClicked(menuItem) {      
      this.menuVisible = false;
      this.$emit('subMenuItemClick', menuItem);
    },

    subMenuItemClicked(rootMenuItem, subMenuItem) {      
      this.menuVisible = false;

      this.$emit('subMenuItemClick', subMenuItem);
    },
  },
};
</script>

<style scoped></style>
