<template>
  <div style="height:calc(100vh - 78px); !important;">
    <gat-form ref="form" v-model="formIsValid">
      <div class="d-flex">
        <div class="d-flex flex-grow-1">
          <!-- <gat-edit v-model="emailFrom" label="From" :maxChars="0" :noflex="true"  /> -->
        </div>
        <div class="ml-auto pb-2">
          <wm-button-group :buttons="wmBtnGrpButtons" @click="buttonGroupClicked" />
        </div>
      </div>
      <div class="d-flex flex-grow-1">
        <SelectEmailFrom
          v-model="fromSelect"
          :startValue="emailFromComputed"
          :agencyId="agencyId"
          class="flex-grow-1 pt-2"
          style="max-width: 400px !important"
          @item-selected="setEmailFrom($event)"
          :readonly="!canEditDraft"
          required />
      </div>
      <div class="email-inputs">
        <div class="pb-1"></div>
        <gat-flex size="xs12">
          <EmailRecipientsManual
            label="To"
            category="TO"
            v-model="emailTo"
            :readonly="!canEditDraft"
            hideDetails="auto"
            required>
            <template slot="append" v-if="!(showBCC || showCC)">
              <div v-if="!showCC" :class="showBCC ? '' : 'mr-2'" class="mt-1 grey--text bccBtn" @click="showCC = true">
                CC
              </div>
              <div v-if="!showBCC" class="mt-1 grey--text bccBtn" @click="showBCC = true">BCC</div>
            </template>
          </EmailRecipientsManual>
        </gat-flex>
        <gat-flex size="xs12" v-if="showCC">
          <EmailRecipientsManual
            label="Cc"
            category="CC"
            v-model="emailCC"
            hideDetails="auto"
            :readonly="!canEditDraft">
            <template slot="append" v-if="!showBCC">
              <div class="mt-1 grey--text bccBtn" @click="showBCC = true">BCC</div>
            </template>
          </EmailRecipientsManual>
        </gat-flex>

        <gat-flex size="xs12" v-if="showBCC">
          <EmailRecipientsManual
            label="Bcc"
            category="BCC"
            v-model="emailBCC"
            hideDetails="auto"
            :readonly="!canEditDraft">
            <template slot="append" v-if="!showCC">
              <div class="mt-1 grey--text bccBtn" @click="showCC = true">CC</div>
            </template>
          </EmailRecipientsManual>
        </gat-flex>
        <gat-flex size="xs12" class="pt-4 pb-1">
          <gat-edit
            v-model="emailSubject"
            label="Subject"
            hideDetails="auto"
            :maxChars="0"
            :noflex="true"
            :readonly="!canEditDraft"
            required />
        </gat-flex>
      </div>
      <div v-if="filesToUpload.length > 0">
        <div class="caption">Attachments</div>
        <div class="pa-1">
          <v-chip
            v-for="(file, index) in filesToUpload"
            :key="index"
            :clearable="!canEditDraft"
            small
            :close="canEditDraft"
            @click:close="unselectItem(index)"
            class="mr-1"
            ><v-icon small :color="getMdiFileIcon(file.name).color" class="mr-1">{{
              getMdiFileIcon(file.name).icon
            }}</v-icon
            >{{ file.name }}</v-chip
          >
        </div>
      </div>
      <div style="display: none">
        <v-file-input
          v-model="currentSelectedFiles"
          multiple
          id="fileUpload"
          :clearable="false"
          @change="addFile"
          label="Attachments" />
      </div>

      <div class="d-flex flex-grow-1" v-resize="setSizeOfEditor" style="height: 80%" ref="editorContainer">
        <vue2-tinymce-editor
          v-if="isActivated"
          ref="tinyMCE"
          style="width: 100%"
          :style="bodyErrorMsgEnabled && bodyErrorMsg ? 'border: 2px solid #b71c1c' : 'border: 2px solid #ffffff'"
          :key="tinyMceKey"
          @editorInit="tinyMCEReady"
          v-model="emailContent"
          :options="editorOptions" />
        <gat-edit style="display: none" :value="emailContent" label="content-validator" required :maxChars="0" />
      </div>
      <v-fade-transition>
        <div
          class="d-flex my-1 v-messages v-messages__wrapper v-messages__message grey--text text--darken-1"
          :style="bodyErrorMsgEnabled ? 'color: #b71c1c !important' : ''"
          v-if="bodyErrorMsg">
          {{ bodyErrorMsg }}
        </div>
      </v-fade-transition>

      <v-dialog v-model="showDialog" width="500">
        <v-card>
          <v-card-title class="text-h5 lighten-2"> Leave with unsaved changes? </v-card-title>

          <v-card-text> Do you really want to leave without saving? </v-card-text>

          <v-card-actions>
            <v-btn color="primary" text @click="dialogAnswer('save draft')"> save draft </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogAnswer('no')"> cancel </v-btn>
            <v-btn color="primary" text @click="dialogAnswer('ok')"> Leave </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay :value="isLoading" :absolute="true" opacity="0.3">
        <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
      </v-overlay>
      <v-dialog v-model="showDeleteDialog" persistent max-width="340">
        <v-card>
          <v-card-title class="title">Delete</v-card-title>
          <v-card-text class="text-center">Are you sure you want to delete this draft?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="showDeleteDialog = false">Cancel</v-btn>
            <v-btn text color="primary" @click="deleteDraft()">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <EmailRecipientsDialog
        v-if="portcallId && location === 'portcall'"
        v-model="showRecipientsDialog"
        :portcallId="portcallId"
        @recipients-selected="setRecipientsFromDialog"
        :to="emailTo"
        :cc="emailCC"
        :bcc="emailBCC"
        :redirect="false" />
    </gat-form>
  </div>
</template>

<script>
import EmailRecipientsManual from '@/modules/email/EmailRecipientsManual.vue';
import { Vue2TinymceEditor } from 'vue2-tinymce-editor';
// eslint-disable-next-line import/no-cycle
import DocumentHelper from '@/common/DocumentHelper';
import { useToolbarStore } from '@/store/toolbarStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import { apiGetFileAsInput, apiPost, apiDelete } from '../store/api';
import SelectEmailFrom from '../app-components/SelectEmailFrom.vue';
import EmailRecipientsDialog from '../modules/email/EmailRecipientsDialog.vue';
import { isValidEmail } from '../modules/email/emailFunctions';

export default {
  name: 'EmailEditor',
  components: {
    Vue2TinymceEditor,
    EmailRecipientsManual,
    SelectEmailFrom,
    EmailRecipientsDialog,
  },
  props: {
    emailId: [String, Number],
    clientId: [String, Number],
    vesselId: [String, Number],
    portcallId: [String, Number],
    location: String,
    // The HTML-content that is passed into the component.
    content: String,
    mailStyle: String,
    mailNo: [String, Number],
    subject: String,
    mailFrom: {},
    to: {
      type: Array,
      default: () => [],
    },
    bcc: {
      type: Array,
      default: () => [],
    },
    cc: {
      type: Array,
      default: () => [],
    },
    /** Mail attachments. (When forwarding mail.) */
    attachments: Array,
    /** Attachments that */
    attachmentsFromDocuments: Array,
    onLoadError: Array,
    /** if the mail is a draft. */
    isDraft: Boolean,
    /** if draft, this will be who created the draft. */
    createdBy: {
      type: String,
      default: undefined,
    },
    saveDraftOnLoad: Boolean,
  },
  setup() {
    const toolbarStore = useToolbarStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    return {
      portcallStore,
      toolbarStore,
      applicationStore,
    };
  },
  data() {
    return {
      formIsValid: true,
      tinyMceKey: 0,
      fromSelect: undefined,
      emailContent: '',
      emailFrom: undefined,
      emailTo: [],
      emailCC: [],
      emailBCC: [],
      emailSubject: '',
      showBCC: false,
      showCC: false,
      orgEmail: {},
      showDialog: false,
      dialogAnswer: undefined,
      currentSelectedFiles: [],
      filesToUpload: [],
      isLoading: true,
      showRecipientsDialog: false,
      /** Is used because of a bug with html-editor. Is set to true when activated hook is triggered. */
      isActivated: false,
      showDeleteDialog: false,
      createdByUser: undefined,
      saveOnLoad: false,
      editorHeight: 500,
      bodyErrorMsgEnabled: false,
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (JSON.stringify(this.email) !== JSON.stringify(this.orgEmail)) {
      this.showDialog = true;
      const dialogFunction = function f1(answer) {
        return new Promise((res) => {
          if (answer === 'ok') {
            res(next());
          }
          if (answer === 'save draft') {
            // Do some saving..
            this.onSaveDraftClick().then(() => {
              res(next());
            });
          }
          if (answer === 'no') {
            this.showDialog = false;
            res(false);
          }
          this.dialogAnswer = undefined;
        });
      };
      this.dialogAnswer = dialogFunction;
      return dialogFunction;
    }
    return next();
  },

  watch: {
    onLoadError: {
      immidiate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          window.messageBus.$emit('showMessage', {
            type: 'error',
            text: 'An error occurred',
            subText: val.toString() || '',
            icon: true,
          });
        }
      },
    },
    saveDraftOnLoad: {
      immediate: true,
      handler(val) {
        if (val) {
          this.saveOnLoad = true;
        }
      },
    },
    attachmentsFromDocuments: {
      immidiate: true,
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.att;
        }
      },
    },
    isMobile: {
      immidiate: true,
      handler() {
        this.increaseKey();
      },
    },
    createdBy: {
      immediate: true,
      handler(val) {
        this.createdByUser = val;
      },
    },
    emailId: {
      handler() {
        this.setSizeOfEditor();
      },
    },
  },
  activated() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
    this.isActivated = true;
    this.setBackNavigation();
  },

  deactivated() {
    this.isActivated = false;
    this.toolbarStore.setBackNavigation(null);
    this.resetForm();
  },

  mounted() {
    this.setSizeOfEditor();
    if (this.$refs.form) {
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    }
  },

  created() {},

  computed: {
    emailFromComputed() {
      let result;
      if (this.mailFrom) {
        // get email address from Displayname <email@domain.com>
        // eslint-disable-next-line prefer-destructuring
        result = this.mailFrom.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)[0];
      }
      return result;
    },
    userCanSend() {
      return this.applicationStore.userRight('EM.SEND');
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    agencyId() {
      let agencyId = this.applicationStore.agencyIdSelected;
      if (this.portcallId) {
        agencyId = this.portcallStore.callDetails.SETUP_ID;
      }
      return agencyId;
    },
    editorOptions() {
      let toolbar = [
        'fullscreen | formatselect | fontselect | fontsizeselect | bullist numlist | alignleft aligncenter alignright | indent outdent',
        'undo redo | bold italic underline strikethrough | forecolor backcolor | removeformat | link table image ',
      ];

      if (this.$vuetify.breakpoint.xsOnly) {
        toolbar =
          'undo redo | paragraphgroup | tocgroup | bold italic underline strikethrough | forecolor backcolor | removeformat | link table image | fullscreen';
      }
      let contentStyle = 'body {font-family:Calibri}';
      if (this.mailStyle){
        contentStyle = this.mailStyle;
      }
      return {
        menubar: false,
        height: this.editorHeight,
        width: '100%',
        plugins:
          'lists advlist autolink charmap code codesample directionality emoticons link fullscreen table image imagetools',
        toolbar,
        toolbar_location: 'top',
        font_formats:
          'Calibri=calibri,sans-serif; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; ' +
          'Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; ' +
          'Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva;' +
          'Verdana=verdana,geneva;',
        toolbar_groups: {
          paragraphgroup: {
            icon: 'paragraph',
            tooltip: 'Paragraph',
            items: 'formatselect | fontselect | fontsizeselect',
          },
          tocgroup: {
            icon: 'toc',
            tooltip: 'Paragraph format',
            items: 'bullist numlist | alignleft aligncenter alignright | indent outdent',
          },
        },
        content_style: contentStyle,
        image_title: true,
        /* enable automatic uploads of images represented by blob or data URIs */
        automatic_uploads: true,
        /*
                        URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                        images_upload_url: 'postAcceptor.php',
                        here we add custom filepicker only to Image dialog
                    */
        file_picker_types: 'image',
        /* and here's our custom image picker */
        file_picker_callback(cb) {
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          /*
                        Note: In modern browsers input[type="file"] is functional without
                        even adding it to the DOM, but that might not be the case in some older
                        or quirky browsers like IE, so you might want to add it to the DOM
                        just in case, and visually hide it. And do not forget do remove it
                        once you do not need it anymore.
                        */

          input.onchange = () => {
            const file = this.files[0];

            const reader = new FileReader();
            reader.onload = () => {
              /*
                            Note: Now we need to register the blob in TinyMCEs image blob
                            registry. In the next release this part hopefully won't be
                            necessary, as we are looking to handle it internally.
                            */
              const id = `blobid${new Date().getTime()}`;
              // eslint-disable-next-line
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              const base64 = reader.result.split(',')[1];
              const blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        readonly: this.canEditDraft ? 0 : 1,
        // any other tinymce options
      };
    },
    sendBtnDisabled() {
      if (!this.emailFrom) {
        return true;
      }
      if (!this.emailSubject) {
        return true;
      }
      if (!this.emailContent) {
        return true;
      }
      const recipients = [...this.emailTo, ...this.emailCC, ...this.emailBCC];
      if (recipients.length === 0) return true;
      for (let i = 0; i < recipients.length; i++) {
        const item = recipients[i];
        if (typeof item === 'string') {
          if (!isValidEmail(item)) {
            return true;
          }
          // eslint-disable-next-line no-prototype-builtins
        } else if (Object(item).hasOwnProperty('EMAIL')) {
          if (!isValidEmail(item.EMAIL)) {
            return true;
          }
        } else {
          return true;
        }
      }
      return false;
    },
    email() {
      return {
        emailContent: this.emailContent,
        emailFrom: this.emailFrom,
        emailTo: this.emailTo,
        emailCC: this.emailCC,
        emailBCC: this.emailBCC,
        emailSubject: this.emailSubject,
      };
    },
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
    canDeleteDraft() {
      if (this.isDraft && this.createdByUser === this.applicationStore.user.userNameLong.toUpperCase()) {
        return true;
      }
      return false;
    },
    canEditDraft() {
      return this.applicationStore.userRight('EM.EDIT');
    },
    wmBtnGrpButtons() {
      const result = [];
      if (this.userCanSend) {
        result.push({
          btnType: 'CUSTOM',
          caption: 'Send',
          icon: 'mdi-send',
          eventName: 'onSendClick',
          mainAction: true,
          disabled: false,
          showOnPhone: true,
        });
      }
      if (this.canEditDraft) {
        result.push({
          btnType: 'CUSTOM',
          caption: 'Save draft',
          icon: 'mdi-archive-arrow-down-outline',
          eventName: 'onSaveDraftClick',
        });
        result.push({
          btnType: 'CUSTOM',
          caption: 'Add attachment',
          icon: 'mdi-paperclip',
          eventName: 'onAddAttachmentClick',
        });
        if (this.portcallId && this.location === 'portcall') {
          result.push({
            btnType: 'CUSTOM',
            caption: 'Recipients',
            icon: 'mdi-account-multiple',
            eventName: 'onRecipientsClick',
          });
        }
      }
      if (this.canDeleteDraft) {
        result.push({ btnType: 'DELETE', eventName: 'onDeleteClick' });
      }
      return result;
    },
    wrapperStyle() {
      return this.$vuetify.breakpoint.mdAndDown ? 'min-height: 130px;' : 'min-height: 130px;';
    },
    getLocationId() {
      if (this.location === 'portcall') {
        return this.portcallId;
      }
      if (this.location === 'client') {
        return this.clientId;
      }
      if (this.location === 'vessel') {
        return this.vesselId;
      }
      return 0;
    },
    recipientsTo() {
      return this.createRecipients(this.emailTo);
    },
    bodyErrorMsg() {
      if (!this.emailContent) {
        return '*required';
      }
      return undefined;
    },
  },

  methods: {
    getMdiFileIcon(fileName) {
      return DocumentHelper.getMdiFileIcon(fileName);
    },
    validateForm() {
      this.bodyErrorMsgEnabled = false;
      this.$refs.form.validate();
      if (!this.emailContent) {
        this.bodyErrorMsgEnabled = true;
      }
    },
    increaseKey() {
      this.tinyMceKey++;
    },
    async tinyMCEReady() {
      /* if(!this.emailContent){ */

      if (this.content) {
        if (!this.emailContent || this.emailContent === '' || this.tinyMceKey === 0) {
          this.emailContent = this.content;
          // eslint-disable-next-line no-dupe-else-if
        } else if (!this.emailContent && this.emailContent !== this.content) {
          this.emailContent = this.content;
        }
      } else {
        this.emailContent = 'loading...';
        this.$nextTick(() => {
          this.emailContent = '';
        });
      }
      /* } */
      if (!this.emailSubject) {
        this.emailSubject = this.setEmailSubject(this.subject);
      }
      if (Array.isArray(this.emailTo) && this.emailTo.length === 0) {
        if (Array.isArray(this.to) && this.to.length > 0) {
          this.emailTo = [...this.to];
        }
      }
      if (!this.emailCc || (Array.isArray(this.emailCc) && this.emailCc.length === 0)) {
        if (Array.isArray(this.cc) && this.cc.length > 0) {
          this.showCC = true;
          this.$nextTick(() => {
            this.emailCC = [...this.cc];
          });
        }
      }
      if (!this.emailBcc || (Array.isArray(this.emailBcc) && this.emailBcc.length === 0)) {
        if (Array.isArray(this.bcc) && this.bcc.length > 0) {
          this.showBCC = true;
          this.$nextTick(() => {
            this.emailBCC = [...this.bcc];
          });
        }
      }
      if ((this.subject || this.mailNo) && !this.emailSubject) {
        this.emailSubject = this.setEmailSubject(this.subject);
      }
      if (Array.isArray(this.filesToUpload) && this.filesToUpload.length === 0) {
        if (Array.isArray(this.attachments) && this.attachments.length > 0) {
          await this.setAttachments(this.attachments);
        }
        if (Array.isArray(this.attachmentsFromDocuments) && this.attachmentsFromDocuments.length > 0) {
          this.filesToUpload = [...this.filesToUpload, ...this.attachmentsFromDocuments];
        }
      }
      this.$nextTick(() => {
        this.orgEmail = { ...this.email };
        this.isLoading = false;
        if (this.tinyMceKey === 0 && this.saveDraftOnLoad) {
          setTimeout(() => {
            this.applicationStore.setEmailListIsDirty(true);
            this.onSaveDraftClick(false, false);
          }, 1000);
        }
      });
    },
    setEmailSubject(subject) {
      if (!subject && this.applicationStore.globalSettings.SGL_EMAIL_INSERT_NO && this.mailNo) {
        const newMailNo = ` - [#${this.mailNo}]`;
        if (subject) {
          return `${newMailNo} ${this.subject}`;
        }
        return newMailNo;
      }
      return subject;
    },
    setEmailFrom(fromObj) {
      // eslint-disable-next-line no-prototype-builtins
      if (Object(fromObj).hasOwnProperty('EMAIL')) {
        const result = { EMAIL: fromObj.EMAIL };
        // eslint-disable-next-line no-prototype-builtins
        if (Object(fromObj).hasOwnProperty('NAME')) {
          result.NAME = fromObj.NAME;
        }
        this.emailFrom = result;
        if (!this.orgEmail.emailFrom) {
          this.orgEmail.emailFrom = { ...this.emailFrom };
        }
      } else {
        console.error('From e-mail not set.');
      }
    },
    setRecipientsFromDialog(recipients) {
      const { to } = recipients;
      const { cc } = recipients;
      const { bcc } = recipients;
      if (Array.isArray(cc) && cc.length > 0) {
        this.showCC = true;
        this.$nextTick(() => {
          this.emailCC = cc;
        });
      }
      if (Array.isArray(to) && to.length > 0) {
        this.emailTo = to;
      }
      if (Array.isArray(bcc) && bcc.length > 0) {
        this.showBCC = true;
        this.$nextTick(() => {
          this.emailBCC = bcc;
        });
      }
    },
    resetForm() {
      this.emailContent = '';
      this.fromSelect = undefined;
      this.emailTo = [];
      this.emailCC = [];
      this.emailBCC = [];
      this.emailSubject = '';
      this.showBCC = false;
      this.showCC = false;
      this.filesToUpload = [];
      this.tinyMceKey = 0;
    },
    unselectItem(idx) {
      this.filesToUpload.splice(idx, 1);
    },
    addFile(files) {
      const result = [...this.filesToUpload];
      files.forEach((file) => {
        result.push(file);
      });
      this.filesToUpload = result;
      this.currentSelectedFiles = [];
    },
    async setAttachments(attachments) {
      this.filesToUpload = [];
      const promises = [];
      const filesToUpload = [];
      attachments.forEach((attachment) => {
        const id = attachment.emailId || this.emailId;
        promises.push(
          apiGetFileAsInput(`mail/${id}/download/${attachment.FILE_NAME}`, attachment.FILE_NAME).then((result) => {
            const file = new File([result], attachment.FILE_NAME, {
              type: 'text/plain',
              lastModified: new Date().getTime(),
            });
            filesToUpload.push(file);
          })
        );
      });
      await Promise.allSettled(promises);
      if (filesToUpload.length > 0) {
        this.filesToUpload = filesToUpload;
      }
    },
    buttonGroupClicked(btn) {
      const event = btn.button.eventName;
      if (event === 'onSendClick') {
        this.onSendClick();
      }
      if (event === 'onSaveDraftClick') {
        this.onSaveDraftClick();
      }
      if (event === 'onAddAttachmentClick') {
        this.onAddAttachmentClick();
      }
      if (event === 'onRecipientsClick') {
        this.showRecipientsDialog = true;
      }
      if (event === 'onDeleteClick') {
        this.showDeleteDialog = true;
      }
    },
    setBackNavigation() {
      const result = [];

      if (this.location === 'portcall' && this.portcallId) {
        result.push({ text: 'port call', href: `/call/${this.portcallId}` });
        result.push({ text: 'e-mail list', href: `/call/${this.portcallId}/e-mail` });
        if (this.emailId > 0 && this.$route.name !== 'prortcallEmailNew') {
          result.push({ text: 'e-mail', href: `/call/${this.portcallId}/e-mail/${this.emailId}` });
        }
      }
      if (this.location === 'vessel' && this.vesselId) {
        result.push({ text: 'vessel', href: `/vessel/${this.vesselId}` });
        result.push({ text: 'e-mail list', href: `/vessel/${this.vesselId}/e-mail` });
        if (this.emailId > 0 && this.$route.name !== 'vesselEmailNew') {
          result.push({ text: 'e-mail', href: `/vessel/${this.vesselId}/e-mail/${this.emailId}` });
        }
      }
      if (this.location === 'client' && this.clientId) {
        result.push({ text: 'client', href: `/client/${this.clientId}` });
        result.push({ text: 'e-mail list', href: `/client/${this.clientId}/e-mail` });
        if (this.emailId > 0 && this.$route.name !== 'clientEmailNew') {
          result.push({ text: 'e-mail', href: `/client/${this.clientId}/e-mail/${this.emailId}` });
        }
      }
      this.toolbarStore.setBackNavigation(result);
    },

    createRecipients(recipientsArray) {
      const result = [];
      recipientsArray.forEach((item) => {
        if (typeof item === 'object') {
          result.push(item);
        } else if (typeof item === 'string') {
          result.push({
            NAME: item,
            EMAIL: item,
            CLIENT_NAME: item,
          });
        }
      });
      return result;
    },

    createFormData() {
      const result = new FormData();
      result.append('TO_', JSON.stringify(this.createRecipients(this.emailTo)));
      result.append('CC', JSON.stringify(this.createRecipients(this.emailCC)));
      result.append('BCC', JSON.stringify(this.createRecipients(this.emailBCC)));
      result.append('FROM_', JSON.stringify(this.emailFrom));
      result.append('SUBJECT', this.emailSubject);
      result.append('BODY', this.emailContent);
      result.append('SENT', GlobalHelperFunctions.globalFormatDate(Date.now(), 'YYYY-MM-DDTHH:mm:ss'));
      result.append('SENT_BY', this.createdByUser || this.applicationStore.user.userNameLong.toUpperCase());
      if (Array.isArray(this.filesToUpload) && this.filesToUpload.length > 0) {
        this.filesToUpload.forEach((file) => {
          result.append('ATTACHMENTS', file);
        });
      }
      return result;
    },
    goToEmailList(refreshPage) {
      const dirty = refreshPage ? Math.random() : 0;
      if (this.location === 'portcall' && this.portcallId) {
        this.$router.push({ name: 'portcallEmailList', params: { portcallId: this.callId, dirty } });
      }
      if (this.location === 'client' && this.clientId) {
        this.$router.push({ name: 'clientEmailList', params: { clientId: this.clientId, dirty } });
      }
      if (this.location === 'vessel' && this.vesselId) {
        this.$router.push({ name: 'vesselEmailList', params: { vesselId: this.vesselId, dirty } });
      }
    },
    async onSaveDraftClick(redirectToList = true, showLoading = true) {
      return new Promise((resolve) => {
        this.isLoading = showLoading;
        const payload = this.createFormData();
        apiPost(`mail/save/${this.location}/${this.getLocationId}/${this.emailId}`, payload)
          .then((result) => {
            if (result && result.payload) {
              this.orgEmail = { ...this.email };
              if (redirectToList) {
                this.$nextTick(() => {
                  this.goToEmailList(true);
                });
              }
            }
          })
          .finally(() => {
            if (this.isLoading) {
              this.isLoading = false;
            }
            resolve();
          });
      });
    },
    onSendClick() {
      this.validateForm();
      // Added nextTick to make the form-validation work before checking formIsValid.
      this.$nextTick(() => {
        if (this.formIsValid) {
          this.isLoading = true;
          const payload = this.createFormData();
          apiPost(`mail/save/${this.location}/${this.getLocationId}/${this.emailId}`, payload).then((result) => {
            if (result && result.payload) {
              apiPost(`mail/send/${result.payload}`)
                .then((sendResult) => {
                  if (sendResult && sendResult.resultCategory === 1) {
                    this.orgEmail = { ...this.email };

                    this.$nextTick(() => {
                      this.goToEmailList(true);
                    });
                  } else if (sendResult && sendResult.resultCategory === 3) {
                    const { message } = sendResult;
                    let errorMessage = '';
                    if (sendResult.payload) {
                      const json = JSON.parse(sendResult.payload);
                      if (json && json.Message) errorMessage = ` - ${json.Message}`;
                    }

                    window.messageBus.$emit('showMessage', {
                      type: 'error',
                      text: 'Error sending email',
                      subText: `${message} ${errorMessage}` || '',
                      icon: true,
                    });
                  }
                })
                .finally(() => {
                  this.isLoading = false;
                });
            } else {
              this.isLoading = false;
            }
          });
        }
      });
    },
    onAddAttachmentClick() {
      const fileUpload = document.getElementById('fileUpload');
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    setSizeOfEditor() {
      const { editorContainer } = this.$refs;
      if (editorContainer) {
        // const height = this.$refs.editorContainer.clientHeight;
        const height = document.documentElement.clientHeight * 0.8;

        if (height && height > 500 && height !== this.editorHeight) {
          this.editorHeight = height;
          this.tinyMceKey += 1;
        }
      }
    },
    async deleteDraft() {
      const id = this.emailId;
      this.isLoading = true;
      this.showDeleteDialog = false;
      const result = await apiDelete(`mail/${id}`);
      if (result.resultCategory === 1) {
        this.goToEmailList(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bccBtn {
  cursor: pointer;
}
.bccBtn:hover {
  text-decoration: underline;
}

.testCalibri {
  font-family: Calibri, sans-serif;
}
</style>

<style lang="scss"></style>
