<template>
  <div>
    <EmailRecipientsDialog
      v-if="showRecipientsDialog"
      v-model="showRecipientsDialog"
      :portcallId="portcallId"
      :location="location"
      @recipients-selected="recipientsSelected"
      :redirect="false"
      :to="emailTo"
      :cc="emailCc"
      :bcc="emailBcc" />
    <email-status
      :showForm="showEmailStatusDialog"
      :guid="emailTemplateGuid"
      @close="showEmailStatusDialog = false"
      @documentReady="showEmailStatusDialog = true"
      @goToEmailClicked="openEmailClicked($event)"
      @documentCreated="documentCreated($event)"
      :noAction="noAction" />
  </div>
</template>

<script>
import { apiGet } from '@/store/api';
import { isValidEmail } from '@/modules/email/emailFunctions';
import EmailStatus from '@/app-components/EmailStatus.vue';
import EmailRecipientsDialog from '@/modules/email/EmailRecipientsDialog.vue';
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'EmailGenerator',
  props: {
    portcallId: [String, Number],
    location: String,
    guid: {
      type: String,
      default: undefined,
    },
    // If no action is needed from the user.
    noAction: {
      type: Boolean,
      default: false,
    },
  },
  components: { EmailStatus, EmailRecipientsDialog },
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {
      showEmailStatusDialog: false,
      showRecipientsDialog: false,
      emailTemplateGuid: undefined,
      emailParams: undefined,
      emailTo: [],
      emailCc: [],
      emailBcc: [],
    };
  },

  created() {},

  watch: {
    guid: {
      immediate: true,
      handler(val) {
        this.emailTemplateGuid = val;
        this.showEmailStatusDialog = val !== undefined;
      },
    },
  },

  computed: {
    askForRecipientsOnNewEmail() {
      return this.applicationStore.SGL_MAIL_ASK_ADDRESS === 1;
    },
    isLoading: {
      get() {
        return false;
      },
      set(val) {
        this.$emit('isLoading', val);
      },
    },
    isLoadingOverlay: {
      get() {
        return false;
      },
      set(val) {
        this.$emit('isLoadingOverlay', val);
      },
    },
  },

  methods: {
    documentCreated(event) {
      this.$emit('documentCreated', event);
    },
    async createRecipientsArray(recipientsArray) {
      const error = [];
      const result = [];
      const promises = [];
      if (Array.isArray(recipientsArray) && recipientsArray.length > 0) {
        recipientsArray.forEach((item) => {
          if (isValidEmail(item.email)) {
            promises.push(
              apiGet(`portcall/emailRecipients/addManual/${item.email}`).then((res) => {
                if (Array.isArray(res) && res.length > 0) {
                  result.push(res[0]);
                } else {
                  result.push(item.email);
                }
              })
            );
          } else {
            error.push(`email ${item.email} is not a valid e-mail address`);
          }
        });
      }
      await Promise.allSettled(promises);
      return { error, result };
    },
    async openEmailClicked(emailId) {
      const email = await this.getEmail(emailId);
      if (email) {
        const promises = [];
        const toResult = await this.createRecipientsArray(email.TO);
        promises.push(toResult);
        const ccResult = await this.createRecipientsArray(email.CC);
        promises.push(ccResult);
        const bccResult = await this.createRecipientsArray(email.BCC);
        promises.push(bccResult);
        await Promise.allSettled(promises);
        const to = toResult.result;
        const cc = ccResult.result;
        const bcc = bccResult.result;
        const onLoadError = [...toResult.error, ...ccResult.error, ...bccResult.error];
        const content = email.MAIL_HTML;
        const subject = email.SUBJECT;
        const mailNo = email.MAIL_NO;
        const attachments = email.MAIL_ATTACHMENTS;
        const { location } = this.location;
        const params = {
          emailId,
          location,
          content,
          mailNo,
          subject,
          to,
          cc,
          bcc,
          attachments,
          onLoadError,
          isDraft: true,
          createdBy: email.SENT_BY,
        };
        const useGlobalSettingForAskRecipient = email.ASK_TITLE === null; // If template, use value from template
        if ((useGlobalSettingForAskRecipient && this.askForRecipientsOnNewEmail) || email.ASK_TITLE) {
          this.emailParams = params;
          this.emailTo = to;
          this.emailCc = cc;
          this.emailBcc = bcc;
          this.showRecipientsDialog = true;
        } else {
          this.goToEmail(params);
        }
      }
    },
    async getEmail(emailId) {
      let result;
      try {
        result = await apiGet(`mail/${+emailId}`);
      } catch (error) {
        this.isLoadingOverlay = false;
        this.isLoading = false;
        console.error(error);
      }
      return result;
    },

    goToEmail(params) {
      const { portcallId } = this;
      const { clientId } = this;
      const { vesselId } = this;
      if (this.location === 'client' && this.clientId) {
        this.$router.push({
          name: 'clientEmailNew',
          params: {
            ...params,
            clientId,
          },
        });
      }
      if (
        (this.location === 'portcall' ||
          this.location === 'expense' ||
          this.location === 'service' ||
          this.location === 'sof' ||
          this.location === 'visitor' ||
          this.location === 'crewchange crew' ||
          this.location === 'crewchange task') &&
        this.portcallId
      ) {
        this.$router.push({
          name: 'prortcallEmailNew',
          params: {
            ...params,
            portcallId,
          },
        });
      }
      if (this.location === 'vessel' && this.vesselId) {
        this.$router.push({
          name: 'vesselEmailNew',
          params: {
            ...params,
            vesselId,
          },
        });
      }
    },

    recipientsSelected({ to, cc, bcc }) {
      if (this.emailParams) {
        const params = {
          ...this.emailParams,
          to,
          cc,
          bcc,
          saveDraftOnLoad: true,
        };
        this.params = undefined;
        this.emailTo = [];
        this.emailCc = [];
        this.emailBCc = [];
        this.goToEmail(params);
      }
    },
  },
};
</script>

<style scoped></style>
