<template>
  <div>
    <gat-grid
      :items="people"
      :columns="columns"
      title="People"
      reloadBtn
      @reload-clicked="fetchData()"
      :rowclass="this.getRowClass"
      :loading="isLoading"
      viewGroupName="viewPeopleList"
      :height="windowHeight"
      :fixedheader="true"
      :dense="denseMode"
      :selectable="isSelectable"
      keyName="ID"
      :selectedKeys="selectedItemKeys"
      @selectionChanged="showLinkedTasks($event)"
      @row-clicked="goToCrewChangePerson($event)">
      <template slot="btns">
        <wm-button-group
          class="right mr-1"
          :buttons="wmButtonGroup"
          @createEmailClick="(val) => createEmailClick(val)" />
      </template>
      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == 'STATUS'">
          <v-tooltip bottom v-if="props.value == 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="blue" v-bind="attrs" v-on="on">mdi-timer-sand-empty</v-icon>
            </template>
            <span>In progress</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="props.value == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="green" v-bind="attrs" v-on="on">mdi-check-circle</v-icon>
            </template>
            <span>Completed</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="props.value == 2">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
            </template>
            <span>Cancelled</span>
          </v-tooltip>
          <v-tooltip bottom v-if="props.row.LOCK_FLAG && props.row.LOCK_FLAG === 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-1" color="grey--darken-2" v-bind="attrs" v-on="on" small>mdi-lock</v-icon>
            </template>
            <span>Locked</span>
          </v-tooltip>
        </div>
        <div v-else-if="props.column.field == 'hasRemark'">
          <v-tooltip bottom v-if="props.value == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-message-bulleted</v-icon>
            </template>
            <span>Remark</span>
          </v-tooltip>
        </div>
        <div v-else-if="props.column.field == 'Attachment'">
          <v-tooltip bottom v-if="props.value == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-paperclip</v-icon>
            </template>
            <span>Attachments</span>
          </v-tooltip>
        </div>
        <div v-else-if="props.column.field == 'PERSON_TYPE'">
          <v-tooltip bottom v-if="props.value == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-account-cash</v-icon>
            </template>
            <span>Passenger</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="props.value == 2">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-account</v-icon>
            </template>
            <span>Other</span>
          </v-tooltip>
        </div>
        <div v-else-if="props.column.field == 'OPERATION'">
          <v-tooltip bottom v-if="props.value == 0">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="green" v-bind="attrs" v-on="on">mdi-airplane-landing</v-icon>
            </template>
            <span>Embarking</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="props.value == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="red" v-bind="attrs" v-on="on">mdi-airplane-takeoff</v-icon>
            </template>
            <span>Disembarking</span>
          </v-tooltip>
          <v-tooltip bottom v-else-if="props.value == 2">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="blue" v-bind="attrs" v-on="on">mdi-circle-double</v-icon>
            </template>
            <span>Other</span>
          </v-tooltip>
        </div>
        <div v-else-if="props.column.field == 'MOBILEPHONE'">
          <a :href="'tel:' + props.value" class="gridLink">{{ props.value }}</a>
        </div>
      </template>
    </gat-grid>
    <email-generator :portcallId="callId" location="crewchange crew" :guid="emailTemplateGuid" />
  </div>
</template>

<script>
import { apiPut, apiGet } from '@/store/api';
import GatUtils from '@/app-components/GatUtils';
import EmailGenerator from '@/app-components/EmailGenerator.vue';
import { useAppStatusStore } from '@/store/appStatusStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
import { getCrewChangePeople } from '@/services/api/api-calls/getCrewChangePeople';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'CrewChangePeopleList',
  props: {
    callId: [Number, String],
    fullsize: [Boolean],
  },
  components: { EmailGenerator },
  setup() {
    const appStatusStore = useAppStatusStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      portcallStore,
      applicationStore,
      appStatusStore,
    };
  },
  data() {
    return {
      isLoading: false,
      selectedItemKeys: [],
      peopleByTask: [],
      taskListHeight: null,
      personFilter: [],
      taskFilter: [],
      operationFilter: [],
      statusFilter: [],
      denseMode: false,
      upcomingPeople: {},
      emailTemplates: [],
      emailTemplateGuid: undefined,
    };
  },

  activated() {
    this.fetchData();
  },

  mounted() {
    window.messageBus.$on('peopleFilteredByTask', (peopleArray) => {
      this.selectedItemKeys = [];
      this.peopleByTask = GatUtils.makeCopyViaJson(peopleArray);
    });

    window.messageBus.$on('crewChangeTaskListHeight', (taskListHeight) => {
      this.taskListHeight = taskListHeight;
    });

    window.messageBus.$on('crewChangeFilterOperation', (operationChoice) => {
      this.operationFilter = operationChoice;
    });

    window.messageBus.$on('crewChangeFilterStatus', (statusChoice) => {
      this.statusFilter = statusChoice;
    });

    window.messageBus.$on('crewChangeFilterPerson', (personChoice) => {
      this.personFilter = personChoice;
    });

    window.messageBus.$on('crewChangeFilterTask', (taskChoice) => {
      this.taskFilter = taskChoice;
    });

    window.messageBus.$on('crewChangeFilterDense', (denseChoise) => {
      this.denseMode = denseChoise;
    });
  },

  created() {
    // this.fetchData();
  },

  watch: {
    // callId: {
    //   handler() {
    //     this.fetchData();
    //   },
    //   immediate: true,
    // },
    externalClientId: {
      handler() {
        this.fetchData();
      },
    },
  },

  computed: {
    externalClientId() {
      return this.applicationStore.user.externalClientId;
    },
    columns() {
      const result = [];
      result.push({ header: 'Op.', field: 'OPERATION' });
      result.push({ header: 'Ty.', field: 'PERSON_TYPE' });
      result.push({ header: 'Status', field: 'STATUS' });
      result.push({ header: 'Seq', field: 'SEQUENCE' });
      if (this.applicationStore.user.internal) {
        result.push({ header: 'Rem', field: 'hasRemark' });
        result.push({ header: 'Doc.', field: 'Attachment' });
        result.push({ header: 'Lastname', field: 'LASTNAME' });
        result.push({ header: 'Firstname', field: 'FIRSTNAME' });
      }
      result.push({ header: 'Fullname', field: 'FULLNAME', nowrap: true });
      if (this.applicationStore.user.internal) {
        result.push({ header: 'Transp.', field: 'Transp' });
        result.push({ header: 'Accom.', field: 'Accom' });
      }
      result.push({ header: 'Mobile', field: 'MOBILEPHONE', nowrap: true });
      result.push({ header: 'Gender', field: 'GENDER' });
      result.push({ header: 'Nat.', field: 'NATIONALITY' });
      result.push({ header: 'Rank', field: 'RANK', nowrap: true });
      if (this.applicationStore.user.internal || this.applicationStore.userRight('CRCH.SHOW_PASSPORT_DETAILS')) {
        result.push({ header: 'Pass number', field: 'PASSPORT_NUMBER' });
        result.push({ header: 'Pass issued date', field: 'PASSPORT_ISSUED_DATE', dataType: 'date' });
        result.push({ header: 'Pass valid to', field: 'PASSPORT_VALID_TO', dataType: 'date' });
      }
      if (this.applicationStore.user.internal) {
        result.push({ header: 'Seaman number', field: 'SEAMANBOOK_NUMBER' });
        result.push({ header: 'Seaman issued date', field: 'SEAMANBOOK_ISSUED_DATE', dataType: 'date' });
        result.push({ header: 'Seaman valid to', field: 'SEAMANBOOK_VALID_TO', dataType: 'date' });

        // Get all the dynafields from the actuall people in the grid.
        const peopleDynafields = [];
        const personer = this.portcallStore.crewChange.people.persons;
        if (personer) {
          personer.forEach((person) => {
            Object.keys(person).forEach((vars) => {
              if (vars.startsWith('dyn_')) peopleDynafields.push(vars);
            });
          });

          // dynafields columns
          for (let index = 0; index < this.helperStore.dynafields.length; index++) {
            const dynafield = this.helperStore.dynafields[index];

            if (dynafield.COMPONENT_NAME == 'pDynaFields.CrewChangeDynaPerson') {
              // Should filter on the ones that are actually relevant
              if (peopleDynafields.includes(`dyn_${dynafield.ID}`)) {
                const col = {
                  header: dynafield.CAPTION,
                  field: `dyn_${dynafield.ID}`,
                  dataType: this.getDataTypeByFieldTypeId(dynafield.FIELD_TYPE),
                };
                result.push(col);
              }
            }
          }
        }
      }
      // expense fields
      if (this.applicationStore.userRight('CRCH.SHOW_EXPENSES')) {
        let templates;
        if ((this.isTransportPage || this.isAccommodationPage) && this.upcomingPeople?.usedExpenseTemplates) {
          templates = this.upcomingPeople.usedExpenseTemplates;
        } else if (this.portcallStore.crewChange.people?.usedExpenseTemplates) {
          templates = this.portcallStore.crewChange.people.usedExpenseTemplates;
        }
        // console.log('TEMPLATES::::::', templates);
        // console.log('ISTRANSPORT::::::', this.isTransportPage);
        // console.log('ISACCOMODATION::::::', this.isAccommodationPage);
        // console.log('upcomingTasks::::::', this.upcomingTasks);
        // console.log('usedExpenseTemplates::::::', this.portcallStore.crewChange.tasks.usedExpenseTemplates);
        if (templates) {
          for (let i = 0; i < templates.length; i++) {
            result.push({
              header: templates[i].CAPTION,
              field: `exp_${templates[i].ID}`,
              footerFunction: { fn: 'sum' },
              dataType: 'number',
            });
          }
        }
        result.push({ header: 'Exp. total', field: 'ExpTotal', footerFunction: { fn: 'sum' }, dataType: 'number' });
        result.push({ header: 'Debtor', field: 'Debtor', nowrap: true });
      }
      if (this.applicationStore.user.internal) {
        result.push({ header: 'Updated', field: 'LAST_UPDATED', dataType: 'datetime' });
        result.push({ header: 'Updated by', field: 'LAST_UPDATED_BY' });
        result.push({ header: 'Exported', field: 'LAST_EXPORTED', dataType: 'datetime' });
        result.push({ header: 'Exported by', field: 'LAST_EXPORTED_BY' });
        result.push({ header: 'Remark', field: 'REMARKS' });
      }
      return result;
    },
    people() {
      if (
        (!this.userRightsPeople || this.isTransportPage || this.isAccommodationPage) &&
        this.peopleByTask.length === 0
      ) {
        return [];
      }

      let people = [];
      let peopleFiltered = null;

      if (this.isTransportPage || this.isAccommodationPage) {
        if (this.upcomingPeople.persons && this.upcomingPeople.persons.length > 0) {
          people = GatUtils.makeCopyViaJson(this.upcomingPeople.persons);
        }
      } else {
        people = this.portcallStore.crewChange.people.persons;
      }

      if (people) {
        peopleFiltered = GatUtils.makeCopyViaJson(people);
        peopleFiltered = this.filterItems(peopleFiltered, this.operationFilter, 'OPERATION');
        peopleFiltered = this.filterItems(peopleFiltered, this.personFilter, 'PERSON_TYPE');
        peopleFiltered = this.filterItems(peopleFiltered, this.statusFilter, 'STATUS');

        if (this.taskFilter[0] && this.taskFilter[1]) {
          peopleFiltered = peopleFiltered.filter((person) => !(person.Transp && person.Accom));
        } else if (this.taskFilter[0]) {
          peopleFiltered = peopleFiltered.filter((person) => !person.Transp);
        } else if (this.taskFilter[1]) {
          peopleFiltered = peopleFiltered.filter((person) => !person.Accom);
        }

        if (this.peopleByTask.length > 0) {
          peopleFiltered = peopleFiltered.filter((person) => this.peopleByTask.includes(person.ID));
        }
      }
      return peopleFiltered || people;
    },
    windowHeight() {
      let height = 77;
      const defaultHeight = 45;
      if (this.fullsize) {
        return 'calc(100vh - 170px)';
      }
      if (this.taskListHeight != null) {
        height -= this.taskListHeight;
      } else {
        height = defaultHeight;
      }

      return `${height}vh`;
    },
    agencyId() {
      return this.portcallStore.callDetails.SETUP_ID;
    },
    wmButtonGroup() {
      const result = [];
      if ((!this.applicationStore.user.internal && this.userCanGenerateDoc) || this.applicationStore.user.internal) {
        result.push({
          btnType: 'CREATE_DOC',
          docType: 23,
          agencyId: this.agencyId,
          location: 'portcall',
          foreignKey: this.callId,
          applicationStatus: this.applicationStatus,
          disabled: !this.userCanGenerateDoc, // this.selectedItemKeys.length === 0 ||
        });
        if (
          this.applicationStore.globalSettings.SGL_WM_EMAIL_PROVIDER !== 0 &&
          this.applicationStore.userRight('EM.EDIT')
        ) {
          result.push({
            btnType: 'CUSTOM',
            caption: 'Create email',
            icon: 'mdi-email',
            menuItems: this.emailTemplateItems,
            disabled: this.emailTemplateItems.length === 0,
          });
        }
      }
      return result;
    },
    userCanGenerateDoc() {
      const isInternal = this.applicationStore.user.internal;
      if (isInternal) {
        return true;
      }
      const externalRights = this.applicationStore.userRight('CRCH.GENERATE_DOCUMENT');
      return externalRights;
    },
    isSelectable() {
      if (this.peopleByTask.length > 0) {
        return false;
      }
      return true;
    },
    applicationStatus() {
      return this.appStatusStore.getAppStatus;
      /* let result = {};
              result.PortCall = {};
              result.PortCall.Id = this.callId;
              result.PortCall.CcmPersonIds = this.selectedItemKeys
              return result; */
    },
    userRightsTasks() {
      return (
        this.applicationStore.user.internal ||
        this.applicationStore.userRight('CRCH.ACCESS') ||
        this.applicationStore.userRight('CRCH.ACCESS_OWN_TASK')
      );
    },
    userRightsPeople() {
      return this.applicationStore.user.internal || this.applicationStore.userRight('CRCH.ACCESS_ALL_PERSONS');
    },
    clientId() {
      if (
        this.applicationStore.user.internal ||
        this.applicationStore.userRight('CRCH.ACCESS_ALL_PERSONS') ||
        this.applicationStore.userRight('CRCH.ACCESS')
      ) {
        return 0;
      }
      return this.applicationStore.user.externalClientId ? this.applicationStore.user.externalClientId : null;
    },
    isTransportPage() {
      return this.$route.path === '/transports';
    },
    isAccommodationPage() {
      return this.$route.path === '/accommodations';
    },
    emailTemplateItems() {
      const templates = [...this.emailTemplates];
      let result = [];
      if (Array.isArray(templates) && templates.length > 0) {
        result = templates.map((item) => ({
          caption: item.TITLE,
          eventName: 'createEmailClick',
          icon: 'mdi-file-outline',
          ID: item.ID,
        }));
      }
      return result;
    },
  },

  methods: {
    goToCrewChangePerson(rowItem) {
      this.$router.push({ name: 'crewChangePerson', params: { personID: rowItem.ID } });
    },
    createEmailClick(item) {
      if (this.callId) {
        const guid = GlobalHelperFunctions.GUID();
        const templateId = item.ID;
        const location = 'portcall';
        const foreignKey = this.callId;
        const appStatus = JSON.stringify(this.applicationStatus);
        const param = { guid, templateId, location, foreignKey, appStatus, isEmail: 1 };
        apiPut('document/generate/', param).then((result) => {
          if (result.resultCategory === 0 && result.resultType === 0) {
            this.emailTemplateGuid = guid;
          }
        });
      }
    },
    retrievePeople(callId) {
      this.isLoading = true;
      getCrewChangePeople({ callId, clientId: this.clientId })
        .then(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    filterItems(people, filterArray, property) {
      const numOfOperationsToggled = filterArray.filter((op) => op === true).length;
      if (numOfOperationsToggled > 0 && numOfOperationsToggled != filterArray.length) {
        const newFilter = filterArray.reduce((result, element, index) => {
          if (element) {
            result.push(index);
          }
          return result;
        }, []);

        // eslint-disable-next-line no-param-reassign
        people = people.filter((person) => {
          if (newFilter.includes(person[property])) {
            return true;
          }
          return false;
        });
      }
      return people;
    },

    getRowClass(rowItem) {
      let result = '';
      if (rowItem.STATUS === 2) {
        result += 'ignored';
      }
      return result;
    },
    showLinkedTasks(peopleIds) {
      if (this.userRightsTasks) {
        this.selectedItemKeys = peopleIds;
        this.appStatusStore.setAppStatusCcmPersonIds(peopleIds);
        this.isLoading = true;
        let taskArray = [];
        if (peopleIds.length == 0) {
          window.messageBus.$emit('tasksFilteredByPeople', taskArray);
          this.isLoading = false;
        } else {
          const selectedPeopleItems = this.getPeopleItemBySelectedKeys();
          taskArray = this.getTasksFromPeople(selectedPeopleItems);
          if (taskArray.length > 0) {
            window.messageBus.$emit('tasksFilteredByPeople', taskArray);
          } else {
            // People selected but no assigned tasks > Clear task table
            window.messageBus.$emit('tasksFilteredByPeople', ['x']);
          }
          this.isLoading = false;
        }
      }
    },
    fetchData() {
      if (this.callId != 0) {
        if (this.portcallStore.crewChange.people) {
          this.retrievePeople(this.callId);
        }
        apiGet(`mail/templates/crewchange crew/${this.agencyId}`).then((result) => {
          if (result) {
            this.emailTemplates = result;
          }
        });
      } else {
        this.retrieveUpcomingTasksPeople();
      }
    },
    getPeopleItemBySelectedKeys() {
      const people = [];
      for (let i = 0; i < this.selectedItemKeys.length; i++) {
        people.push(
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          this.people.filter((obj) => obj.ID === this.selectedItemKeys[i])
        );
      }
      return people;
    },
    getTasksFromPeople(peopleArray) {
      const tasks = [];
      for (let i = 0; i < peopleArray.length; i++) {
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in peopleArray[i][0]) {
          if (prop.indexOf('Task_') === 0) {
            tasks.push(peopleArray[i][0][prop]);
          }
        }
      }
      const uniqueTasks = [...new Set(tasks)];
      return uniqueTasks;
    },
    retrieveUpcomingTasksPeople() {
      if (this.applicationStore.user.externalClientId != undefined) {
        this.isLoading = true;
        apiGet(`crewchange/upcomingTasksPeople/${this.applicationStore.user.externalClientId}`).then((result) => {
          this.upcomingPeople = GatUtils.makeCopyViaJson(result);
          this.isLoading = false;
        });
      }
    },
    // eslint-disable-next-line consistent-return
    getDataTypeByFieldTypeId(fieldType) {
      switch (fieldType) {
        case 3:
          return 'date';
        case 12:
          return 'datetime';
        default:
      }
    },
  },
};
</script>

<style scoped></style>
