<template>
  <gat-flex v-if="!noflex" :size="getSize()" :hide="hide">
    <gat-field-spacer :size="size" :fieldType="fieldType">
      <v-textarea
        v-if="rows"
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        ref="field"
        v-model="textValue"
        :label="getLabel"
        :messages="getMessages"
        @input="updateValue()"
        :rows="rows"
        :autofocus="autofocus"
        auto-grow
        :disabled="disabled"
        :readonly="isReadonly"
        :hint="hint"
        :error-messages="errorMessages"
        :markOptionalInputs="markOptionalInputs"
        :counter="getCounter"
        :outlined="isOutlined"
        :filled="isFilled"
        :dense="gatComponentsStore.input_dense"
        placeholder=" "
        :rules="rules"
        :hide-details="hideDetails"
        @blur="doBlur"
        @OnErrorState="HandleErrorState">
      </v-textarea>

      <gat-num-edit
        v-else-if="isNumberType"
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        ref="field"
        :label="label"
        v-model="textValue"
        :disabled="disabled"
        :readonly="isReadonly"
        :hint="hint"
        :decimals="decimals"
        :inlineEdit="inlineEdit"
        :required="required"
        :error-messages="errorMessages"
        :markOptionalInputs="markOptionalInputs"
        :validatorRules="validatorRules"
        :suffix="suffix"
        :noThousandSeperator="noThousandSeperator"
        :hide-details="hideDetails"
        @input="updateValue()"
        @blur="doBlur"
        @OnErrorState="HandleErrorState">
        <span v-if="appendText" class="mt-1" slot="append">{{ appendText }}</span>
        <template slot="append">
          <slot name="append"></slot>
        </template>
      </gat-num-edit>

      <v-checkbox
        v-else-if="checkbox"
        :class="hideDetails || typeof hideDetails === 'string' ? 'pt-0' : ''"
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        ref="field"
        :label="label"
        v-model="chkValue"
        :disabled="disabled"
        :readonly="isReadonly"
        :hint="hint"
        :hide-details="hideDetails"
        :false-value="uncheckedValue"
        :true-value="checkedValue"
        :error-messages="errorMessages"
        @blur="doBlur"
        :dense="dense"
        @change="$emit('change', $event)">
      </v-checkbox>

      <v-switch
        v-else-if="toggle"
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        ref="field"
        :label="label"
        v-model="chkValue"
        :disabled="disabled"
        :readonly="isReadonly"
        :hint="hint"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        @blur="doBlur"></v-switch>

      <gat-date-edit
        v-else-if="dateEdit"
        :futureDate="futureDate"
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        :label="label"
        v-model="textValue"
        :autofocus="autofocus"
        @input="updateValue()"
        :disabled="disabled"
        :readonly="isReadonly"
        :hint="hint"
        :append-icon="appendIcon"
        :inlineEdit="inlineEdit"
        :validatorRules="rules"
        :required="required"
        :error-messages="errorMessages"
        :markOptionalInputs="markOptionalInputs"
        :clearable="clearable"
        :showWeekDay="showWeekDay"
        :hide-details="hideDetails"
        @blur="doBlur"
        @OnErrorState="HandleErrorState"></gat-date-edit>

      <gat-time-edit
        v-else-if="timeEdit"
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        :label="label"
        v-model="textValue"
        :autofocus="autofocus"
        @input="updateValue()"
        :disabled="disabled"
        :readonly="isReadonly"
        :hint="hint"
        :append-icon="appendIcon"
        :inlineEdit="inlineEdit"
        :required="required"
        :error-messages="errorMessages"
        :markOptionalInputs="markOptionalInputs"
        :clearable="clearable"
        :validatorRules="rules"
        :hide-details="hideDetails"
        @blur="doBlur"
        @OnErrorState="HandleErrorState">
        <template slot="append">
          <slot name="append" />
        </template>
      </gat-time-edit>

      <gat-date-time-edit
        v-else-if="dateTimeEdit"
        :futureDate="futureDate"
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        :label="getLabel"
        :messages="getMessages"
        :label2="label2"
        v-model="textValue"
        :autofocus="autofocus"
        @input="updateValue()"
        :disabled="disabled"
        :readonly="isReadonly"
        :hint="hint"
        :inlineEdit="inlineEdit"
        :append-icon="appendIcon"
        :required="required"
        :error-messages="errorMessages"
        :markOptionalInputs="markOptionalInputs"
        :clearable="clearable"
        :showWeekDay="showWeekDay"
        :validatorRules="rules"
        :hide-details="hideDetails"
        @blur="doBlur"
        @OnErrorState="HandleErrorState"></gat-date-time-edit>
      <div v-else class="d-flex">
        <v-text-field
          ref="field"
          v-model="textValue"
          :autocomplete="autocomplete"
          :data-lpignore="dataLpignore"
          :data-form-type="dataFormType"
          data-lpignore="true"
          data-form-type="other"
          :label="getLabel"
          :messages="getMessages"
          @input="updateValue()"
          :autofocus="autofocus"
          :suffix="suffix"
          :error-messages="errorMessages"
          :error="error"
          :disabled="disabled"
          :outlined="isOutlined"
          :filled="isFilled"
          :dense="gatComponentsStore.input_dense"
          placeholder=" "
          :clearable="clearable"
          :readonly="isReadonly"
          :hint="hint"
          :rules="rules"
          :append-icon="appendIcon"
          :markOptionalInputs="markOptionalInputs"
          :counter="getCounter"
          :hide-details="hideDetails"
          @keyup.enter.native="$emit('onenterkey')"
          @blur="doBlur"
          @OnErrorState="HandleErrorState">
          <span v-if="appendText" class="mt-1" slot="append">{{ appendText }}</span>
          <template slot="append">
            <slot name="append"></slot>
          </template>
        </v-text-field>
        <v-btn
          v-if="tel || mailto"
          class="ma-0 pa-0 ml-2 elevation-0 gat-edit-btn"
          color="primary"
          :disabled="!textValue"
          :href="tel ? `tel:${textValue}` : `mailto:${textValue}`">
          <v-icon v-if="tel">mdi-phone</v-icon>
          <v-icon v-else>mdi-mail</v-icon>
        </v-btn>
      </div>
    </gat-field-spacer>
  </gat-flex>
  <span v-else>
    <v-textarea
      v-if="rows"
      :autocomplete="autocomplete"
      :data-lpignore="dataLpignore"
      :data-form-type="dataFormType"
      ref="field"
      v-model="textValue"
      :label="getLabel"
      :messages="getMessages"
      @input="updateValue()"
      :rows="rows"
      :autofocus="autofocus"
      auto-grow
      :disabled="disabled"
      :readonly="isReadonly"
      :hint="hint"
      :outlined="isOutlined"
      :filled="isFilled"
      :dense="gatComponentsStore.input_dense"
      placeholder=" "
      :rules="rules"
      :error-messages="errorMessages"
      :markOptionalInputs="markOptionalInputs"
      :counter="getCounter"
      :hide-details="hideDetails"
      @blur="doBlur">
    </v-textarea>

    <v-checkbox
      v-else-if="checkbox"
      :class="hideDetails || typeof hideDetails === 'string' ? 'pt-0' : ''"
      :autocomplete="autocomplete"
      :data-lpignore="dataLpignore"
      :data-form-type="dataFormType"
      ref="field"
      :label="label"
      v-model="chkValue"
      :disabled="disabled"
      :readonly="isReadonly"
      :hint="hint"
      :hide-details="hideDetails"
      :false-value="uncheckedValue"
      :true-value="checkedValue"
      :error-messages="errorMessages"
      @blur="doBlur"
      :dense="dense"
      @change="$emit('change', $event)"></v-checkbox>

    <gat-num-edit
      v-else-if="isNumberType"
      :autocomplete="autocomplete"
      :data-lpignore="dataLpignore"
      :data-form-type="dataFormType"
      ref="field"
      :label="label"
      v-model="textValue"
      :disabled="disabled"
      :readonly="isReadonly"
      :hint="hint"
      :decimals="decimals"
      :inlineEdit="inlineEdit"
      :suffix="suffix"
      :required="required"
      :error-messages="errorMessages"
      :markOptionalInputs="markOptionalInputs"
      :validatorRules="validatorRules"
      :hide-details="hideDetails"
      @input="updateValue()"
      @blur="doBlur"
      @OnErrorState="HandleErrorState">
      <span v-if="appendText" class="mt-1" slot="append">{{ appendText }}</span>
    </gat-num-edit>

    <v-switch
      v-else-if="toggle"
      :autocomplete="autocomplete"
      :data-lpignore="dataLpignore"
      :data-form-type="dataFormType"
      ref="field"
      :label="label"
      v-model="chkValue"
      :disabled="disabled"
      :readonly="isReadonly"
      :hint="hint"
      :error-messages="errorMessages"
      :hide-details="hideDetails"
      @blur="doBlur"></v-switch>

    <gat-date-edit
      v-else-if="dateEdit"
      :futureDate="futureDate"
      :autocomplete="autocomplete"
      :data-lpignore="dataLpignore"
      :data-form-type="dataFormType"
      :label="label"
      v-model="textValue"
      :autofocus="autofocus"
      @input="updateValue()"
      :disabled="disabled"
      :readonly="isReadonly"
      :hint="hint"
      :inlineEdit="inlineEdit"
      :append-icon="appendIcon"
      :required="required"
      :error-messages="errorMessages"
      :markOptionalInputs="markOptionalInputs"
      :clearable="clearable"
      :showWeekDay="showWeekDay"
      :validatorRules="rules"
      :hide-details="hideDetails"
      @OnErrorState="HandleErrorState"
      @blur="doBlur"></gat-date-edit>

    <gat-time-edit
      v-else-if="timeEdit"
      :autocomplete="autocomplete"
      :data-lpignore="dataLpignore"
      :data-form-type="dataFormType"
      :label="label"
      v-model="textValue"
      :autofocus="autofocus"
      @input="updateValue()"
      :disabled="disabled"
      :readonly="isReadonly"
      :hint="hint"
      :append-icon="appendIcon"
      :inlineEdit="inlineEdit"
      :required="required"
      :error-messages="errorMessages"
      :markOptionalInputs="markOptionalInputs"
      :clearable="clearable"
      :validatorRules="rules"
      :hide-details="hideDetails"
      @blur="doBlur"
      @OnErrorState="HandleErrorState">
      <template slot="append">
        <slot name="append" />
      </template>
    </gat-time-edit>

    <gat-date-time-edit
      v-else-if="dateTimeEdit"
      :autocomplete="autocomplete"
      :data-lpignore="dataLpignore"
      :data-form-type="dataFormType"
      :label="label"
      :label2="label2"
      v-model="textValue"
      :futureDate="futureDate"
      :autofocus="autofocus"
      @input="updateValue()"
      :disabled="disabled"
      :readonly="isReadonly"
      :hint="hint"
      :inlineEdit="inlineEdit"
      :append-icon="appendIcon"
      :error-messages="errorMessages"
      :required="required"
      :markOptionalInputs="markOptionalInputs"
      :clearable="clearable"
      :showWeekDay="showWeekDay"
      :validatorRules="rules"
      :hide-details="hideDetails"
      @blur="doBlur"
      @OnErrorState="HandleErrorState"></gat-date-time-edit>

    <v-text-field
      v-else
      ref="field"
      :autocomplete="autocomplete"
      :data-lpignore="dataLpignore"
      :data-form-type="dataFormType"
      v-model="textValue"
      :label="getLabel"
      :messages="getMessages"
      @input="updateValue()"
      :autofocus="autofocus"
      :suffix="suffix"
      :error-messages="errorMessages"
      :error="error"
      :disabled="disabled"
      :outlined="isOutlined"
      :filled="isFilled"
      :dense="gatComponentsStore.input_dense"
      placeholder=" "
      :clearable="clearable"
      :readonly="isReadonly"
      :hint="hint"
      :rules="rules"
      :append-icon="appendIcon"
      :markOptionalInputs="markOptionalInputs"
      :counter="getCounter"
      :hide-details="hideDetails"
      @keyup.enter.native="$emit('onenterkey')"
      @blur="doBlur">
      <span v-if="appendText" slot="append" class="mt-1">{{ appendText }}</span>
      <template slot="append">
        <slot name="append"></slot>
      </template>
    </v-text-field>
  </span>
</template>

<script>
import { useGatComponentsStore } from '@/store/gatComponentsStore';
import GatFlex from '../GatFlex.vue';
import GatFieldSpacer from '../GatFieldSpacer.vue';
import GatDateEdit from './GatDateEdit.vue';
import GatTimeEdit from './GatTimeEdit.vue';
import GatDateTimeEdit from './GatDateTimeEdit.vue';
import GatNumEdit from './GatNumEdit.vue';
import { GatInputMixin } from './GatInputMixin';

export default {
  name: 'GatEdit',
  mixins: [GatInputMixin],
  components: { GatFlex, GatFieldSpacer, GatDateEdit, GatTimeEdit, GatDateTimeEdit, GatNumEdit },
  props: {
    appendIcon: String,
    appendText: String,
    autofocus: Boolean,
    checkbox: Boolean,
    futureDate: Boolean,
    autocomplete: {
      type: String,
      default: 'off',
    },
    dataFormType: {
      type: String,
      default: 'other',
    },
    dataLpignore: {
      type: String,
      default: 'true',
    },
    checkedValue: {
      type: [String, Number, Boolean],
      default: true,
    },
    clearable: Boolean,
    counter: [Boolean, Number], // , String],
    dataType: {
      type: String,
      // validator: function (value) {
      //   // The value must match one of these strings
      //   return ['number'].indexOf(value) >= 0}
    },
    dateEdit: Boolean,
    dateTimeEdit: Boolean,
    decimals: Number,
    dense: Boolean,
    disabled: Boolean,
    errorMessages: [String, Array],
    hide: {
      type: String,
      validator: (value) => value.indexOf('phone', 'pad', 'all') !== -1,
    },
    hint: String,
    hideDetails: [String, Boolean],
    inlineEdit: Boolean,
    label: String,
    label2: String,
    lowercase: Boolean,
    mailto: Boolean,
    markOptionalInputs: Boolean,
    maxChars: Number,
    noflex: Boolean,
    readonly: Boolean,
    required: Boolean,
    rows: Number,
    showWeekDay: {
      type: String,
      default: 'auto', // auto use dd, ddd or dddd depending on field width when mounted
      validator: (value) => ['none', 'auto', 'dd', 'ddd', 'dddd'].indexOf(value) >= 0,
    },
    size: String,
    solo: Boolean,
    suffix: String,
    tel: Boolean,
    timeEdit: Boolean,
    toggle: Boolean,
    uncheckedValue: {
      type: [String, Number, Boolean],
      default: false,
    },
    uppercase: Boolean,
    value: [String, Number, Boolean, Object],
    validatorRules: [Function, Array],
    noThousandSeperator: Boolean,
  },

  setup() {
    const gatComponentsStore = useGatComponentsStore();
    return {
      gatComponentsStore,
    };
  },
  data() {
    return {
      textValue: null,
      chkValue: null,
      error: false,
      internalDataType: null,
    };
  },
  watch: {
    dataType(newValue) {
      this.internalDataType = newValue;
    },

    chkValue() {
      this.updateValue();
    },

    value(newValue) {
      if (newValue !== this.textValue) {
        this.setCompValue(newValue);
      }
      if (this.checkForErrors(newValue)) {
        this.HandleErrorState(newValue); // mixin
      }
    },
  },

  created() {
    this.internalDataType = this.dataType;
    if (this.dataType === 'number') {
      this.internalDataType = typeof 0;
    }
    this.setCompValue(this.value);
    if (this.uppercase || this.lowercase) {
      this.updateValue();
    }
  },

  computed: {
    enforceMaxChars() {
      if (typeof this.$gatEnforceMaxChars === 'boolean') {
        return this.$gatEnforceMaxChars;
      }
      return true;
    },
    dataTypevalue() {
      return `dataype ${this.internalDataType}`;
    },

    fieldType() {
      let result = 'GatEdit-text';
      if (this.checkbox || this.toggle) {
        result = 'GatEdit-checkbox';
      }
      return result;
    },

    getCounter() {
      // if we are not showing the counter, show it if maxChar is used and the text is to long
      if (!this.counter && this.maxChars && this.textValue && this.textValue.length > this.maxChars) {
        return this.maxChars;
      }
      return this.counter;
    },

    isOutlined() {
      return !this.inlineEdit;
    },

    isFilled() {
      return this.inlineEdit;
    },

    isNumberType() {
      return this.dataType === 'number';
    },

    isReadonly() {
      if (typeof this.readonly != 'undefined') {
        return this.readonly;
      }
      // return this.getGroupReadonly();
      return false;
    },

    rules() {
      let rules = [...this.addMaxCharRules];

      // add maxChar rules to text fields
      /* this.addMaxCharRules(rules); */

      // required rule
      if (this.required) {
        rules.push((value) => {
          if (value) {
            return true;
          }
          return 'required';
        });
      }

      // custom rules
      if (this.validatorRules) {
        rules = rules.concat(this.validatorRules);
      }

      return rules;
    },
    addMaxCharRules() {
      const rules = [];
      // readonly and disabled fields should not enforce maxChars rule

      const isTextField = !(
        this.checkbox ||
        this.dataType === 'number' ||
        this.dateEdit ||
        this.dateTimeEdit ||
        this.timeEdit
      );
      if (isTextField && this.enforceMaxChars) {
        if (typeof this.maxChars !== 'number') {
          // No maxChar set, show error to developer
          rules.push(() => 'maxChar missing');
        }
      }
      if (this.maxChars > 0) {
        rules.push((value) => {
          if (value && value.length > this.maxChars) {
            // return "max length is "+this.maxChars;
            return 'value is too long';
          }
          return true;
        });
      }

      return rules;
    },
  },

  methods: {
    doBlur(params) {
      this.$emit('blur', params);
    },

    getGroupReadonly(obj) {
      if (!obj) {
        // eslint-disable-next-line no-param-reassign
        obj = this.$parent;
      }
      if (obj.$options.name == 'GatGroup') {
        return obj.isReadonly;
      }
      if (obj != this.$root) {
        return this.getGroupReadonly(obj.$parent);
      }

      return false;
    },

    HandleErrorState() {
      this.expandParentGatGroup();
    },

    validateValue() {
      const result = true;
      return result;
    },

    setCompValue(newValue) {
      if (!this.internalDataType) {
        this.internalDataType = typeof newValue;
      }

      if (this.checkbox || this.toggle) {
        // handle 1/0 when checked-value is true/false
        if (newValue === 1 && this.checkedValue === true) {
          // eslint-disable-next-line no-param-reassign
          newValue = true;
        } else if (newValue === 0 && this.checkedValue === true) {
          // eslint-disable-next-line no-param-reassign
          newValue = false;
        }
        this.chkValue = newValue;
      } else {
        this.textValue = newValue;
      }
    },

    // eslint-disable-next-line consistent-return
    getSize() {
      if (this.size) {
        return this.size;
      }
      if (this.dateEdit) {
        return 'xs4 sm3 md2 xl1';
      }
      if (this.timeEdit) {
        return 'xs3 sm2 md1';
      }
      if (this.dateTimeEdit) {
        return 'xs6 sm3 md2';
      }
    },

    getValue() {
      let result = this.textValue;

      if (this.checkbox || this.toggle) {
        result = this.chkValue;
      }

      return result;
    },

    updateValue() {
      this.validateValue();
      let value = this.getValue();
      if (this.uppercase && value) {
        value = value.toUpperCase();
      }
      if (this.lowercase && value) {
        value = value.toLowerCase();
      }
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped>
.inlineEditClass {
  margin-top: 10px;
}

.gat-edit-btn {
  min-width: 0 !important;
  height: auto;
  width: auto;
  width: 40px;
  height: 40px !important;
  margin: auto;
}
</style>
