<template>
  <div>
    <gat-grid
      title="E-Mails"
      class="table-spacing elevation-0"
      dense
      :items="emailItems"
      :hide-empty-columns="true"
      :columns="columns"
      :fixedheader="true"
      :rowMenu="rowMenu"
      height="calc(100vh - 135px)"
      width="100%"
      reloadBtn
      @reload-clicked="getEmails"
      @row-clicked="gotoEmailItem"
      @rowMenuClicked="rowMenuClicked"
      keyName="ID"
      :loading="isLoading"
      :selectable="canDelete"
      viewGroupName="emailList"
      gridName="emailCompGrid"
      :selectedKeys="selectedEmailIds"
      @selectionChanged="selectedEmailIds = $event">
      <!-- :orgSortBy="[{field:'SENT', desc:true}]" -->
      <template slot="cell" slot-scope="props">
        <grid-label v-if="props.column.field == 'LABEL_ID' && props.value" :labelId="props.row.LABEL_ID" />
        <a
          v-else-if="props.column.field == 'EXTMAIL2_NO' && props.value"
          :href="'callmark:OpenDocument?' + props.value"
          target="_blank"
          class="gridLink">
          {{ props.value }}
        </a>
        <div v-else-if="props.column.field == 'FROM_'">
          <!-- style="white-space: nowrap !important; overflow: hidden !important; text-overflow: ellipsis !important; max-width: 150px;" -->
          <a v-if="props.value.includes('@') && emailLink" :href="`mailto:${props.value}`" class="gridLink" @click.stop>
            {{ props.value }}
          </a>
          <span v-else>
            {{ props.value }}
          </span>
        </div>
        <div
          v-else-if="props.column.field == 'TO_' || props.column.field == 'CC'"
          class="d-flex justify-start align-center">
          <!-- style="white-space: nowrap !important; overflow: hidden !important; text-overflow: ellipsis !important; max-width: 100%;" -->

          <!-- <div v-if="formatEmailTo(props.value).emails && formatEmailTo(props.value).emails.length >= 1">(+{{formatEmailTo(props.value).emails.length}})</div> -->
          <a
            v-if="formatEmailTo(props.value).email.includes('@') && emailLink"
            :href="`mailto:${formatEmailTo(props.value).email}`"
            class="gridLink"
            @click.stop>
            {{
              formatEmailTo(props.value).email.length >= 50
                ? formatEmailTo(props.value).email.substring(0, 50) + '...'
                : formatEmailTo(props.value).email
            }}
          </a>
          <span v-else>
            {{
              formatEmailTo(props.value).email.length >= 50
                ? formatEmailTo(props.value).email.substring(0, 50) + '...'
                : formatEmailTo(props.value).email
            }}
          </span>
          <div v-if="formatEmailTo(props.value).emails && formatEmailTo(props.value).emails.length >= 1" @click.stop>
            <v-tooltip bottom v-if="formatEmailTo(props.value).emails && formatEmailTo(props.value).emails.length >= 1">
              <template v-slot:activator="{ on, attrs }">
                <!-- <div v-bind="attrs" v-on="on">+{{formatEmailTo(props.value).emails.length}}</div> -->
                <div v-bind="attrs" v-on="on" class="ml-1">
                  <v-chip x-small color="blue" class="pa-1 white--text" label
                    >+{{ formatEmailTo(props.value).emails.length }}</v-chip
                  >
                </div>
              </template>
              <div v-for="people in formatEmailTo(props.value).emails" :key="people">
                <span>{{ people }}</span>
              </div>
            </v-tooltip>
          </div>
        </div>
        <div
          v-else-if="props.column.field == 'SUBJECT'"
          style="white-space: nowrap !important; overflow: hidden !important; text-overflow: ellipsis !important">
          <span v-if="props.value && props.value.length > subjectStringLen">
            {{ props.value.substring(0, subjectStringLen) + '...' }}
          </span>
          <span v-else-if="props.value">{{ props.value }}</span>
        </div>
        <div v-else-if="props.column.field == 'NUM_ATTACHMENTS'" class="d-flex justify-center">
          <div v-if="props.value > 0" style="position: relative">
            <v-icon>
              <!-- style="transform: rotate(90deg);" -->
              mdi-attachment
            </v-icon>
            <!-- <div class="icon-counter elevation-1">{{props.value}}</div>
                  <div v-if="props.value > 9" style="position: absolute; top: -10px; right: -15px; text-align: cetner; line-height: 18px; font-weight: 400; font-size: 14px;" >+</div> -->
          </div>
        </div>

        <div v-else-if="props.column.field == 'WEB_ACCESS' && props.value" class="d-flex flex-nowrap">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip small v-bind="attrs" v-on="on" @click.stop="showProperties(props.row)">{{
                getAccessGroupNames(props.value).displayName
              }}</v-chip>
            </template>
            <div v-for="accesGroups in getAccessGroupNames(props.value).content" :key="accesGroups">
              {{ accesGroups }}
            </div>
          </v-tooltip>
        </div>

        <div
          v-else-if="
            (applicationStore.user.internal && props.column.field == 'CONTEXT_MENU') ||
            ($vuetify.breakpoint.xs && props.column.field == 'CONTEXT_MENU')
          "
          class="d-flex align-center justify-center">
          <v-btn icon @click.stop="showContextMenu($event, props.row.ID)">
            <v-icon color="primary">mdi-dots-vertical</v-icon>
          </v-btn>
        </div>
      </template>
      <template slot="btns">
        <wm-button-group
          class="right mr-1"
          :buttons="wmButtonGroup"
          @newEmailClick="newEmailClick"
          @createEmailClick="(val) => createEmailClick(val)"
          @deleteClick="showDeleteDialog = true" />
      </template>
    </gat-grid>
    <EmailRecipientsDialog
      v-if="showNewEmailDialog"
      v-model="showNewEmailDialog"
      :portcallId="portcallId"
      :location="location" />
    <v-overlay :value="isLoadingOverlay" :absolute="true" opacity="0.3" style="z-index: 3 !important">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showDeleteDialog" persistent max-width="340">
      <v-card>
        <v-card-title class="title">Delete</v-card-title>
        <v-card-text class="text-center"
          >Are you sure you want to delete <span v-if="selectedEmailIds.length >= 1"></span
          >{{
            selectedEmailIds.length > 1 ? selectedEmailIds.length + ' e-mails' : selectedEmailIds.length + ' e-mail'
          }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDeleteDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="deleteEmails()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <email-status :showForm="showEmailStatusDialog" :guid="emailTemplateGuid" @close="showEmailStatusDialog = false" @documentReady="showEmailStatusDialog=true" @documentCreated="getEmails" @goToEmailClicked="goToEditDraft($event)" /> -->
    <email-generator
      :portcallId="portcallId"
      :location="location"
      :guid="emailTemplateGuid"
      @documentCreated="getEmails" />
    <email-properties
      :showForm="showPropertiesForm"
      v-model="email"
      :webAccessGroups="webAccessGroups"
      :location="location"
      @close="propertiesFormClosed" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete } from '@/store/api';
import GridLabel from '@/app-components/GridLabel.vue';
import { isValidEmail } from '@/modules/email/emailFunctions';
import EmailProperties from '@/app-components/EmailProperties.vue';
import EmailGenerator from '@/app-components/EmailGenerator.vue';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
import { useAppStatusStore } from '@/store/appStatusStore';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import EmailRecipientsDialog from '../modules/email/EmailRecipientsDialog.vue';

export default {
  name: 'EmailList',
  props: {
    location: String,
    portcallId: Number,
    clientId: Number,
    vesselId: Number,
    dirty: {
      type: Number,
      default: 0,
    },
  },
  components: {
    GridLabel,
    EmailRecipientsDialog,
    /* EmailStatus, */
    EmailGenerator,
    EmailProperties,
  },
  setup() {
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    const appStatusStore = useAppStatusStore();
    return {
      helperStore,
      appStatusStore,
      portcallStore,
      applicationStore,
    };
  },
  data() {
    return {
      email: {},
      showPropertiesForm: false,
      emails: [],
      emailTemplates: [],
      isLoading: false,
      showNewEmailDialog: false,
      isLoadingOverlay: false,
      emailTemplateGuid: undefined,
      /* showEmailStatusDialog: false, */
      showDeleteDialog: false,
      selectedEmailIds: [],
      /** set to true to show links in the email list. */
      emailLink: false,
    };
  },

  created() {},
  activated() {
    if (this.isDirty) {
      this.getEmails();
      this.isDirty = false;
    }
  },

  watch: {
    agencyIdSelected: {
      handler() {
        this.getEmails();
      },
    },
    dirty: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getEmails();
        }
      },
    },
    portcallId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getEmails();
        }
      },
    },
    clientId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getEmails();
        }
      },
    },
    vesselId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getEmails();
        }
      },
    },
  },

  computed: {
    webAccessGroups() {
      return this.helperStore.webAccessGroups;
    },
    agencyIdSelected() {
      return this.applicationStore.agencyIdSelected;
    },
    agencyId() {
      if (this.location === 'portcall') {
        return this.portcallStore.callDetails.SETUP_ID;
      }
      return this.agencyIdSelected;
    },
    applicationStatus() {
      return this.appStatusStore.getAppStatus;
      /* let result = {};
                  result.PortCall = {};
                  result.PortCall.Id = this.callId;
                  result.PortCall.CcmTaskIds = this.selectedItemKeys
                  return result; */
    },
    isDirty: {
      get() {
        return this.applicationStore.emailListIsDirty;
      },
      set(val) {
        this.applicationStore.setEmailListIsDirty(val);
      },
    },
    /** If the user can delete emails. */
    canDelete() {
      /* if(this.hasSelectedOtherUsersDraft){
                      return false;
                  } */
      return this.applicationStore.userRight('EM.DELETE');
    },
    /** If the user has selected another users draft, the result will be a string/error message. Otherwise returns undeinfed */
    hasSelectedOtherUsersDraft() {
      if (Array.isArray(this.selectedEmailIds) && this.selectedEmailIds.length > 0) {
        const selectedEmailIds = [...this.selectedEmailIds];
        for (let i = 0; i < selectedEmailIds.length; i++) {
          const id = selectedEmailIds[i];
          const found = this.emails.find(
            (item) =>
              item.ID === id &&
              item.DRAFT === 1 &&
              item.SENT_BY !== this.applicationStore.user.userNameLong.toUpperCase()
          );
          if (found) {
            return 'You cannot delete a draft that is not yours';
          }
        }
      }
      return undefined;
    },
    /** If the user can create or update emails. */
    canEdit() {
      return this.applicationStore.userRight('EM.EDIT');
    },
    /** True if the user has selected any items in the grid. */
    hasSelectedRowsInGrid() {
      return Array.isArray(this.selectedEmailIds) && this.selectedEmailIds.length > 0;
    },
    askForRecipientsOnNewEmail() {
      return this.applicationStore.globalSettings.SGL_MAIL_ASK_ADDRESS === 1;
    },
    wmButtonGroup() {
      const result = [];
      // if (this.applicationStore.user.internal) {
      if (this.applicationStore.globalSettings.SGL_WM_EMAIL_PROVIDER !== 0 && this.canEdit) {
        if (Array.isArray(this.emailTemplateItems) && this.emailTemplateItems.length > 0) {
          result.push({
            btnType: 'CUSTOM',
            mainAction: true,
            caption: 'Create email',
            icon: 'mdi-email',
            menuItems: this.emailTemplateItems,
          });
        }
      }
      if (this.canDelete) {
        result.push({
          btnType: 'DELETE',
          eventName: 'deleteClick',
          disabled: !this.hasSelectedRowsInGrid && !this.hasSelectedOtherUsersDraft,
        });
      }
      // }
      return result;
    },
    emailTemplateItems() {
      const newEmailItem = {};
      newEmailItem.caption = 'New email';
      newEmailItem.eventName = 'newEmailClick';
      newEmailItem.icon = 'mdi-plus';

      const templates = [...this.emailTemplates];
      let result = [];
      if (this.location === 'portcall' && this.locationId) {
        if (Array.isArray(templates) && templates.length > 0) {
          result = templates.map((item) => ({
            caption: item.TITLE,
            eventName: 'createEmailClick',
            icon: 'mdi-file-outline',
            ID: item.ID,
          }));
        }
      }

      result.unshift(newEmailItem);
      return result;
    },
    subjectStringLen() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 140;
      }
      if (this.$vuetify.breakpoint.xsOnly) {
        return 40;
      }
      return 80;
    },
    rowMenu() {
      const result = [];
      if (this.applicationStore.user.internal) {
        if (this.location === 'portcall') {
          result.push({
            name: 'changeProperties',
            caption: 'Web access',
            icon: 'mdi-playlist-edit',
            enabled: this.applicationStore.userRight('EM.EDIT') && this.location === 'portcall',
          });
        }
        result.push({
          name: 'remove label',
          caption: 'Remove label',
          icon: 'mdi-label-off',
          enabled: this.applicationStore.userRight('DOC.NEW_AND_EDIT'),
          separatorBelow: true,
        });
        let subHeader = 'Labels';
        for (let index = 0; index < this.helperStore.documentLabels.length; index++) {
          const label = this.helperStore.documentLabels[index];
          let locationFilter = null;
          switch (this.location) {
            case 'portcall':
              locationFilter = 0;
              break;
            case 'client':
              locationFilter = 1;
              break;
            case 'vessel':
              locationFilter = 2;
              break;
            case 'proforma': // not yet implemented
              locationFilter = 3;
              break;
            case 'purchaseInvoice': // not yet implemented
              locationFilter = 4;
              break;
            case 'expense':
              locationFilter = 5;
              break;
            case 'service':
              locationFilter = 7;
              break;
            default: // all labels.
              locationFilter = -1;
          }

          if (
            (label.DLA_TYPE_EMAIL == 1 && label.DLA_LOCATION_FILTER == null) ||
            (label.DLA_TYPE_EMAIL == 1 && label.DLA_LOCATION_FILTER == locationFilter)
          ) {
            result.push({
              name: 'setLabel',
              caption: label.DLA_LABEL,
              icon: 'label',
              enabled: this.applicationStore.userRight('EM.EDIT'),
              subHeader,
              label,
              iconColor: `#${this.delphiColorToHex(label.DLA_COLOR_BACKGROUND)}`,
            });
            subHeader = null;
          }
        }
      }

      return result;
    },
    locationId() {
      if (this.location === 'portcall') {
        return this.portcallId;
      }
      if (this.location === 'client') {
        return this.clientId;
      }
      if (this.location === 'vessel') {
        return this.vesselId;
      }
      return null;
    },
    columns() {
      const result = [];
      result.push({ header: 'Mail No', field: 'MAIL_NO', hide: true });
      result.push({
        header: 'Label',
        field: 'LABEL_ID',
        width: '1%',
        nowrap: true,
        calculated: (rowData) => this.getLabelTitle(rowData.LABEL_ID),
      });
      result.push({
        header: 'Sent',
        field: 'SENT',
        dataType: 'datetime',
        width: '1%',
        nowrap: true,
        calculatedClass: (rowData) => (rowData.SENT === null || rowData.DRAFT === 1 ? 'red--text' : ''),
        calculated: (rowData) =>
          rowData.SENT === null || rowData.DRAFT === 1 ? 'Draft' : this.formatEmailDate(rowData.SENT),
      });
      result.push({ header: '', field: 'NUM_ATTACHMENTS', width: '1%' });
      result.push({ header: 'From', field: 'FROM_', nowrap: true });
      result.push({ header: 'Subject', field: 'SUBJECT', nowrap: true, calculatedClass: () => 'font-weight-medium' });
      result.push({ header: 'To', field: 'TO_', nowrap: true });
      result.push({ header: 'Cc', field: 'CC', nowrap: true });
      result.push({ header: 'reMark ref', field: 'EXTMAIL2_NO', nowrap: true });
      result.push({ header: 'Comment', field: 'EMAIL_COMMENT' });
      result.push({ header: 'Created by', field: 'SENT_BY', hide: true });
      result.push({ header: 'Draft', field: 'DRAFT', hide: true });
      if (this.applicationStore.user.internal && this.location === 'portcall') {
        result.push({
          header: 'Web access',
          field: 'WEB_ACCESS',
          hideOnPhone: true,
          custom: true,
          width: '1%',
          class: 'px-2',
        });
      }
      if (this.applicationStore.user.internal) {
        result.push({
          header: '',
          field: 'CONTEXT_MENU',
          custom: true,
          hideOnPhone: false,
          ignoreOnCopy: true,
          width: '1%',
          class: 'px-0',
        });
      }

      return result;
    },
    emailItems() {
      if (Array.isArray(this.emails) && this.emails.length > 0) {
        let emails = [...this.emails];
        if (!this.applicationStore.user.internal && !this.applicationStore.userRight('EM.EDIT')) {
          emails = emails.filter((item) => item.DRAFT === null || item.DRAFT === 0);
        }
        return emails;
      }
      return [];
    },
  },

  methods: {
    deleteEmails() {
      const emails = [...this.emails];
      const promises = [];
      this.showDeleteDialog = false;
      this.isLoading = true;
      this.selectedEmailIds.forEach((id) => {
        promises.push(
          apiDelete(`mail/${id}`).then((result) => {
            if (result.resultCategory == 1) {
              const idx = emails.findIndex((item) => item.ID === id);
              if (idx >= 0) {
                emails.splice(idx, 1);
              }
            }
          })
        );
      });
      Promise.allSettled(promises).finally(() => {
        this.emails = emails;
        this.selectedEmailIds = [];
        this.isLoading = false;
      });
    },
    showContextMenu(event, rowItemId) {
      console.log('showContextMenu', event, rowItemId);
      // used for the context button on mobile devices.
      this.gatComponentsMessageBus.$emit('gatgrid-rowmenu-show-context-menu', {
        event,
        rowItemId,
        gridName: 'emailCompGrid',
      });
    },
    newEmailClick() {
      if (this.location === 'portcall' && this.askForRecipientsOnNewEmail) {
        // The if-statement for location should be removed when recipients dialog can be used for client & vessel.
        this.showNewEmailDialog = true;
      } else {
        this.isLoadingOverlay = true;
        apiGet('mail/new-email')
          .then((result) => {
            if (result && result.payload) {
              const emailId = result.payload.ID;
              const mailNo = result.payload.MAIL_NO;
              if (this.location === 'portcall') {
                this.$router.push({
                  name: 'prortcallEmailNew',
                  params: { callId: this.portcallId, emailId, to: [], bcc: [], cc: [], mailNo },
                });
              } else if (this.location === 'vessel') {
                this.$router.push({
                  name: 'vesselEmailNew',
                  params: { vesselId: this.vesselId, emailId, to: [], bcc: [], cc: [], mailNo },
                });
              } else if (this.location === 'client') {
                this.$router.push({
                  name: 'clientEmailNew',
                  params: { clientId: this.clientId, emailId, to: [], bcc: [], cc: [], mailNo },
                });
              }
            }
          })
          .finally(() => {
            this.isLoadingOverlay = false;
          });
      }
    },
    createEmailClick(item) {
      if (this.portcallId) {
        const guid = GlobalHelperFunctions.GUID();
        const templateId = item.ID;
        const { location } = this;
        const foreignKey = this.portcallId;
        const appStatus = JSON.stringify(this.applicationStatus);
        const param = { guid, templateId, location, foreignKey, appStatus, isEmail: 1 };
        apiPut('document/generate/', param).then((result) => {
          if (result.resultCategory === 0 && result.resultType === 0) {
            this.emailTemplateGuid = guid;
            /* this.showEmailStatusDialog = true; */
          }
        });
      }
    },
    formatEmailTo(toStr) {
      let emails = null;
      let email = toStr;
      if (toStr) {
        emails = toStr.split(',');
        if (toStr.includes(';')) {
          emails = toStr.split(';');
        }
        if (typeof emails == 'object' && emails.length >= 2) {
          // eslint-disable-next-line prefer-destructuring
          email = emails[0];
        }
        emails.splice(0, 1);
      }
      return { email, emails };
    },
    formatEmailDate(date) {
      const today = new Date();
      // eslint-disable-next-line no-param-reassign
      date = new Date(date);
      let result = GlobalHelperFunctions.globalFormatDateTime(date, 'DD.MM YYYY HH:mm');

      const sameYear = today.getYear() === date.getYear();
      if (sameYear) {
        result = GlobalHelperFunctions.globalFormatDateTime(date, 'DD MMM HH:mm');
      }

      const sameDay = today.getDate() === date.getDate();
      if (sameDay) {
        result = GlobalHelperFunctions.globalFormatDateTime(date, 'HH:mm');
      }
      return result;
    },
    getLabelTitle(labelId) {
      const result = this.helperStore.documentLabels.find((label) => label.DLA_ID == labelId);
      if (result) {
        return result.DLA_LABEL;
      }
      return '';
    },

    getEmails() {
      this.isLoading = true;
      apiGet(`mail/all/${this.location}/${this.locationId}`)
        .then((result) => {
          if (result) {
            this.emails = result;
          }
          apiGet(`mail/templates/${this.location}/${this.agencyId}`).then((templateResult) => {
            if (templateResult) {
              this.emailTemplates = templateResult;
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    gotoEmailItem(item) {
      if (item.EXTMAIL2_NO) return;

      const emailId = +item.ID;
      if ((item.SENT === null || item.DRAFT === 1) && emailId) {
        this.goToEditDraft(emailId);
      } else if (emailId) {
        this.$router.push({ name: `${this.location}EmailItem`, params: { emailId } });
      }
    },
    propertiesFormClosed(param) {
      this.showPropertiesForm = false;
      if (param.btn == 'save') {
        const webAccessObject = {
          ID: param.value.ID,
          WEB_ACCESS: param.value.WEB_ACCESS,
        };

        apiPut('/mail/webaccess', webAccessObject).then(() => {
          this.getEmails();
        });
      }

      this.email = {};
    },
    showProperties(rowItem) {
      if (!this.applicationStore.userRight('EM.EDIT')) {
        return;
      }

      if (this.location !== 'portcall') {
        return;
      }

      this.email = rowItem;
      this.showPropertiesForm = true;
    },
    async createRecipientsArray(recipientsArray) {
      const error = [];
      const result = [];
      const promises = [];
      if (Array.isArray(recipientsArray) && recipientsArray.length > 0) {
        recipientsArray.forEach((item) => {
          if (isValidEmail(item.email)) {
            result.push(item.email);
            // promises.push(apiGet(`portcall/emailRecipients/addManual/${item.email}`).then(res => {
            //     if(Array.isArray(res) && res.length > 0){
            //         result.push(res[0])
            //     } else {
            //         result.push(item.email);
            //     }
            // }));
          } else {
            error.push(`email ${item.email} is not a valid e-mail address`);
          }
        });
      }
      await Promise.allSettled(promises);
      return { error, result };
    },
    async goToEditDraft(emailId) {
      this.isLoadingOverlay = true;
      const email = await this.getEmail(emailId);
      if (email) {
        const promises = [];
        const toResult = await this.createRecipientsArray(email.TO);
        promises.push(toResult);
        const ccResult = await this.createRecipientsArray(email.CC);
        promises.push(ccResult);
        const bccResult = await this.createRecipientsArray(email.BCC);
        promises.push(bccResult);
        await Promise.allSettled(promises);
        this.isLoadingOverlay = false;
        const to = toResult.result;
        const cc = ccResult.result;
        const bcc = bccResult.result;
        // eslint-disable-next-line no-underscore-dangle
        const mailFrom = email.FROM_;
        const onLoadError = [...toResult.error, ...ccResult.error, ...bccResult.error];
        const content = email.MAIL_HTML;
        const subject = email.SUBJECT;
        const mailNo = email.MAIL_NO;
        const attachments = email.MAIL_ATTACHMENTS;

        const { location } = this.location;
        const { portcallId } = this;
        const { clientId } = this;
        const { vesselId } = this;
        const params = {
          emailId,
          location,
          content,
          mailNo,
          subject,
          mailFrom,
          to,
          cc,
          bcc,
          attachments,
          onLoadError,
          isDraft: true,
          createdBy: email.SENT_BY,
        };
        if (this.location === 'client' && this.clientId) {
          this.$router.push({
            name: 'clientEmailNew',
            params: {
              ...params,
              clientId,
            },
          });
        }
        if (this.location === 'portcall' && this.portcallId) {
          this.$router.push({
            name: 'prortcallEmailNew',
            params: {
              ...params,
              portcallId,
            },
          });
        }
        if (this.location === 'vessel' && this.vesselId) {
          this.$router.push({
            name: 'vesselEmailNew',
            params: {
              ...params,
              vesselId,
            },
          });
        }
      }
    },
    async getEmail(emailId) {
      let result;
      try {
        result = await apiGet(`mail/${+emailId}`);
      } catch (error) {
        this.isLoadingOverlay = false;
        this.isLoading = false;
        console.error(error);
      }
      return result;
    },

    delphiColorToHex(number) {
      let str = number.toString(16);
      str = str.padStart(6, '0');
      const R = str.substring(4, 6);
      const G = str.substring(2, 4);
      const B = str.substring(0, 2);
      // return str+':'+R+' '+G+' '+B;
      return R + G + B;
    },

    rowMenuClicked(event) {
      const mailId = event.rowItem.ID;
      if (event.menuItem.name == 'setLabel') {
        const labelId = event.menuItem.label.DLA_ID;
        apiPut(`/mail/setLabel/${mailId}/${labelId}`).then((result) => {
          if (result.resultCategory == 1) {
            this.emails.map((item) => {
              const newItem = { ...item };
              if (newItem.ID === mailId) {
                newItem.LABEL_ID = labelId;
              }
              return newItem;
            });
          }
        });
      } else if (event.menuItem.name == 'remove label') {
        apiPut(`/mail/setLabel/${mailId}/${-1}`).then((result) => {
          if (result.resultCategory == 1) {
            this.emails.map((item) => {
              const newItem = { ...item };
              if (newItem.ID === mailId) {
                newItem.LABEL_ID = null;
              }
              return newItem;
            });
          }
        });
      } else if (event.menuItem.name == 'changeProperties') {
        this.showProperties(event.rowItem);
      } else {
        /* console.log(event); */
      }
    },
    createWebAccessObjectFromIds(obj) {
      if (obj.WEB_ACCESS) {
        // eslint-disable-next-line no-param-reassign
        obj.WEB_ACCESS = obj.WEB_ACCESS.map((item) => ({
          WAD_DOCUMENT_ID: obj.ID,
          WAD_WMG_ID: item,
          WAD_WMVER: 1,
        }));
      }
      return obj;
    },
    getAccessGroupNames(ids) {
      const result = {
        displayName: '',
        content: [],
      };
      if (ids) {
        const idArr = ids.split(',');
        idArr.forEach((id) => {
          const accesGrp = this.webAccessGroups.find((item) => item.ID == id);
          if (accesGrp && accesGrp.NAVN) {
            result.content.push(accesGrp.NAVN);
          }
        });
        if (result.content.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          result.displayName = result.content[0];
        }
        if (result.content.length > 1) {
          result.displayName += ` +${result.content.length - 1}`;
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep td {
  padding: 0 !important;
  flex-grow: 0 !important;
}
.icon-counter {
  padding: 2px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  top: -10px;
  right: -25px;
  background: rgb(0, 121, 191);
  font-weight: 400;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  font-size: 11px;
  text-align: center;
  color: white;
}
</style>
