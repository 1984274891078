<template>
  <span>
    <gat-button-group :buttons="getButtons" :rightAligned="rightAligned" :showMenu="showMenu" @click="buttonClicked" />
    <document-status
      :showForm="showDocumentStatusDialog"
      :guid="guid"
      @close="showDocumentStatusDialog = false"
      @documentReady="showDocumentStatusDialog = true"
      @documentCreated="documentCreated" />
  </span>
</template>

<script>
import { apiPut } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import { useHelperStore } from '@/store/helperStore';
import DocumentStatus from './DocumentStatus.vue';

export default {
  name: 'WmButtonGroup',
  props: {
    showMenu: Boolean,
    buttons: {
      type: Array,
    },
    rightAligned: {
      type: Boolean,
      default: true,
    },
  },
  components: { DocumentStatus },
  setup() {
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    return {
      portcallStore,
      helperStore,
    };
  },
  data() {
    return {
      showDocumentStatusDialog: false,
      addedToQueue: false,
      guid: null,
    };
  },

  created() {},

  watch: {},

  computed: {
    getButtons() {
      const result = [];
      // eslint-disable-next-line array-callback-return
      this.buttons.map((button) => {
        if (button.btnType === 'CREATE_DOC') {
          const btn = GlobalHelperFunctions.duplicateViaJson(button);
          btn.btnType = 'CUSTOM';
          btn.caption = 'create doc';
          btn.icon = 'mdi-file-document';
          btn.isCreateDocBtn = true;
          btn.menuItems = this.docTemplatesMenuItems(btn.docType, btn.agencyId, btn.disabled);
          btn.openMenuOnHover = true;
          btn.embeddMenuItems = true;
          btn.useSubTitle = true;
          btn.subHeader = 'create document';
          btn.disabled = !btn.menuItems || btn.menuItems.length === 0 || button.disabled;
          result.push(btn);
        } else if (button.btnType === 'ADD' && typeof button.mainAction === 'undefined') {
          const btn = GlobalHelperFunctions.duplicateViaJson(button);
          btn.mainAction = true;
          result.push(btn);
        } else {
          result.push(button);
        }
      });

      return result;
    },
  },

  methods: {
    documentCreated() {
      this.$emit('documentCreated');
    },
    buttonClicked(eventParam) {
      const { menuItem } = eventParam;
      const { button } = eventParam;

      if (button.isCreateDocBtn) {
        this.createDocumentClicked(menuItem, button);
        this.$emit('click', { caption: menuItem.caption, menuItem, button });
      } else if (menuItem) {
        this.$emit('click', { caption: menuItem.caption, menuItem, button });
        if (menuItem.eventName) {
          this.$emit(menuItem.eventName, menuItem);
        }
      } else {
        this.$emit('click', { caption: button.caption, button });
        if (this.eventName(button)) {
          this.$emit(this.eventName(button), button);
        }
      }
    },

    docTemplatesMenuItems(docType, agencyId, disabled) {
      const result = [];
      const docTemplates = this.helperStore.documentTemplates(docType, agencyId);
      // eslint-disable-next-line array-callback-return
      docTemplates.map((item) => {
        const menuItem = {
          caption: item.TITLE,
          icon: this.getIcon(item),
          ID: item.ID,
          disabled,
        };
        result.push(menuItem);
      });
      return result;
    },

    eventName(btn) {
      if (btn.eventName) {
        return btn.eventName;
      }
      return `${btn.btnType.toLowerCase()}Click`;
    },

    getIcon(item) {
      let result = 'mdi-file-outline';

      if (item.FILENAME && item.FILENAME.toUpperCase().endsWith('.DOCX')) {
        result = 'mdi-file-word-outline';
      }
      if (item.MAKE_PDF) {
        result = 'mdi-file-pdf-outline';
      }
      return result;
    },

    createDocumentClicked(item, button) {
      this.guid = GlobalHelperFunctions.GUID();
      this.showDocumentStatusDialog = true;
      const appstatusen = button.applicationStatus || {};
      appstatusen.VesselId = this.portcallStore.callDetails.VESSEL_ID;
      appstatusen.SetupID = this.portcallStore.callDetails.SETUP_ID;

      const param = {
        guid: this.guid,
        templateId: item.ID,
        location: button.location,
        foreignKey: button.foreignKey,
        appStatus: JSON.stringify(appstatusen),
      };
      apiPut('document/generate/', param).then(() => {});
    },
  },
};
</script>

<style scoped></style>
