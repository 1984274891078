<template>
    <div>
    <!-- <div class="d-flex flex-shrink-1">
    <v-btn icon @click="scrollGatTab('left')"><v-icon>mdi-chevron-left</v-icon></v-btn>
    {{isTouchDevice}} -->
    <v-tabs  v-model="activeTabIndex" :height="30" :background-color="getBarColor" class="tabs" :show-arrows="false" ref="gatTab">
        <v-tab v-for="(tab, index) in 30" :key="index">
        <div style="position:relative; width: 100%;">
                        Hallo
                        <v-icon v-if="true" :style="activeTabIndex === index ? 'opacity: 1' : 'opacity: 0.75'" x-small style="position: relative; top:-2.5px;right:0px;">mdi-eye-off-outline</v-icon>
                    </div>
            </v-tab>

    </v-tabs>
    <!-- <div>prev</div>
    </div> -->
        <record-presenter :value="someValue" />
        <!-- <gat-edit label="ETA" v-model="someValue.dateTime" dateTimeEdit /> -->
        <gat-client-select label="Client" v-model="someValue.CLIENT_ID" @item-selected="someValue.debtor=$event" :readonly="someValue.ro" />
         <v-btn  @click="btnClicked">Click me</v-btn>
          <v-btn  @click="btnClicked2">Click me to</v-btn>
    </div>
</template>

<script>

import GatClientSelect from '@/app-components/GatClientSelect.vue';

    export default {
        name: 'TestPage',
        props: [],
        components: {GatClientSelect},
        data() {
            return	{
                activeTabIndex: 0,
                someValue : {
                    CLIENT_ID : null,
                    debtor : null,
                    ro : false,

                }
            }
        },


        created(){
        },

        watch:{
        },

        methods:{
            btnClicked(){
                this.someValue.CLIENT_ID = 884053970;
            },
            btnClicked2(){
                this.someValue.CLIENT_ID = 866591153;
            },
            scrollGatTab(){

            }
        },
        computed:{
            isTouchDevice(){
                return matchMedia('(hover: none)').matches;
            },
            getBarColor(){
                if(!this.$vuetify.theme.dark){
                    return 'primary';
                } 
                   return '#EF6C00'
                
            },
        }
    }
</script>

<style scoped>

</style>
