<template>
  <v-app :style="cssProps">
    <navigation-drawer :value="drawerVisible" @showDrawerChanged="showDrawerChanged" class="drawer-menu" />

    <app-bar :drawerVisible="drawerVisible" @showDrawerChanged="showDrawerChanged" />

    <v-main class="content ma-2" :class="drawerVisible ? 'drawer-open' : ''">
      <keep-alive>
        <router-view
          v-if="
            applicationStore.appIsInitialized || $route.path === '/auth' || $route.name === 'NoLicense'
          "></router-view>
      </keep-alive>
    </v-main>
    <messageHandler></messageHandler>

    <InitializingDialog :value="!applicationStore.appIsInitialized" />
    <DatabaseWarning :value="!isValidDatabase"></DatabaseWarning>

    <v-snackbar bottom v-model="updateExists" class="mb-6" :timeout="-1" color="primary">
      A new version of the web module is available.
      <v-btn text @click="refreshApp"> Update </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import MessageHandler from '@/app-components/MessageHandler.vue';
import DatabaseWarning from '@/app-components/DatabaseWarning.vue';
import moment from 'moment';
import numeral from 'numeral';
import { defineComponent } from 'vue';
import { useApplicationStore } from '@/store/applicationStore';
import { initializeApp } from '@/services/api/api-calls/initializeApp';
import AppBar from './menus/AppBar.vue';
import NavigationDrawer from './menus/NavigationDrawer.vue';
import InitializingDialog from './app-components/InitializingDialog.vue';
import { checkDatabaseVersion } from './store/databaseChecker';
import 'numeral/locales/de';
import 'numeral/locales/nl-nl';
import updateSw from './app-components/mixins/updateSw';
import { useHelperStore } from './store/helperStore';

export default defineComponent({
  mixins: [updateSw],
  setup() {
    const applicationStore = useApplicationStore();
    const helperStore = useHelperStore();
    return {
      applicationStore,
      helperStore,
    };
  },
  data() {
    return {
      fixed: true,
      tabUsed: false,
      drawerVisible: null,
      isValidDatabase: true,
    };
  },
  provide() {
    return {
      getGroupReadonly: () => {},
    };
  },
  name: 'App',
  components: { MessageHandler, AppBar, NavigationDrawer, InitializingDialog, DatabaseWarning },

  created() {
    moment.locale('en'); // 'en'
    this.setDarkModeFromLocalStorage();
    if (this.applicationStore.debugMode) {
      console.log(this.applicationStore.user.apiToken);
    }
  },

  computed: {
    isCrm() {
      return this.applicationStore.isCRM;
    },
    webCaption() {
      return this.applicationStore.globalSettings?.SGL_WEB_CAPTION;
    },
    colors() {
      if (this.settings.dark) {
        return { primary: '#EF6C00', groupCaption: '##E0E0E0', gatLink: '#64B5F6', gatMenu: '#303030' };
      }
      return { primary: '#FB8C00', groupCaption: '#757575', gatLink: '#1976D2', gatMenu: '#fafafa' };
    },
    cssProps() {
      return {
        '--gatGroupCaption': this.colors.groupCaption,
        '--gatPrimaryColor': this.colors.primary,
        '--gatLinkColor': this.colors.gatLink,
        '--gatMenuColor': this.colors.gatMenu,
        '--gatSideSheetTop': this.sideSheetTop,
        '--gatDrawerWitdth': this.drawerWith,
      };
    },
    drawerWith() {
      if (this.drawerVisible && !this.$vuetify.breakpoint.xsOnly) {
        return '256px';
      }
      return '0px';
    },

    isTokenValid() {
      const dateIsValid = this.applicationStore.isProbablyAuthenticated;
      return dateIsValid;
    },
    settings() {
      return this.applicationStore.settings;
    },
    sideSheetTop() {
      if (this.tabUsed) {
        return '65px';
      }
      return '40px';
    },
  },

  mounted() {
    window.addEventListener('click', (event) => {
      const { target } = event;

      // handle only links that do not reference external resources
      const intUrl = target && target.matches("a:not([href*='://'])");
      const telUrl = target && target.href && target.href.startsWith('tel:');
      // if (target && target.matches("a:not([href*='://'])") && target.href && !target.href.startWith('tel:')) {
      if (target && intUrl && !telUrl && target.href) {
        // some sanity checks taken from vue-router:
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event;
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return;
        // don't handle when preventDefault called
        if (defaultPrevented) return;
        // don't handle right clicks
        if (button !== undefined && button !== 0) return;
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target');
          if (/\b_blank\b/i.test(linkTarget)) return;
        }
        // don't handle same page links/anchors
        const url = new URL(target.href);
        let to = url.pathname;
        if (url.search) {
          to += url.search;
        }
        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault();
          this.$router.push(to);
        }
      }
    });
  },

  watch: {
    isCrm() {
      this.setCRM();
    },
    isTokenValid: {
      handler(val) {
        if (val) {
          checkDatabaseVersion().then((isValid) => {
            this.isValidDatabase = isValid;
            if (isValid) {
              this.retrieveStoreData();
            }
          });
        }
      },
      immediate: true,
    },
    webCaption: {
      handler(val) {
        this.setBranding(val);
      },
    },
  },

  methods: {
    setCRM() {
      const isCrm = this.applicationStore.isCRM;
      if (isCrm) {
        this.applicationStore.documentTitle.defaultDocumentTitle = 'GAT-Ship CRM';
        const favicon = document.getElementById('favicon');
        favicon.href = '/crm.ico';
        this.applicationStore.setAppTitle(document.title);
      } else {
        const favicon = document.getElementById('favicon');
        favicon.href = '/favicon.ico';
      }
    },

    setBranding(caption) {
      console.log('setBranding - ', caption);
      const isCrm = this.applicationStore.isCRM;
      if (isCrm) {
        return;
      }

      if (!caption) {
        this.applicationStore.documentTitle.defaultDocumentTitle = 'GAT-Ship';
        this.applicationStore.setAppTitle('GAT-Ship');
        return;
      }

      if (caption.toLowerCase().includes('gat-ship')) {
        this.applicationStore.documentTitle.defaultDocumentTitle = caption;
      } else {
        this.applicationStore.documentTitle.defaultDocumentTitle = `GAT-Ship - ${caption}`;
      }

      this.applicationStore.setAppTitle(caption);
    },

    setDarkModeFromStore() {
      const darkTheme = this.applicationStore.settings.dark;

      if (darkTheme) {
        this.$vuetify.theme.dark = darkTheme;
      }
    },

    setDarkModeFromLocalStorage() {
      const darkMode = localStorage.getItem('dark_theme');

      this.$vuetify.theme.dark = darkMode == 'true';
    },

    toolbarExtentionChanged(value) {
      this.tabUsed = value;
    },
    showDrawerChanged(value) {
      this.drawerVisible = value;
    },
    retrieveStoreData() {
      this.helperStore.initFixedValues();
      initializeApp().then(() => {
        numeral.locale(this.applicationStore.settings.numberFormat);
        this.setDarkModeFromStore();
      });
    },
  },
});
</script>
<style>
body {
  margin: 0 !important;
  min-height: -webkit-fill-available;
  padding-left: calc(0 + env(safe-area-inset-left)) !important;
  padding-top: calc(0 + env(safe-area-inset-top)) !important;
  padding-right: calc(0 + env(safe-area-inset-right)) !important;
  padding-bottom: calc(0 + env(safe-area-inset-bottom)) !important;
}
</style>
<style lang="scss" scoped>
.drawer-menu {
  z-index: 200 !important;
}
</style>

<style lang="scss">
// Custom style for v-overlay (only on 'content' css-classes)
.content {
  .v-overlay {
    position: fixed !important;
    z-index: 1 !important;
    top: -8px;
    right: 0;
    height: calc(100vh + 8px);
    width: calc(100% + 8px + 8px);
  }
}

.drawer-open {
  .v-overlay {
    width: calc(100% + 8px - 224.5px);
    margin-left: 224.5px;
  }
}

.alert {
  z-index: 10;
}

.inactiveItem {
  text-decoration: line-through;
}

.right {
  float: right !important;
}

.left {
  float: left !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
/* set to position initial in order to fix a overlapping issue for the profile-menu card. */
.v-toolbar__content,
.v-toolbar__extension {
  position: initial;
  width: 100%;
}
/* .v-dialog{ // v-dialog ble for høy pga overflow.
  overflow:unset !important;
} */

html {
  overflow: auto;
}
aside {
  padding-left: calc(4px + env(safe-area-inset-left)) !important;
  padding-top: calc(4px + env(safe-area-inset-top)) !important;
  padding-right: calc(4px + env(safe-area-inset-right)) !important;
  padding-bottom: calc(4px + env(safe-area-inset-bottom)) !important;
}
</style>
