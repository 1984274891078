<template>
  <div>
    <span v-for="(nav, index) in value" :key="index" class="pr-4 navItem" @click="navigate(nav)">
      <v-icon size="20">arrow_back</v-icon> <span class="subheading"> {{ nav.text }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BackNavigator',
  props: ['value'],
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  methods: {
    navigate(navItem) {
      this.$router.push(navItem.href);
    },
  },
};
</script>

<style scoped>
.navItem {
  cursor: pointer;
}
</style>
