<template>
  <div class="pb-5">
    <gat-form :readonly="!applicationStore.userRight('VE.EDIT_AND_NEW')" :isLoading="isLoading">
      <gat-group title="Last known location" :collapsed="false">
        <template slot="compact"> </template>
        <vessel-current-a-i-s :imo="value.LLOYDSNR" />
      </gat-group>
      <GatGroup class="pa-0" title="Vessel masters" :collapsed="true">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field
              v-for="master in value.masters"
              :key="master.ID"
              v-show="master.INACTIVE != 1"
              :value="master.NAME"
              label="Name" />
          </v-layout>
        </template>
        <v-layout wrap>
          <GatSubGroup title="" size="xs12 sm8">
            <gat-grid
              :items="value.masters"
              :columns="masterColumns"
              class="elevation-0"
              @row-clicked="masterRowClicked"
              rowHint="click to edit"
              :rowclass="this.getRowClass"
              :orgSortBy="[{ field: 'INACTIVE' }]"></gat-grid>
          </GatSubGroup>
        </v-layout>
        <v-btn
          v-if="value.ID"
          slot="btn"
          outlined
          color="primary"
          small
          @click="addMaster"
          :disabled="!applicationStore.userRight('VE.EDIT_AND_NEW')">
          <v-icon small>mdi-plus</v-icon>Add
        </v-btn>
        <div v-else class="pa-3">The new vessel have to be saved before this section can be edited</div>
      </GatGroup>

      <gat-group title="Previous names" :collapsed="true">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field
              v-for="(name, index) in vesselNames"
              :key="index"
              :value="name.VNA_OLD_NAME + ' -> ' + name.VNA_NAME"
              :label="formatDisplayDate(name.VNA_TO_DATE)" />
          </v-layout>
        </template>
        <gat-grid :items="vesselNames" :columns="nameColumns" class="elevation-0"></gat-grid>
      </gat-group>

      <gat-group title="Change history" :collapsed="true" v-if="applicationStore.user.internal">
        <template slot="compact">
          <v-layout wrap>
            <gat-compact-field
              v-for="(change, index) in lastChangeHistory"
              :key="index"
              :value="change.HFI_OLD_VALUE + '->' + change.HFI_NEW_VALUE"
              :label="change.HFI_FIELD" />
          </v-layout>
        </template>
        <gat-grid :items="changeHistory" :columns="historyColumns" class="elevation-0"></gat-grid>
      </gat-group>
    </gat-form>

    <vessel-master
      :showForm="masterFormVisible"
      v-model="master"
      @close="masterFormClosed"
      :readonly="!applicationStore.userRight('VE.EDIT_AND_NEW')" />
  </div>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';
import VesselMaster from './VesselMaster.vue';
import VesselCurrentAIS from './VesselCurrentAIS.vue';

export default {
  name: 'VesselHistory',
  props: { value: { default: {} } },
  components: { VesselMaster, VesselCurrentAIS },
  mixins: [],
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {
      master: {},
      masterFormVisible: false,
      maxHistoryCount: 100,
      isLoading: false,
      masterColumns: [
        { header: 'Master name', field: 'NAME' },
        { header: 'Nationality', field: 'NATIONALITY' },
        { header: 'Certificate', field: 'CERTIFICATE' },
        { header: 'Inactive', field: 'INACTIVE', checkbox: true },
      ],
      changeHistory: [],
      historyColumns: [
        { header: 'When', field: 'HFI_DATETIME', dataType: 'datetime' },
        { header: 'Field', field: 'HFI_FIELD' },
        { header: 'Old value', field: 'HFI_OLD_VALUE' },
        { header: 'new value', field: 'HFI_NEW_VALUE' },
        { header: 'Changed by', field: 'HFI_USER' },
      ],
      vesselNames: [],
      nameColumns: [
        { header: 'Date', field: 'VNA_TO_DATE', dataType: 'date' },
        { header: 'Old name', field: 'VNA_OLD_NAME' },
        { header: 'New name', field: 'VNA_NAME' },
      ],
    };
  },

  created() {
    this.masters = GatUtils.makeCopyViaJson(this.value.masters);
    this.getHistory();
    this.getVesselNames();
  },

  deactivated() {
    if (this.$route.name != 'vesselDetails' && this.$route.name != 'vesselDocs') {
      this.applicationStore.setTraversableList([]);
      this.applicationStore.setTraversableListCounter(false);
    }
  },

  watch: {
    value(newValue) {
      this.masters = GatUtils.makeCopyViaJson(newValue.masters);
      this.getHistory();
      this.getVesselNames();
    },
  },

  computed: {
    lastChangeHistory() {
      let result = this.changeHistory.filter((item) => item.HFI_OLD_VALUE);
      if (result.length > 10) {
        result = result.splice(0, 10);
      }
      return result;
    },
  },

  methods: {
    formatDisplayDate(date) {
      return GlobalHelperFunctions.formatDisplayDate(date);
    },
    addMaster() {
      this.master = { VESSEL_ID: this.value.ID };
      this.masterFormVisible = true;
    },

    getHistory() {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiGet(`/vesselhistory/changes/${this.value.ID}/${this.maxHistoryCount}`).then((data) => {
        this.isLoading = false;
        this.changeHistory = data;
      });
    },
    getRowClass(rowItem) {
      let result = '';
      if (rowItem.INACTIVE) {
        result += 'ignored';
      }
      /* if (rowItem.CHECKED) {
                    result += " checked"
                } */
      return result;
    },

    getVesselNames() {
      apiGet(`/vesselhistory/names/${this.value.ID}`).then((data) => {
        this.vesselNames = data;
      });
    },

    masterFormClosed(params) {
      this.masterFormVisible = false;
      if (params.btn == 'save') {
        apiPut('vesselmaster', { value: params.value, orgValue: this.master }).then((result) => {
          if (result.resultCategory == 1) {
            this.value.masters = result.payload;
          }
        });
      } else if (params.btn == 'delete') {
        apiDelete(`vesselmaster/${params.value.ID}`).then(() => {
          const idx = this.value.masters.findIndex((item) => item.ID == params.value.ID);
          this.master = params.value;
          this.value.masters.splice(idx, 1);
          //  this.orgmasters = this.value.masters;//to avoid save btn "changes"
        });
      }
      this.master = {};
    },

    masterRowClicked(rowData) {
      this.master = rowData;
      this.masterFormVisible = true;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .ignored {
  td:nth-of-type(4) {
    text-decoration: none !important;
  }
}
</style>
