import moment from 'moment';
import { useApplicationStore } from '@/store/applicationStore';
import GatComponentsHelperFunctions from '@/plugins/gat-components/common/GatComponentsHelperFunctions';
import GatCommon from '@/plugins/gat-components/common/GatCommon';

function setHistoryField(obj: any, fieldName = 'HISTORY') {
  const applicationStore = useApplicationStore();
  if (!applicationStore.user.userName) {
    console.error('Username not found -> setHistoryField');
    return;
  }
  let currentValue = null;
  if (Object.prototype.hasOwnProperty.call(obj, fieldName)) {
    currentValue = obj[fieldName];
  }
  if (!currentValue) {
    const newValue = moment().format('YYMMDD-HHmm-') + applicationStore.user.userName.substr(0, 12);
    // eslint-disable-next-line no-param-reassign
    obj[fieldName] = newValue;
  } else {
    // yymmdd-uuuuuuuuuuuu--999--yymmdd-hhmm-uuuuuuuuuuuu
    // 191212-SUPER--001--191212-1447-SUPER
    // Added date + addet time + added by user + number of modifications (max length = 3) +modified date + modified time + modified by user (user name length,max 12 chars)
    let sepIndex = currentValue.indexOf('--');
    if (sepIndex == -1) {
      sepIndex = currentValue.length;
    }
    let newValue = currentValue.substr(0, sepIndex);
    const changeCount = currentValue.substr(sepIndex + 2, 3);
    let newCount: any = (parseInt(changeCount, 10) || 0) + 1;
    newCount = newCount.toString().padStart(3, '0');
    newValue = `${newValue}--${newCount}--${moment().format('YYMMDD-HHmm-')}${applicationStore.user.userName.substr(
      0,
      12
    )}`;
    // eslint-disable-next-line no-param-reassign
    obj[fieldName] = newValue;
  }
}

function lookupCode(code: any, list: any, codeField = 'value', textField = 'text') {
  let result = code;
  if (list && list.length > 0) {
    // eslint-disable-next-line array-callback-return, consistent-return
    const record = list.find((item: any) => {
      if (item[codeField] == code) {
        return item;
      }
    });
    if (record) {
      result = record[textField];
    }
  }

  return result;
}

const defaultGlobalDateFormat = 'DD.MM.YYYY';
const defaultGlobalDateTimeFormat = 'DD.MM.YYYY HH:mm';
const defaultGlobalShortDateFormat = 'DD MMM';
const defaultGlobalTimeFormat = 'HH:mm:ss';
const defaultGlobalShortTimeFormat = 'HH:mm';

// format date based on a given format
// eslint-disable-next-line consistent-return
function globalFormatDate(date: any, format: any) {
  const applicationStore = useApplicationStore();
  if (!format) {
    // eslint-disable-next-line no-param-reassign
    format = applicationStore.settings.dateFormat ? applicationStore.settings.dateFormat : defaultGlobalDateFormat;
  }
  const m = moment(date);
  if (m.isValid()) {
    return m.format(format);
  }
}

// eslint-disable-next-line consistent-return
function globalFormatDateTime(date: any, format: any) {
  const applicationStore = useApplicationStore();
  if (!format) {
    // eslint-disable-next-line no-param-reassign
    format = applicationStore.settings.dateFormat ? applicationStore.settings.dateFormat : defaultGlobalDateTimeFormat;
  }
  const m = moment(date);
  if (m.isValid()) {
    return m.format(format);
  }
}

// eslint-disable-next-line consistent-return
function globalFormatTime(time: any, format: any) {
  if (!format) {
    // eslint-disable-next-line no-param-reassign
    format = defaultGlobalShortTimeFormat;
  }
  const mtime = moment(time, defaultGlobalTimeFormat);
  if (mtime.isValid()) {
    return mtime.format(format);
  }
}

function globalGetLastDateOfPreviousMonth() {
  // get day of month
  const dayOfMonth = moment().date();

  // get date of last day prev month
  const lastDayOfPreviousMonth = moment().add(-1 * dayOfMonth, 'days');

  // Set the day to the last day of previous month, no time part
  return lastDayOfPreviousMonth.format('YYYY-MM-DD');
}

function globalAddDaysToDate(date: any, days: number) {
  const result = moment(date).add(days, 'days');
  return result.format('YYYY-MM-DD');
}

// eslint-disable-next-line consistent-return
function formatDuration(durationInMs: number, durationFormat = 'daysHourMinutes') {
  const TotalInseconds = durationInMs / 1000;
  const seconds = Math.floor(TotalInseconds % 60);
  let minutes = Math.floor(TotalInseconds / 60);
  minutes %= 60;
  let hours = Math.floor(TotalInseconds / (60 * 60));
  hours %= 24;
  let days = Math.floor(TotalInseconds / (24 * 60 * 60));
  days %= 365;

  if (durationFormat === 'daysHourMinutes') {
    let newResult: string | null = null;
    if (days > 0) {
      newResult = `${days}d `;
    }
    if (newResult && hours > 0) {
      newResult = `${newResult + hours}h `;
    }
    if (newResult && minutes > 0) {
      newResult = `${newResult + minutes}min `;
    }
    if (newResult && seconds > 0 && days === 0 && hours === 0 && minutes === 0) {
      newResult = `${newResult + seconds}s `;
    }
    return newResult;
  }
}

export const GlobalHelperFunctions = {
  defaultGlobalDateFormat,
  defaultGlobalDateTimeFormat,
  defaultGlobalShortDateFormat,
  defaultGlobalTimeFormat,
  defaultGlobalShortTimeFormat,
  lookupCode,
  setHistoryField,
  globalFormatDate,
  globalFormatDateTime,
  globalFormatTime,
  globalGetLastDateOfPreviousMonth,
  globalAddDaysToDate,
  formatDuration,
  ...GatComponentsHelperFunctions,
  ...GatCommon,
};
