<template>
  <div>
    <search-panel :fields="searchFields" @input="search" @reset="resetSearch" submitBtnRight="true" clearBtn="true">
      <gat-multi-select
        v-model="selectedCallStatuses"
        :items="callStatuses"
        label="Portcall status filter"
        textFieldName="NAME"
        codeFieldName="ID"
        :chips="false"
        :showMaxRows="false"
        :maxVisibleItemCount="setMaxVisibleCount"
        size="sm6 xs12 lg3" />
      <gat-select
        v-model="lastSearch.dateSearchType"
        label="Date limitation"
        :items="getDateSearchTypes"
        size="sm6 xs12 lg3" />
      <gat-edit
        dateEdit
        v-model="lastSearch.fromDate"
        :label="getDateLabel + ' from date'"
        :disabled="lastSearch.dateSearchType === 'NONE'"
        size="sm3 xs6 lg2" />
      <gat-edit
        dateEdit
        v-model="lastSearch.toDate"
        :label="getDateLabel + ' to date'"
        :disabled="lastSearch.dateSearchType === 'NONE'"
        size="sm3 xs6 lg2" />
    </search-panel>
    <!-- v-model="searchParams" -->
    <white-board
      v-if="whiteboard"
      :whiteBoard="whiteboard"
      :rowData="WhiteBoardData"
      :favCalls="null"
      :maxRows="maxRows"
      :loading="isLoading" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPost } from '@/store/api';
import SearchPanel from '@/app-components/SearchPanel.vue';
import WhiteBoard from '@/app-components/WhiteBoard.vue';
import { useApplicationStore } from '@/store/applicationStore';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'PortcallSearch',
  props: {
    query: {
      type: Object,
      required: false,
    },
  },
  components: { WhiteBoard, SearchPanel },
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {
      whiteboard: [],
      isLoading: false,
      maxRows: 100,
      WhiteBoardData: [],
      lastSearch: {},
      selectedCallStatuses: [],
      /* searchParams:{} */
    };
  },

  created() {
    apiGet('savedInfo/web_last_portcall_search').then((data) => {
      if (data.length > 0) {
        this.lastSearch = JSON.parse(data[0].MEMOVALUE1);
        this.maxRows = this.lastSearch.max;
      } else {
        // select all of the statuses if there is no savedInfo.
        this.selectedCallStatuses = [0, 1, 2, 3, 4, 5];
      }
    });
    apiGet('whiteboards/search').then((data) => {
      this.whiteboard = data;
    });
    /* window.messageBus.$on('new-agency-selected',()=>{
                      this.loadData();
                  }); */
  },

  watch: {
    lastSearch(newVal) {
      if (newVal.expected) {
        this.selectedCallStatuses.push(0);
      }
      if (newVal.berthed) {
        this.selectedCallStatuses.push(1);
      }
      if (newVal.anchored) {
        this.selectedCallStatuses.push(2);
      }
      if (newVal.sailed) {
        this.selectedCallStatuses.push(3);
      }
      if (newVal.archived) {
        this.selectedCallStatuses.push(4);
      }
      if (newVal.cancelled) {
        this.selectedCallStatuses.push(5);
      }
    },
  },

  computed: {
    callStatuses() {
      const result = [];
      result.push({ ID: 0, NAME: 'Expected' });
      result.push({ ID: 1, NAME: 'Berthed' });
      result.push({ ID: 2, NAME: 'Anchored' });
      result.push({ ID: 3, NAME: 'Sailed' });
      if (this.applicationStore.userRight('P.SEARCH_CANCELLED_AND_ARCHIVED')) {
        result.push({ ID: 4, NAME: 'Archived' });
        result.push({ ID: 5, NAME: 'Cancelled' });
      }
      return result;
    },
    getDateLabel() {
      if (this.lastSearch.dateSearchType === 'ARR') {
        return 'Arrival';
      }
      if (this.lastSearch.dateSearchType === 'DEP') {
        return 'Departure';
      }
      if (this.lastSearch.dateSearchType === 'PORT') {
        return 'Berthed';
      }
      return '';
    },
    getDateSearchTypes() {
      return [
        { text: 'No date limitation', value: 'NONE' },
        { text: 'Arrival in period', value: 'ARR' },
        { text: 'Berthed during period', value: 'PORT' },
        { text: 'Departure in period', value: 'DEP' },
      ];
    },
    searchFields() {
      const result = [];
      result.push({
        name: 'searchText',
        caption: 'Port call no / Vessel name',
        size: 'lg3 sm4 xs12',
        value: this.lastSearch.searchText ? this.lastSearch.searchText : '',
        maxChars: 50,
      });
      result.push({
        name: 'cargoText',
        caption: 'Cargo name',
        size: 'lg3 sm4 xs12',
        value: this.lastSearch.cargoText ? this.lastSearch.cargoText : '',
        maxChars: 50,
      });
      result.push({
        name: 'terminalText',
        caption: 'Terminal / Quay name',
        size: 'lg3 sm4 xs12',
        value: this.lastSearch.terminalText ? this.lastSearch.terminalText : '',
        maxChars: 50,
      });
      result.push({
        name: 'clientText',
        caption: 'Client name',
        size: 'lg3 sm4 xs12',
        value: this.lastSearch.clientText ? this.lastSearch.clientText : '',
        maxChars: 50,
      });
      //      result.push({name:"max", clearable:false, caption: "Max rows", size: "xl1 lg1 sm2 xs12", value: this.lastSearch.max ? this.lastSearch.max : 100, required: true, maxChars: 4});
      return result;
    },
    setMaxVisibleCount() {
      return 1;
    },
  },

  methods: {
    resetSearch() {
      this.WhiteBoardData = [];
      this.lastSearch = { dateSearchType: 'NONE' };
      this.selectedCallStatuses = [];
    },
    // [ {ID:0,NAME:'Expected'}, {ID:1,NAME:'Berthed'}, {ID:2,NAME:'Anchored'}, {ID:3,NAME:'Sailed'}, {ID:4,NAME:'Archived'}, {ID:5,NAME:'Cancelled'}]
    search(searchInput) {
      this.isLoading = true;
      const statuses = this.selectedCallStatuses;
      // eslint-disable-next-line no-param-reassign
      searchInput.fromDate = this.lastSearch.fromDate;
      // eslint-disable-next-line no-param-reassign
      searchInput.toDate = this.lastSearch.toDate;
      // eslint-disable-next-line no-param-reassign
      searchInput.dateSearchType = this.lastSearch.dateSearchType;
      // eslint-disable-next-line no-param-reassign
      searchInput.expected = false;
      // eslint-disable-next-line no-param-reassign
      searchInput.berthed = false;
      // eslint-disable-next-line no-param-reassign
      searchInput.anchored = false;
      // eslint-disable-next-line no-param-reassign
      searchInput.sailed = false;
      // eslint-disable-next-line no-param-reassign
      searchInput.archived = false;
      // eslint-disable-next-line no-param-reassign
      searchInput.cancelled = false;
      for (let i = 0; statuses.length > i; i++) {
        // eslint-disable-next-line default-case
        switch (statuses[i]) {
          case 0:
            // eslint-disable-next-line no-param-reassign
            searchInput.expected = true;
            break;
          case 1:
            // eslint-disable-next-line no-param-reassign
            searchInput.berthed = true;
            break;
          case 2:
            // eslint-disable-next-line no-param-reassign
            searchInput.anchored = true;
            break;
          case 3:
            // eslint-disable-next-line no-param-reassign
            searchInput.sailed = true;
            break;
          case 4:
            // eslint-disable-next-line no-param-reassign
            searchInput.archived = true;
            break;
          case 5:
            // eslint-disable-next-line no-param-reassign
            searchInput.cancelled = true;
            break;
        }
      }
      apiGet(
        `whiteboarddata/search/${this.applicationStore.agencyIdSelected}/100?searchText=${
          searchInput.searchText
        }&fromDate=${GlobalHelperFunctions.globalFormatDate(
          searchInput.fromDate,
          'YYYY-MM-DD'
        )}&toDate=${GlobalHelperFunctions.globalFormatDate(searchInput.toDate, 'YYYY-MM-DD')}&expected=${
          searchInput.expected
        }&anchored=${searchInput.anchored}&berthed=${searchInput.berthed}&sailed=${searchInput.sailed}&cancelled=${
          searchInput.cancelled
        }&archived=${searchInput.archived}&cargo=${searchInput.cargoText}&terminal=${
          searchInput.terminalText
        }&datePeriod=${searchInput.dateSearchType}&client=${searchInput.clientText}`
      ).then((data) => {
        this.WhiteBoardData = data;
        const savedInfo = {
          TypeName: 'web_last_portcall_search',
          StrValue: JSON.stringify(searchInput),
        };
        apiPost('savedInfo', savedInfo);
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped></style>
